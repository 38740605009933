import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  TableSortLabel,
  Box,
  useTheme,
} from "@mui/material";
import { dataFetch } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";
import { tokens } from "../../../utils/theme";
import UserQuotationsCard from "./UserQuotationsCard";

const UserQuotationsList = () => {
  const [quotations, setQuotations] = useState([]);
  const [totalQuotations, setTotalQuotations] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedQuotations, setSelectedQuotations] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { token } = useAuth();

  useEffect(() => {
    const fetchQuotations = async () => {
      setLoadingData(true);
      try {
        const skip = (page - 1) * rowsPerPage;
        let url = `quotations/myquotations/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
        let response = await dataFetch(url, token);

        if (response.status === 200) {
          setQuotations(response.data.data);
          setTotalQuotations(response.data.total);
        } else {
          console.error(`Error fetching quotations: ${response.status}`);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error(
            "Unauthorized access - possibly due to an invalid or expired token."
          );
        } else {
          console.error("Error fetching quotations:", error);
        }
      } finally {
        setLoadingData(false);
      }
    };

    fetchQuotations();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleQuotationsClick = async (quotationsId) => {
    setLoadingData(true);
    try {
      let url = `quotations/get/${quotationsId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedQuotations(response.data.data);
      } else {
        console.error(`Error fetching quotations details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access - possibly due to an invalid or expired token."
        );
      } else {
        console.error("Error fetching quotations details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalQuotations / rowsPerPage);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
    >
      {selectedQuotations ? (
        <Grid item xs={12}>
          <UserQuotationsCard quotations={selectedQuotations} />
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
            variant="h5" gutterBottom
            >
              Quotations Dashboard - Page {page}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {loadingData ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "50vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : quotations.length === 0 ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "50vh" }}
              >
                <Typography variant="h6" color="textSecondary">
                  No Quotations found! Please check back later.
                </Typography>
              </Grid>
            ) : (
              <TableContainer
                component={Paper}
                sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {[
                        "quotationssummary",
                        "associateddeal",
                        "associatedcontacts",
                        "quotationsstatus",
                        "grandtotal",

                      ].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: "bold",
                            padding: { xs: "8px", md: "16px" },
                            textAlign: "center",
                            backgroundColor: colors.primary[300], // Background color from the tokens
                            color: "white",
                            "&:hover": {
                              backgroundColor: "white",
                              color: colors.primary[300], // Hover effect to reverse colors
                            },
                          }}
                        >
                          <TableSortLabel
                            active={sortField === header}
                            direction={sortField === header ? sortOrder : "asc"}
                            onClick={() => handleSortRequest(header)}
                            sx={{ textAlign: "center" }}
                          >
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quotations.map((quotation) => (
                      <TableRow
                        key={quotation._id}
                        onClick={() => handleQuotationsClick(quotation._id)}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: colors.primary[300],
                            color: "white",
                            "& td": {
                              color: "white",
                              backgroundColor: colors.primary[300],
                            },
                          },
                        }}
                      >
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          {quotation?.quotationssummary}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          {quotation?.associateddeal?.name}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          {quotation?.associatedcontacts?.fullName}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          {quotation?.quotationsstatus}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          <b>{quotation?.grandtotal}</b>
                        </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{
                  "& .MuiPaginationItem-root": {
                    fontSize: { xs: "0.8rem", md: "1rem" },
                    margin: "0 4px",
                  },
                  "& .MuiPaginationItem-ellipsis": { display: "none" },
                  "& .MuiPaginationItem-page.Mui-selected": {
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  },
                }}
              />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
          </Grid>
        </>
      )}

    </Grid>
  );
};

export default UserQuotationsList;



