import React from "react";
import {Grid,useTheme} from "@mui/material";
import {tokens} from "../../../utils/theme";

import UserReportsSearch from "./UserReportsSearch";
import UserReportsList from "./UserReportsList";


const UserReportsDashboard=()=>{
    const theme=useTheme();
    const colors=tokens(theme.palette.mode);

    return(
        <Grid
        container
        spacing={2}//Add space between grid items
        sx={{padding:3, backgroundColor: "#ffff", minHeight:"50vh"}}
        >
            <Grid item xs={12} >
                <UserReportsSearch/>
            </Grid>

            <Grid item xs={12} sx={{marginTop:2}}>
                <UserReportsList/>
            </Grid>
        </Grid>
    );
};

export default UserReportsDashboard;