import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { dataFetch, dataUpdate } from "../../../services/ApiEndPoint"; // Use PUT method for updating
import { useAuth } from "../../../contexts/AuthContext";

const EditLeadModal = ({ onClose, open, lead, onUpdate }) => {
  const { token } = useAuth();
  const [users, setUsers] = useState([]);
  const [initialValues, setInitialValues] = useState({
    firstname: "",
    fullname: "",
    leadowner: "",
    location:"",
    date:"",
    pipelinestage: "",
    companyname: "",
    phonenumber: "",
    email: "",
    timezone: "",
    pipelineComment: "",
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token); // Fetch users for lead owner selection
        setUsers(Array.isArray(response.data.data) ? response.data.data : []);
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, [token]);

  // Update initialValues when the modal opens or the lead data changes
  useEffect(() => {
    if (open && lead) {
      setInitialValues({
        firstname: lead.firstname || "",
        fullname: lead.fullname || "",
        leadowner: lead?.leadowner?._id || "",
        location: lead.location || "",
        date: lead.date.substring(0, 10) || new Date().toISOString().substring(0, 10),
        pipelinestage: lead.pipelinestage || "",
        companyname: lead.companyname || "",
        phonenumber: lead.phonenumber || "",
        email: lead.email || "",
        timezone: lead.timezone || "",
        pipelineComment: lead.pipelineComment.join(", ") || "",
      });
    }
  }, [open, lead]);

  const validationSchema = Yup.object({
    firstname: Yup.string(),
    fullname: Yup.string(),
    leadowner: Yup.string().required("Please add a lead owner"),
    pipelinestage: Yup.string(),
    companyname: Yup.string().required("Please add a company name"),
    phonenumber: Yup.string().required("Please add a phone number"),
    email: Yup.string().email("Invalid email").required("Please add an email"),
    timezone: Yup.string(),
    pipelineComment: Yup.string(),
    location: Yup.string().required("Please add a location"),
    date: Yup.string().required("Please add a date"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const updatedValues = {
        ...values,
        pipelineComment: values.pipelineComment.split(",").map(comment => comment.trim()), // Split comments into an array
      };

      const response = await dataUpdate(`lead/${lead._id}`, updatedValues, token);
      if (response.status === 200) {
        toast.success("Lead updated successfully");
        onUpdate(updatedValues);
        resetForm();
      } else {
        console.error("Failed to update lead:", response.data);
        toast.error("Failed to update lead");
      }
    } catch (error) {
      console.error("Error updating lead:", error.response || error.message || error);
      toast.error("Error updating lead");
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Lead</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true} // This ensures that the form is reinitialized when initialValues change
      >
        {({ touched, errors }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="firstname"
                    as={TextField}
                    label="First Name"
                    fullWidth
                    helperText={touched.firstname ? errors.firstname : ""}
                    error={Boolean(errors.firstname && touched.firstname)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="fullname"
                    as={TextField}
                    label="Full Name"
                    fullWidth
                    helperText={touched.fullname ? errors.fullname : ""}
                    error={Boolean(errors.fullname && touched.fullname)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="leadowner"
                    as={TextField}
                    select
                    label="Lead Owner"
                    fullWidth
                    helperText={touched.leadowner ? errors.leadowner : ""}
                    error={Boolean(errors.leadowner && touched.leadowner)}
                  >
                    {users.length > 0 ? (
                      users.map((user) => (
                        <MenuItem key={user?._id} value={user?._id}>
                          {user?.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No users available</MenuItem>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="date"
                    as={TextField}
                    label="Date"
                    type="date"
                    fullWidth
                    helperText={touched.date ? errors.date : ""}
                    error={Boolean(errors.date && touched.date)}
                  />
                </Grid>

              <Grid item xs={12} md={6}>
                  <Field
                    name="location"
                    as={TextField}
                    label="Location"
                    fullWidth
                    helperText={touched.location ? errors.location : ""}
                    error={Boolean(errors.location && touched.location)}
                  />
                </Grid>
                    
                <Grid item xs={12} md={6}>
                  <Field
                    name="pipelinestage"
                    as={TextField}
                    select
                    label="Pipeline Stage"
                    fullWidth
                    helperText={touched.pipelinestage ? errors.pipelinestage : ""}
                    error={Boolean(errors.pipelinestage && touched.pipelinestage)}
                  >
                    <MenuItem value="New">New</MenuItem>
                    <MenuItem value="Contacted">Contacted</MenuItem>
                    <MenuItem value="Qualified">Qualified</MenuItem>
                    <MenuItem value="Lost">Lost</MenuItem>
                    <MenuItem value="Won">Won</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Converted">Converted</MenuItem>
                    <MenuItem value="Unqualified">Unqualified</MenuItem>
                    <MenuItem value="Reassigned">Reassigned</MenuItem>
                    <MenuItem value="Reopened">Reopened</MenuItem>
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="companyname"
                    as={TextField}
                    label="Company Name"
                    fullWidth
                    helperText={touched.companyname ? errors.companyname : ""}
                    error={Boolean(errors.companyname && touched.companyname)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="phonenumber"
                    as={TextField}
                    label="Phone Number"
                    fullWidth
                    helperText={touched.phonenumber ? errors.phonenumber : ""}
                    error={Boolean(errors.phonenumber && touched.phonenumber)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="email"
                    as={TextField}
                    label="Email"
                    fullWidth
                    helperText={touched.email ? errors.email : ""}
                    error={Boolean(errors.email && touched.email)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="timezone"
                    as={TextField}
                    label="Timezone"
                    fullWidth
                    helperText={touched.timezone ? errors.timezone : ""}
                    error={Boolean(errors.timezone && touched.timezone)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="pipelineComment"
                    as={TextField}
                    label="Pipeline Comments"
                    fullWidth
                    multiline
                    rows={4}
                    helperText={touched.pipelineComment ? errors.pipelineComment : ""}
                    error={Boolean(errors.pipelineComment && touched.pipelineComment)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Update Lead
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditLeadModal;
