import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
} from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";
import { dataFetch } from "../../../services/ApiEndPoint";
import LeadCard from "./UserLeadCard";

function UserLeadSearch() {
  const [leads, setLeads] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLead, setSelectedLead] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [sortBy, setSortBy] = useState("fullname");
  const [pipelineStageFilter, setPipelineStageFilter] = useState("");
  const [leadOwnerFilter, setLeadOwnerFilter] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { token } = useAuth();

  const fetchLeads = async () => {
    setLoadingData(true);
    try {
      let url = `lead/myleads/query?page=${page}&limit=${rowsPerPage}&sort=${sortBy}&fullname=${searchTerm}&pipelinestage=${pipelineStageFilter}&leadowner=${leadOwnerFilter}`;
      let response = await dataFetch(url, token, "GET");
      if (response.status === 200) {
        setLeads(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, [
    searchTerm,
    sortBy,
    pipelineStageFilter,
    leadOwnerFilter,
    page,
    rowsPerPage,
  ]);

  const handleLeadClick = async (leadId) => {
    setLoadingData(true);
    try {
      let url = `lead/user/${leadId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedLead(response.data.data);
      } else {
        console.error(`Error fetching Lead details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access - possibly due to an invalid or expired token."
        );
      } else {
        console.error("Error fetching Lead details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  return (
    <Grid
      container
      spacing={2}
    justifyContent="center"
    >
      {selectedLead ? (
        <LeadCard lead={selectedLead} />
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Lead Dashboard
            </Typography>
          </Grid>

          {/* Search Bar, Sort, and Filter Controls */}
          <Grid
            container
            item
            xs={12}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={8} md={6}>
              <TextField
                label="Search leads"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
                sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Sort By</InputLabel>
                <Select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  label="Sort By"
                  sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
                >
                  <MenuItem value="fullname">Full Name</MenuItem>
                  <MenuItem value="companyname">Company Name</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Pipeline Stage</InputLabel>
                <Select
                  value={pipelineStageFilter}
                  onChange={(e) => setPipelineStageFilter(e.target.value)}
                  label="Pipeline Stage"
                  sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
                >
                  <MenuItem value="">All Stages</MenuItem>
                  <MenuItem value="prospect">Prospect</MenuItem>
                  <MenuItem value="qualified">Qualified</MenuItem>
                  <MenuItem value="proposal">Proposal</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Lead Owner</InputLabel>
                <Select
                  value={leadOwnerFilter}
                  onChange={(e) => setLeadOwnerFilter(e.target.value)}
                  label="Lead Owner"
                  sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
                >
                  <MenuItem value="">All Owners</MenuItem>
                  <MenuItem value="john">John</MenuItem>
                  <MenuItem value="jane">Jane</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Dynamic Search Results */}
          {searchTerm && !loadingData && (
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  maxHeight: 200,
                  overflow: "auto",
                  mt: 2,
                  padding: 2,
                  bgcolor: "#f1f1f1",
                  borderRadius: 2,
                }}
              >
                <List>
                  {leads
                    .filter((leads) =>
                      leads?.fullname
                        .toLowerCase()
                        .startsWith(searchTerm.toLowerCase())
                    )
                    .map((leads) => (
                      <ListItem
                        key={leads?._id}
                        sx={{
                          bgcolor: "#f4f4f4",
                          mb: 1,
                          borderRadius: 2,
                          boxShadow: 1,
                        }}
                        button
                        onClick={() => handleLeadClick(leads?._id)} // Fetch and display the lead profile on click
                      >
                        <ListItemText
                          primary={leads?.fullname}
                          secondary={`${leads?.companyname} - ${leads?.phonenumber}`}
                          primaryTypographyProps={{ fontWeight: "bold" }}
                        />
                      </ListItem>
                    ))}
                </List>
              </Paper>
            </Grid>
          )}

          {loadingData && (
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: "100vh" }}
            >
              <CircularProgress />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default UserLeadSearch;
