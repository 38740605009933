import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../utils/theme";
import AdminProfileCard from "./AdminProfileCard";
import { useAuth } from "../../../contexts/AuthContext";


const AdminProfile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
const { userData,token } = useAuth();
  return (
    <Grid
      container
      spacing={2}
      sx={{ padding: 3, backgroundColor: "#ffffff", minHeight: "100vh" }}
    >
      <Grid item xs={12} md={12} lg={12} xl={12}>
       <AdminProfileCard userData={userData} token={token}/>
      </Grid>

      <Grid item xs={12} md={12} lg={12} xl={12}>
      
      </Grid>
    </Grid>
  );
};

export default AdminProfile;
