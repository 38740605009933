import React, { useState, useEffect } from 'react';
import {
  Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Pagination, Typography, CircularProgress, Box, TableSortLabel,
  useTheme
} from '@mui/material';
import { tokens } from '../../../utils/theme';

const OrganizationUser = ({ organization }) => {
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    if (organization && organization.users) {
      setUsers(organization.users);
      setTotalUsers(organization.users.length);
    }
    setLoadingData(false);
  }, [organization]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortField(field);
    const sortedUsers = [...users].sort((a, b) => {
      if (a[field] < b[field]) return isAsc ? -1 : 1;
      if (a[field] > b[field]) return isAsc ? 1 : -1;
      return 0;
    });
    setUsers(sortedUsers);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalUsers / rowsPerPage);

  const getRoleStyles = (role) => {
    switch (role) {
      case 'manager':
        return { color: 'blue', fontWeight: 'bold' }; // Example color for manager
      case 'admin':
        return { color: 'red', fontWeight: 'bold' }; // Example color for admin
      case 'user':
        return { color: 'green', fontWeight: 'bold' }; // Example color for user
      default:
        return { color: 'black', fontWeight: 'bold' }; // Default color
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center" sx={{ width: '100%' }}>
      <Grid item xs={12}>
        {loadingData ? (
          <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
            <CircularProgress />
          </Grid>
        ) : (
          <TableContainer component={Paper} sx={{ width: '100%' }}>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  {['name', 'email', 'role', 'phone', 'status'].map((header) => (
                    <TableCell
                      key={header}
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        backgroundColor: colors.primary[300],
                        color: 'white',
                      }}
                    >
                      <TableSortLabel
                        active={sortField === header}
                        direction={sortField === header ? sortOrder : 'asc'}
                        onClick={() => handleSortRequest(header)}
                      >
                        {header.charAt(0).toUpperCase() + header.slice(1)}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((user) => (
                  <TableRow key={user._id}>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{user?.name || '-'}</TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{user?.email || '-'}</TableCell>
                    <TableCell sx={{ textAlign: 'center', ...getRoleStyles(user?.role), textTransform: 'uppercase' }}>
                      {user?.role || '-'}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{user?.phone || '-'}</TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: user?.status === 'active' ? 'green' : 'red',
                        textTransform: 'uppercase', // Make status uppercase
                      }}
                    >
                      {user?.status || '-'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            sx={{
              '& .MuiPaginationItem-root': {
                fontSize: { xs: '0.8rem', md: '1rem' },
                margin: '0 4px',
              },
              '& .MuiPaginationItem-ellipsis': { display: 'none' },
              '& .MuiPaginationItem-page.Mui-selected': {
                backgroundColor: 'black',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'black',
                },
              },
            }}
          />
          <Typography sx={{ ml: 2 }}>
            <strong>Total Users: {totalUsers}</strong>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrganizationUser;
