import React, { useState } from "react";
import { Grid, Tabs, Tab, Typography } from "@mui/material";
import TabPanel from "../../../utils/TabPanel";
import QuotationsProfile from "./UserQuotationsProfile";

function UserQuotationsCard({ quotations }) {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Grid container spacing={2}>
            {/* Header */}
            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <Typography variant="h5" gutterBottom>
                    {quotations?.quotationssummary}'s Profile
                </Typography>
            </Grid>

            {/* Tabs */}
            <Grid item xs={12}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="primary"
                >
                    <Tab label="Profile" value={0} />
                    <Tab label="Employment" value={1} />
                </Tabs>
            </Grid>

            {/* Profile Tab */}
            <TabPanel value={tabValue} index={0}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <QuotationsProfile quotations={quotations} />
                    </Grid>
                </Grid>
            </TabPanel>

            {/* Employment Tab */}
            <TabPanel value={tabValue} index={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* Add employment-related content here */}
                        <Typography variant="h6">Employment Information</Typography>
                        {/* Include more details as needed */}
                    </Grid>
                </Grid>
            </TabPanel>
        </Grid>
    );
}

export default UserQuotationsCard;
