import React, { useState } from "react";
import { Grid, Tabs, Typography, Button, Box, Tab } from "@mui/material";
import TabPanel from "../../../utils/TabPanel"; 
import UpdateReport from "./EditReportsModal";
import ReportsProfile from "./ReportsProfile";

function ReportsCard({ reports }) {
    const [tabValue, setTabValue] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);
    const [reportsData, setReportsData] = useState(reports);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleReportsUpdate = (updatedReports) => {
        setReportsData(updatedReports); // Update the reports data in state
    };
console.log(reports);
console.log(reportsData);
    return (
        <Grid container spacing={2}>
            {/* Header */}
            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                <Typography variant="h5" gutterBottom>
                    {reportsData?.reportname}'s Profile
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                        backgroundColor: 'blue',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: 'yellow',
                            color: 'black',
                        },
                        borderRadius: '8px',
                        padding: '8px 16px', // Corrected padding
                    }}
                    onClick={handleModalOpen}
                >
                    Update Reports
                </Button>
            </Grid>

            {/* Tabs */}
            <Grid item xs={12}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="primary"
                  
                >
                    <Tab label="Profile" value={0} />
                    <Tab label="Employment" value={1} />
                </Tabs>
            </Grid>

            {/* Profile Tab */}
            <TabPanel value={tabValue} index={0}>
                <Box sx={{ p: 2,  }}>
                    <ReportsProfile reports={reportsData} />
                </Box>
            </TabPanel>

            {/* Employment Tab */}
            <TabPanel value={tabValue} index={1}>
                <Box sx={{ p: 2,  }}>
                    {/* Employment content can go here */}
                    <Typography variant="h6">Employment Details</Typography>
                    {/* Add relevant content or components */}
                </Box>
            </TabPanel>

            {/* Update Reports Modal */}
            <UpdateReport
                open={isModalOpen}
                onClose={handleModalClose}
                reports={reportsData}
                onUpdate={handleReportsUpdate} // Pass the update handler
            />
        </Grid>
    );
}

export default ReportsCard;
