import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { getDateTimeSync } from "../../../utils/DateTimeHelper";

function UserDealsProfile({ deals }) {
  const renderComments = () => {
    const comments = [];

    // Check if both pipeline stage and comment exist
    if (deals?.pipelinestage && deals?.pipelineComment) {
      comments.push(
        <Typography variant="body1" key="pipeline-comment" sx={{ color: 'blue', fontWeight: 'bold' }}>
          {`${deals.pipelinestage} - ${deals.pipelineComment}`}
        </Typography>
      );
    } else if (deals?.pipelineComment) {
      // If only pipeline comment exists
      comments.push(
        <Typography variant="body1" key="pipeline-comment" sx={{ color: 'blue', fontWeight: 'bold' }}>
          {`Pipeline Comment: ${deals.pipelineComment}`}
        </Typography>
      );
    } else if (deals?.additionalComments && deals.additionalComments.length > 0) {
      // If additional comments exist
      comments.push(
        <Typography variant="body1" key="additional-comments">
          {deals.additionalComments.join(', ')}
        </Typography>
      );
    } else {
      comments.push(<Typography variant="body1">No comments available</Typography>);
    }

    return comments;
  };

  const renderAssignedDeals = () => {
    if (!deals?.assignedDeals || deals.assignedDeals.length === 0) {
      return <Typography variant="body1">No assigned leads available</Typography>;
    }
  
    // Extract names and join them with a separator
    const assignedDealsNames = deals.assignedDeals.map((assignedDeals) => assignedDeals.name).join(" | ");
  
    return (
      <Typography variant="body1">
        {assignedDealsNames}
      </Typography>
    );
  };

  return (
    <Grid item xs={12} sm={8} md={6}>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "20px",
          padding: "8px",
          maxWidth: "100%",
          margin: "auto",
          mt: "10px",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
               Name:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{deals?.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Actual Date:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{getDateTimeSync(deals?.actualdate)}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
              Pipelinestage
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{deals?.pipelinestage}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
              Estimated Value:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{deals?.estimatedvalue}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Product or Service:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{deals?.productorservice}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
               Company:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{deals?.company}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
               EstimatedClousure Date:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{getDateTimeSync(deals?.estimatedclosuredate)}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Owner:
              </Typography>
            </Grid>
            <Grid item xs={8}>
            <Typography variant="body1">{deals?.owner?.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
               Comments:
              </Typography>
</Grid>
            <Grid item xs={8}>
              {renderComments()}
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Assigned To:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {renderAssignedDeals()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default UserDealsProfile;
