import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  Button,
  TableSortLabel,
  useTheme,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import { dataFetch, dataDelete } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";
import { tokens } from "../../../utils/theme";
import DeleteIcon from "@mui/icons-material/Delete";
import AddReportsModal from "./AddReportsModal";
import ReportsCard from "./ReportsCard";
import toast from "react-hot-toast";
import { getDateTimeFullWithTimeSync } from "../../../utils/DateTimeHelper";

const ReportsList = () => {
  const [reports, setReports] = useState([]);
  const [totalReports, setTotalReports] = useState([0]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedReports, setSelectedReports] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [reportsIdToDelete, setReportsIdToDelete] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { token } = useAuth();

  useEffect(() => {
    const fetchReports = async () => {
      setLoadingData(true);
      try {
        const skip = (page - 1) * rowsPerPage;
        let url = `reports/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
        let response = await dataFetch(url, token);

        if (response.status === 200) {
          setReports(response.data.data);
          setTotalReports(response.data.total);
        } else {
          console.error(`Error fetching Reports: ${response.status}`);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error(
            "Unauthorized access - possibly due to an invalid or expired token."
          );
        } else {
          console.error("Error fetching reports:", error);
        }
      } finally {
        setLoadingData(false);
      }
    };

    fetchReports();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const addReports= (newReports) => {
    // Add the new reports to the current list
    setReports((prevReports) => [...prevReports, newReports]);
    setTotalReports((prevTotal) => prevTotal + 1); // Optionally, update the total count
  };

  const handleReportsClick = async (reportsId) => {
    setLoadingData(true);
    try {
      let url = `reports/${reportsId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedReports(response.data.data);
      } else {
        console.error(`Error fetching reports details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log(
          "Unauthorized access - possibly due to an invalid or expired token."
        );
      } else {
        console.log("Error fetching reports details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  const handleOpenDeleteDialog = (reportsId) => {
    setReportsIdToDelete(reportsId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setReportsIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (reportsIdToDelete) {
      setLoadingData(true);
      try {
        const url = `reports/${reportsIdToDelete}`;
        const response = await dataDelete(url, token);

        if (response.status === 200) {
          setReports(reports.filter((reports) => reports._id !== reportsIdToDelete));
          setTotalReports((prev) => prev - 1);
          console.log("Reports deleted successfully");
          toast.success("Reports deleted successfully");
        } else {
          console.error(`Error deleting reports: ${response.status}`);
        }
      } catch (error) {
        console.error("Error deleting reports:", error);
      } finally {
        setLoadingData(false);
        handleCloseDeleteDialog();
      }
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalReports / rowsPerPage);

  return (
    <Grid container spacing={2}>
      {selectedReports ? (
        <Grid item xs={12}>
          <ReportsCard reports={selectedReports} />
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
             variant="h5" gutterBottom
            >
              Reports Dashboard - Page {page}
            </Typography>
            <Box sx={{display:'flex', gap:'8px'}}>
           
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "blue",
                color: "white",
                "&:hover": {
                  backgroundColor: "yellow",
                  color: "black",
                },  borderRadius: '8px', // Example additional style
            padding: '8px 16px'
              }}
              onClick={handleOpenModal}
            >
              Add reports
            </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {loadingData ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "50vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <TableContainer
                component={Paper}
                sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {[
                        "reportname",
                        "entitytype",
                        "reporttype",
                        "createdat",
                        "createdby",
                        "delete"

                      ].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: "bold",
                            padding: { xs: "8px", md: "16px" },
                            textAlign: "center",
                            backgroundColor: colors.primary[300], // Background color from the tokens
                            color: "white",
                            "&:hover": {
                              backgroundColor: "white",
                              color: colors.primary[300], // Hover effect to reverse colors
                            },
                          }}
                        >
                          <TableSortLabel
                            active={sortField === header}
                            direction={sortField === header ? sortOrder : "asc"}
                            onClick={() => handleSortRequest(header)}
                            sx={{ textAlign: "center" }}
                          >
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reports.map((reports) => (
                      <TableRow
                        key={reports._id}
                        onClick={() => handleReportsClick(reports._id)}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: colors.primary[300],
                            color: "white",
                            "& td": {
                              color: "white",
                              backgroundColor: colors.primary[300],
                            },
                          },
                        }}
                      >
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          {reports?.reportname}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          {reports?.entitytype}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          {reports?.reporttype}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          {getDateTimeFullWithTimeSync(reports?.createdat)}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          <b>{reports?.createdby?.name}</b>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the row click event when clicking the delete button
                              handleOpenDeleteDialog(reports._id);
                            }}
                            sx={{
                              backgroundColor: "black",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "red",
                              },
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{
                  "& .MuiPaginationItem-root": {
                    fontSize: { xs: "0.8rem", md: "1rem" },
                    margin: "0 4px",
                  },
                  "& .MuiPaginationItem-ellipsis": { display: "none" },
                  "& .MuiPaginationItem-page.Mui-selected": {
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  },
                }}
              />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
          </Grid>
        </>
      )}
  {/* Delete Confirmation Dialog */}
  <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} fullWidth maxWidth="xs"
        PaperProps={{
          sx: {
            borderRadius: 10,
            padding: 2,
            minWidth: '300px',
            boxShadow: 3,
          },
        }}>
        <DialogTitle sx={{ textAlign: 'center', paddingBottom: 2 }}>Confirm Deletion</DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <DialogContentText sx={{ textAlign: 'center', marginBottom: 2 }}>
            Are you sure you want to delete this quotation? 
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            sx={{
              backgroundColor: 'error.main',
              color: 'white',
              '&:hover': {
                backgroundColor: 'error.dark',
              },
              padding: '8px 16px',
              marginRight: 1,
            }}
          >
            Yes
          </Button>
          <Button
            onClick={handleCloseDeleteDialog}
            variant="outlined"
            sx={{
              color: 'blue',
              borderColor: 'blue',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: 'blue',
                color: 'white',
              },
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      {/* Add reports Modal */}
      <AddReportsModal open={openModal} onClose={handleCloseModal} onAddReports={addReports}/>
    </Grid>
  );
};

export default ReportsList;

