import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "./contexts/AuthContext.jsx";
import { ThemeProviderWrapper } from "./utils/ThemeContext.jsx";
// import ThemedApp from "./utils/ThemedApp.jsx";
import App from "./App.jsx";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthProvider>
<ThemeProviderWrapper>
      <App/>
</ThemeProviderWrapper>
    </AuthProvider>
  </React.StrictMode>
);
