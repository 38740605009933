import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  Button,
  TableSortLabel,
  useTheme,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import { dataFetch, dataDelete } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";
import { tokens } from "../../../utils/theme";
import DeleteIcon from "@mui/icons-material/Delete";
import AddQuotationsModal from "./AddQuotationsModal";
import QuotationsCard from "./QuotationsCard";
import toast from "react-hot-toast";

const QuotationsList = () => {
  const [quotations, setQuotations] = useState([]);
  const [totalQuotations, setTotalQuotations] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedQuotations, setSelectedQuotations] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [quotationsIdToDelete, setQuotationsIdToDelete] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { token } = useAuth();

  useEffect(() => {
    const fetchQuotations = async () => {
      setLoadingData(true);
      try {
        const skip = (page - 1) * rowsPerPage;
        let url = `quotations/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
        let response = await dataFetch(url, token);

        if (response.status === 200) {
          setQuotations(response.data.data);
          setTotalQuotations(response.data.total);
        } else {
          console.error(`Error fetching Quotations: ${response.status}`);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized access - possibly due to an invalid or expired token.");
        } else {
          console.error("Error fetching quotations:", error);
        }
      } finally {
        setLoadingData(false);
      }
    };

    fetchQuotations();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const addQuotations = (newQuotations) => {
    setQuotations((prevQuotations) => [...prevQuotations, newQuotations]);
    setTotalQuotations((prevTotal) => prevTotal + 1);
  };

  const handleQuotationsClick = async (quotationsId) => {
    setLoadingData(true);
    try {
      let url = `quotations/${quotationsId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedQuotations(response.data.data);
      } else {
        console.error(`Error fetching quotations details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching quotations details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  const handleOpenDeleteDialog = (quotationsId) => {
    setQuotationsIdToDelete(quotationsId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setQuotationsIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (quotationsIdToDelete) {
      setLoadingData(true);
      try {
        const url = `quotations/${quotationsIdToDelete}`;
        const response = await dataDelete(url, token);

        if (response.status === 200) {
          setQuotations((prevQuotations) => prevQuotations.filter((quotation) => quotation._id !== quotationsIdToDelete));
          setTotalQuotations((prevTotal) => prevTotal - 1);
          console.log("Quotations deleted successfully");
          toast.success("Quotations deleted successfully");
        } else {
          console.error(`Error deleting quotations: ${response.status}`);
        }
      } catch (error) {
        console.error("Error deleting quotations:", error);
      } finally {
        setLoadingData(false);
        handleCloseDeleteDialog();
      }
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalQuotations / rowsPerPage);

  return (
    <Grid container spacing={2}>
      {selectedQuotations ? (
        <Grid item xs={12}>
          <QuotationsCard quotations={selectedQuotations} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h5" gutterBottom>
              Quotations Dashboard - Page {page}
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "blue",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "yellow",
                    color: "black",
                  },
                  borderRadius: '8px',
                  padding: '8px 16px'
                }}
                onClick={handleOpenModal}
              >
                Add Quotations
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {loadingData ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "50vh" }}>
                <CircularProgress />
              </Grid>
            ) : (
              <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {["quotationssummary", "associateddeal", "associatedcontacts", "quotationsstatus", "grandtotal", "delete"].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: "bold",
                            padding: { xs: "8px", md: "16px" },
                            textAlign: "center",
                            backgroundColor: colors.primary[300],
                            color: "white",
                            "&:hover": {
                              backgroundColor: "white",
                              color: colors.primary[300],
                            },
                          }}
                        >
                          <TableSortLabel
                            active={sortField === header}
                            direction={sortField === header ? sortOrder : "asc"}
                            onClick={() => handleSortRequest(header)}
                            sx={{ textAlign: "center" }}
                          >
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quotations.map((quotation) => (
                      <TableRow
                        key={quotation._id}
                        onClick={() => handleQuotationsClick(quotation._id)}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: colors.primary[300],
                            color: "white",
                            "& td": {
                              color: "white",
                              backgroundColor: colors.primary[300],
                            },
                          },
                        }}
                      >
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>{quotation?.quotationssummary}</TableCell>
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>{quotation?.associateddeal?.name}</TableCell>
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>{quotation?.associatedcontacts?.fullName}</TableCell>
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>{quotation?.quotationsstatus}</TableCell>
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}><b>{quotation?.grandtotal}</b></TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenDeleteDialog(quotation._id);
                            }}
                            sx={{
                              backgroundColor: "black",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "red",
                              },
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{
                  "& .MuiPaginationItem-root": {
                    fontSize: { xs: "0.8rem", md: "1rem" },
                    margin: "0 4px",
                  },
                  "& .MuiPaginationItem-ellipsis": { display: "none" },
                  "& .MuiPaginationItem-page.Mui-selected": {
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  },
                }}
              />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
          </Grid>
        </>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} fullWidth maxWidth="xs"
        PaperProps={{
          sx: {
            borderRadius: 10,
            padding: 2,
            minWidth: '300px',
            boxShadow: 3,
          },
        }}>
        <DialogTitle sx={{ textAlign: 'center', paddingBottom: 2 }}>Confirm Deletion</DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <DialogContentText sx={{ textAlign: 'center', marginBottom: 2 }}>
            Are you sure you want to delete this quotation? 
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            sx={{
              backgroundColor: 'error.main',
              color: 'white',
              '&:hover': {
                backgroundColor: 'error.dark',
              },
              padding: '8px 16px',
              marginRight: 1,
            }}
          >
            Yes
          </Button>
          <Button
            onClick={handleCloseDeleteDialog}
            variant="outlined"
            sx={{
              color: 'blue',
              borderColor: 'blue',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: 'blue',
                color: 'white',
              },
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add quotations Modal */}
      <AddQuotationsModal open={openModal} onClose={handleCloseModal} onAddQuotations={addQuotations} />
    </Grid>
  );
};

export default QuotationsList;
