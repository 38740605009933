import React, { useState } from "react";
import { Grid, Tabs, Tab, Typography, Button } from "@mui/material";
import TabPanel from "../../../utils/TabPanel";
import EditContactsDrawer from "./EditConactsDrawer";
import ContactProfile from "./ContactProfile";

function ContactsCard({ contacts }) {
  const [tabValue, setTabValue] = useState(0);
  const [openDrawer, setDrawerOpen] = useState(false);
  const [contactsData, setContactsData] = useState(contacts);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleContactsUpdate = (updatedContacts) => {
    setContactsData(updatedContacts);
  };

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <Typography variant="h5" gutterBottom>
          {contactsData?.fullName}'s Profile
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            backgroundColor: 'blue',
            color: 'white',
            '&:hover': {
              backgroundColor: 'yellow',
              color: 'black'
            },
            borderRadius: '8px',
            padding: '8px 16px'
          }}
          onClick={handleDrawerOpen}
        >
          Update contact
        </Button>
      </Grid>

      {/* Tabs */}
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
        
        >
          <Tab label="Profile" value={0} />
          <Tab label="Employment" value={1} />
        </Tabs>
      </Grid>

      {/* Profile Tab */}
      <Grid item xs={12}>
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContactProfile contacts={contactsData} />
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>

      {/* Employment Tab */}
      <Grid item xs={12}>
        <TabPanel value={tabValue} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Employment Information</Typography>
              {/* Add employment-related details here */}
              <Typography variant="body1">
                {/* Example: Current Position: {contactsData?.position || "Not Available"} */}
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>

      {/* Edit Contacts Drawer */}
      <EditContactsDrawer 
        open={openDrawer} 
        onClose={handleDrawerClose} 
        contacts={contactsData}  
        onUpdate={handleContactsUpdate}  
      />
    </Grid>
  );
}

export default ContactsCard;
