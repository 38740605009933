import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../utils/theme";
import DealsSearch from "./DealsSearch";
import DealsList from "./DealsList";
import AssignDealsList from "./AssignDealsList";

const DealsDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Grid
      container
      spacing={2}
      sx={{ padding: 3, backgroundColor: "#ffffff", minHeight: "50vh" }}
    >
      <Grid item xs={12} >
        <DealsSearch />
      </Grid>
      <Grid item xs={12}>
        <AssignDealsList/>
        </Grid>
      <Grid item xs={12} sx={{ marginTop: 2 }}>
        <DealsList />
      </Grid>
    </Grid>
  );
};

export default DealsDashboard;
