import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../utils/theme";

import UserDealsSearch from "./UserDealsSearch";
import UserDealsList from "./UserDealsList";

const UserDealsDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Grid
      container
      sx={{ padding: 3, backgroundColor: "#ffffff", minHeight: "50vh" }}
    >
      <Grid item xs={12}>
        <UserDealsSearch />
      </Grid>

      <Grid item xs={12} sx={{ marginTop: 2 }}> {/* Add marginTop here */}
        <UserDealsList />
      </Grid>
    </Grid>
  );
};

export default UserDealsDashboard;
