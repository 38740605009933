import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Drawer,
  Grid,
  IconButton,
  Typography,
  Divider,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { dataFetch, dataPost } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";

const AddContactsDrawer = ({ onClose, open,onAddContacts }) => {
  const { token,userData } = useAuth();
  const [users, setUsers] = useState([]);


  useEffect(() => {
  setUsers(userData)
  }, [userData]);
 


  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    fullName: Yup.string().required("Required"),
    owner: Yup.string().required("Required"),
    company: Yup.string().required("Required"),
    designation: Yup.string().required("Required"),
    phoneNumber: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    address: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
  });

  const initialValues = {
    firstName: "",
    fullName: "",
    owner: "",
    company: "",
    designation: "",
    phoneNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    console.log("Values:", values);

    try {
      const response = await dataPost("contacts/", values, token);
      console.log("Response:", response);
      if (response.status === 201) {
        toast.success("Contact added successfully");
        onAddContacts(response.data.data);
        resetForm();
       
      } else {
        console.error("Failed to add contact:", response.data);
        toast.error("Failed to add contact");
      }
    } catch (error) {
      console.error(
        "Error adding contact:",
        error.response || error.message || error
      );
      toast.error("Error adding contact");
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: { xs: "100%", sm: "50%", md: "40%" } } }} // Responsive width
    >
      <div style={{ padding: 16 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Add New Contact</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ touched, errors }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="firstName"
                    as={TextField}
                    label="First Name"
                    fullWidth
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : ""
                    }
                    error={Boolean(errors.firstName && touched.firstName)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="fullName"
                    as={TextField}
                    label="Full Name"
                    fullWidth
                    helperText={
                      errors.fullName && touched.fullName
                        ? errors.fullName
                        : ""
                    }
                    error={Boolean(errors.fullName && touched.fullName)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                <Field
                name="owner"
                as={TextField}
                label="Owner"
                fullWidth
                // value={values.owner} // Bind formik value for owner
                helperText={touched.owner ? errors.owner : ""}
                error={Boolean(errors.owner && touched.owner)}
                select
              >
                {users.name ? ( // Check if userData exists
                  <MenuItem key={users._id} value={users._id}>
                    {users.name}
                  </MenuItem>
                ) : (
                  <MenuItem disabled>No users available</MenuItem>
                )}
              </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="company"
                    as={TextField}
                    label="Company"
                    fullWidth
                    helperText={
                      errors.company && touched.company ? errors.company : ""
                    }
                    error={Boolean(errors.company && touched.company)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="designation"
                    as={TextField}
                    label="Designation"
                    fullWidth
                    helperText={
                      errors.designation && touched.designation
                        ? errors.designation
                        : ""
                    }
                    error={Boolean(errors.designation && touched.designation)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="phoneNumber"
                    as={TextField}
                    label="Phone Number"
                    fullWidth
                    helperText={
                      errors.phoneNumber && touched.phoneNumber
                        ? errors.phoneNumber
                        : ""
                    }
                    error={Boolean(errors.phoneNumber && touched.phoneNumber)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="email"
                    as={TextField}
                    label="Email"
                    fullWidth
                    helperText={errors.email && touched.email ? errors.email : ""}
                    error={Boolean(errors.email && touched.email)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="address"
                    as={TextField}
                    label="Address"
                    fullWidth
                    helperText={
                      errors.address && touched.address ? errors.address : ""
                    }
                    error={Boolean(errors.address && touched.address)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="city"
                    as={TextField}
                    label="City"
                    fullWidth
                    helperText={errors.city && touched.city ? errors.city : ""}
                    error={Boolean(errors.city && touched.city)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="state"
                    as={TextField}
                    label="State"
                    fullWidth
                    helperText={errors.state && touched.state ? errors.state : ""}
                    error={Boolean(errors.state && touched.state)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="country"
                    as={TextField}
                    label="Country"
                    fullWidth
                    helperText={
                      errors.country && touched.country ? errors.country : ""
                    }
                    error={Boolean(errors.country && touched.country)}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="flex-end"
                style={{ marginTop: 16 }}
              >
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Add Contact
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Drawer>
  );
};

export default AddContactsDrawer;
