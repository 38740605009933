import React from "react";
import { Grid, useTheme } from "@mui/material";
import { tokens } from "../../../utils/theme";
import UserCompaniesSearch from "./UserCompaniesSearch";
import UserCompaniesList from "./UserCompaniesList";


const UserCompaniesDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Grid
      container
      spacing={2} 
      sx={{ padding: 3, backgroundColor: "#ffffff", minHeight: "50vh" }}
    >
      <Grid item xs={12} >
      <UserCompaniesSearch/>
    
      </Grid>

      <Grid item xs={12} sx={{marginTop:2}}>
      <UserCompaniesList/>
     
      </Grid>
    </Grid>
  );
};

export default UserCompaniesDashboard;
