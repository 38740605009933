import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { dataFetch, dataUpdate } from "../../../services/ApiEndPoint"; // Use PUT method for updating
import { useAuth } from "../../../contexts/AuthContext";

const EditTasksModal = ({ onClose, open, tasks, onUpdate }) => {
  const { token } = useAuth();
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [leads, setLeads] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token);
        if (response.status === 200) {
          setUsers(Array.isArray(response.data.data) ? response.data.data : []);
        }
      } catch (error) {
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, [token]);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await dataFetch("/customer/", token);
        if (response.status === 200) {
          setCustomers(response.data.data);
        }
      } catch (error) {
        toast.error("Failed to fetch customers");
      }
    };
    fetchCustomer();
  }, [token]);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await dataFetch("/lead/", token);
        if (response.status === 200) {
          setLeads(response.data.data);
        }
      } catch (error) {
        toast.error("Failed to fetch leads");
      }
    };
    fetchLeads();
  }, [token]);

  const validationSchema = Yup.object({
    title: Yup.string().required("Please enter the task title"),
    description: Yup.string().required("Please enter the title description"),
    status: Yup.string().required("Please enter the title status"),
    dueDate: Yup.string().required("Enter the due date"),
    assignedTo: Yup.string().required("Enter the details"),
    relatedCustomer: Yup.string().required("Enter the details"),
    relatedLead: Yup.string().required("Enter the details"),
    totalnoofpendingtasksbyuser: Yup.string().required("Enter the details"),
    totalnoofoverduetasksbyuser: Yup.string().required("Enter the details"),
  });

  const initialValues = {
    title: tasks?.title || "",
    description: tasks?.description || "",
    status: tasks?.status || "",
    dueDate: tasks?.dueDate || "",
    assignedTo: tasks?.assignedTo || "",
    relatedCustomer: tasks?.relatedCustomer || "",
    relatedLead: tasks?.relatedLead || "",
    totalnoofpendingtasksbyuser: tasks?.totalnoofpendingtasksbyuser || "",
    totalnoofoverduetasksbyuser: tasks?.totalnoofoverduetasksbyuser || "",
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const response = await dataUpdate(`task/${tasks._id}`, values, token);
      if (response.status === 200) {
        toast.success("Tasks updated successfully");
        onUpdate(values);
        resetForm();
      } else {
        toast.error("Failed to update tasks");
      }
    } catch (error) {
      toast.error("Error updating tasks");
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      initialValues.title = tasks?.title || "";
      initialValues.description = tasks?.description || "";
      initialValues.status = tasks?.status || "";
      initialValues.dueDate = tasks?.dueDate?.substring(0, 10) || new Date().toISOString().substring(0, 10);
      initialValues.assignedTo = tasks?.assignedTo?._id || "";
      initialValues.relatedCustomer = tasks?.relatedCustomer?._id || "";
      initialValues.relatedLead = tasks?.relatedLead?._id || "";
      initialValues.totalnoofpendingtasksbyuser = tasks?.totalnoofpendingtasksbyuser || "";
      initialValues.totalnoofoverduetasksbyuser = tasks?.totalnoofoverduetasksbyuser || "";
    }
  }, [tasks, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Tasks</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true} // Ensure the form is reinitialized when initialValues change
      >
        {({ touched, errors }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                {/* Form fields */}
                <Grid item xs={12} md={6}>
                  <Field
                    name="title"
                    as={TextField}
                    label="Title"
                    fullWidth
                    helperText={errors.title && touched.title ? errors.title : ""}
                    error={Boolean(errors.title && touched.title)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="description"
                    as={TextField}
                    label="Description"
                    fullWidth
                    helperText={errors.description && touched.description ? errors.description : ""}
                    error={Boolean(errors.description && touched.description)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="status"
                    as={TextField}
                    select
                    label="Status"
                    fullWidth
                    helperText={errors.status && touched.status ? errors.status : ""}
                    error={Boolean(errors.status && touched.status)}
                  >
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                    <MenuItem value="inprogress">In Progress</MenuItem>
                    <MenuItem value="overdue">Overdue</MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="dueDate"
                    type="date"
                    as={TextField}
                    label="Due Date"
                    fullWidth
                    helperText={errors.dueDate && touched.dueDate ? errors.dueDate : ""}
                    error={Boolean(errors.dueDate && touched.dueDate)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="assignedTo"
                    as={TextField}
                    select
                    label="Assigned To"
                    fullWidth
                    helperText={errors.assignedTo && touched.assignedTo ? errors.assignedTo : ""}
                    error={Boolean(errors.assignedTo && touched.assignedTo)}
                  >
                    {users.map((user) => (
                      <MenuItem key={user._id} value={user._id}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="relatedCustomer"
                    as={TextField}
                    select
                    label="Related Customer"
                    fullWidth
                    helperText={errors.relatedCustomer && touched.relatedCustomer ? errors.relatedCustomer : ""}
                    error={Boolean(errors.relatedCustomer && touched.relatedCustomer)}
                  >
                    {customers.map((customer) => (
                      <MenuItem key={customer._id} value={customer._id}>
                        {customer.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="relatedLead"
                    as={TextField}
                    select
                    label="Related Lead"
                    fullWidth
                    helperText={errors.relatedLead && touched.relatedLead ? errors.relatedLead : ""}
                    error={Boolean(errors.relatedLead && touched.relatedLead)}
                  >
                    {leads.map((lead) => (
                      <MenuItem key={lead._id} value={lead._id}>
                        {lead.fullname}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="totalnoofpendingtasksbyuser"
                    as={TextField}
                    label="Total No of Pending Tasks By User"
                    fullWidth
                    helperText={errors.totalnoofpendingtasksbyuser && touched.totalnoofpendingtasksbyuser ? errors.totalnoofpendingtasksbyuser : ""}
                    error={Boolean(errors.totalnoofpendingtasksbyuser && touched.totalnoofpendingtasksbyuser)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="totalnoofoverduetasksbyuser"
                    as={TextField}
                    label="Total No of Overdue Tasks By User"
                    fullWidth
                    helperText={errors.totalnoofoverduetasksbyuser && touched.totalnoofoverduetasksbyuser ? errors.totalnoofoverduetasksbyuser : ""}
                    error={Boolean(errors.totalnoofoverduetasksbyuser && touched.totalnoofoverduetasksbyuser)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Update Task
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditTasksModal;
