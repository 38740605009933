import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
} from "@mui/material";
import { useAuth } from "../../../../contexts/AuthContext";

import { dataFetch } from "../../../../services/ApiEndPoint";
import SuperAdminCard from "./SuperAdminCard";


function SuperAdminSearch() {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [sortBy, setSortBy] = useState("name");
  const [roleFilter, setRoleFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { token } = useAuth();

  const fetchUsers = async () => {
    setLoadingData(true);
    try {
      let url = `admin/query?page=${page}&limit=${rowsPerPage}&sort=${sortBy}&name=${searchTerm}&role=${roleFilter}&status=${statusFilter}`;
      let response = await dataFetch(url, token, "GET");
      if (response.status === 200) {
        setUsers(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [searchTerm, sortBy, roleFilter, statusFilter, page, rowsPerPage]);

  const handleUserClick = async (userId) => {
    setLoadingData(true);
    try {
      let url = `admin/${userId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedUser(response.data.user);
      } else {
        console.error(`Error fetching user details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access - possibly due to an invalid or expired token."
        );
      } else {
        console.error("Error fetching user details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset page to 1 when search term changes
  };

  return (
    <Grid container spacing={2}     justifyContent="center">
      {selectedUser ? (
        <SuperAdminCard user={selectedUser} />
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              User Dashboard
            </Typography>
          </Grid>

          {/* Search Bar, Sort, and Filter Controls */}
          <Grid
            container
            item
            xs={12}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={8} md={6}>
              <TextField
                label="Search users"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
                sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Sort By</InputLabel>
                <Select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  label="Sort By"
                  sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
                >
                  <MenuItem value="name">Name</MenuItem>
                  <MenuItem value="phone">Phone</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Role</InputLabel>
                <Select
                  value={roleFilter}
                  onChange={(e) => setRoleFilter(e.target.value)}
                  label="Role"
                  sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
                >
                  <MenuItem value="">All Roles</MenuItem>
                  <MenuItem value="manager">Manager</MenuItem>
                  <MenuItem value="user">user</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  label="Status"
                  sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
                >
                  <MenuItem value="">All Statuses</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Dynamic Search Results */}
          {searchTerm && !loadingData && (
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  maxHeight: 200,
                  overflow: "auto",
                  mt: 2,
                  padding: 2,
                  bgcolor: "#f1f1f1",
                  borderRadius: 2,
                }}
              >
                <List>
                  {users
                    .filter((user) =>
                      user.name
                        .toLowerCase()
                        .startsWith(searchTerm.toLowerCase())
                    )
                    .map((user) => (
                      <ListItem
                        key={user._id}
                        sx={{
                          bgcolor: "#f4f4f4",
                          mb: 1,
                          borderRadius: 2,
                          boxShadow: 1,
                        }}
                        button
                        onClick={() => handleUserClick(user._id)}
                      >
                        <ListItemText
                          primary={user.name}
                          secondary={`${user.role} - ${user.phone}`}
                          primaryTypographyProps={{ fontWeight: "bold" }}
                        />
                      </ListItem>
                    ))}
                </List>
              </Paper>
            </Grid>
          )}

          {loadingData && (
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: "100vh" }}
            >
              <CircularProgress />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default SuperAdminSearch;
