import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { getDateTimeFullWithTimeSync } from "../../../utils/DateTimeHelper";


function UserReportsProfile({ reports }) {
  return (
    <Grid item xs={12} sm={8} md={6}>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "20px",
          padding: "8px",
          maxWidth: "100%",
          margin: "auto",
          mt: "10px",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Report Name:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {reports?.reportname}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Entity Type:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {reports?.entitytype}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Report Type:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {reports?.reporttype}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Created At:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {getDateTimeFullWithTimeSync(reports?.createdat)}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Created By:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {reports?.createdby?.name}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Total Sales:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {reports?.totalsales}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Date Range:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {getDateTimeFullWithTimeSync(reports?.daterange?.startdate)} - {getDateTimeFullWithTimeSync(reports?.daterange?.enddate)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default UserReportsProfile;
