import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  Button,
  TableSortLabel,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { dataDelete, dataFetch } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";
import TasksCard from "./TasksCard";

import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from "@emotion/react";
import { tokens } from "../../../utils/theme";
import toast from "react-hot-toast";
import AddTasksModal from "./AddTasksModal";
import { getDateTimeFullWithTimeSync } from "../../../utils/DateTimeHelper";

const TasksList = () => {
  const [tasks, setTasks] = useState([]);
  const [totalTasks, setTotalTasks] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedTasks, setSelectedTasks] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [taskIdToDelete, setTaskIdToDelete] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { token } = useAuth();

  useEffect(() => {
    const fetchTasks = async () => {
      setLoadingData(true);
      try {
        const skip = (page - 1) * rowsPerPage; 
        let url = `task/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
        let response = await dataFetch(url, token);

        if (response.status === 200) {
          setTasks(response.data.data);
          setTotalTasks(response.data.total);
        } else {
          console.error(`Error fetching tasks: ${response.status}`);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized access - possibly due to an invalid or expired token.");
        } else {
          console.error("Error fetching tasks:", error);
        }
      } finally {
        setLoadingData(false);
      }
    };

    fetchTasks();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const addTasks = (newTasks) => {
    setTasks((prevTasks) => [...prevTasks, newTasks]);
    setTotalTasks((prevTotal) => prevTotal + 1);
  };

  const handleTasksClick = async (tasksId) => {
    setLoadingData(true);
    try {
      let url = `task/${tasksId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedTasks(response.data.data);
      } else {
        console.error(`Error fetching tasks details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching tasks details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  const handleOpenDeleteDialog = (taskId) => {
    setTaskIdToDelete(taskId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setTaskIdToDelete(null);
  };
  const handleConfirmDelete = async () => {
    if (taskIdToDelete) {
      setLoadingData(true);
      try {
        const url = `task/${taskIdToDelete}`;
        const response = await dataDelete(url, token);
        
        if (response.status === 200) {
          setTasks((prevTasks) => prevTasks.filter((task) => task._id !== taskIdToDelete));
          setTotalTasks((prevTotal) => prevTotal - 1);
          toast.success('Tasks deleted successfully');
        } else {
          console.error(`Error deleting tasks: ${response.status}`);
          toast.error('Error deleting tasks');
        }
      } catch (error) {
        console.error('Error deleting tasks:', error);
        toast.error('Error deleting tasks');
      } finally {
        setLoadingData(false);
        handleCloseDeleteDialog();
      }
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalTasks / rowsPerPage);

  return (
    <Grid container spacing={2} >
      {selectedTasks ? (
        <Grid item xs={12}>
          <TasksCard tasks={selectedTasks} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5" gutterBottom>
              Tasks Dashboard - Page {page}
            </Typography>
            <Box sx={{display:'flex', gap:'8px'}}>
        
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: 'blue',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'yellow',
                  color: 'black'
                },  borderRadius: '8px', // Example additional style
            padding: '8px 16px'
              }}
              onClick={handleOpenModal}
            >
              Add Tasks
            </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {loadingData ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                <CircularProgress />
              </Grid>
            ) : (
              <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {["title", "status", "dueDate", "assignedTo", "pendingTask", "dueTask", "delete"].map(header => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: 'bold',
                            padding: { xs: '8px', md: '16px' },
                            textAlign: 'center',
                            backgroundColor: colors.primary[500],
                            color: 'white',
                            '&:hover': {
                              backgroundColor: 'white',
                              color: 'black',
                            }
                          }}
                        >
                          <TableSortLabel
                            active={sortField === header}
                            direction={sortField === header ? sortOrder : 'asc'}
                            onClick={() => handleSortRequest(header)}
                            sx={{ textAlign: 'center' }}
                          >
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasks.map(task => (
                      <TableRow
                        key={task._id}
                        onClick={() => handleTasksClick(task._id)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: colors.primary[500],
                            color: 'white',
                            '& td': {
                              color: 'white',
                              backgroundColor: colors.primary[500],
                            }
                          }
                        }}
                      >
                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{task?.title}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{task?.status}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{getDateTimeFullWithTimeSync(task?.dueDate)}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{task?.assignedTo?.name}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{task?.totalnoofpendingtasksbyuser}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{task?.totalnoofoverduetasksbyuser}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenDeleteDialog(task._id);
                            }}
                            sx={{
                              backgroundColor: 'black',
                              color: 'white',
                              '&:hover': {
                                backgroundColor: 'white',
                                color: 'red',
                              }
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{
                  "& .MuiPaginationItem-root": {
                    fontSize: { xs: "0.8rem", md: "1rem" },
                    margin: "0 4px",
                  },
                  "& .MuiPaginationItem-ellipsis": { display: "none" },
                  "& .MuiPaginationItem-page.Mui-selected": {
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  },
                }}
              />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
          </Grid>
        </>
      )}
      {/* Add Task Modal */}
      <AddTasksModal open={openModal} onClose={handleCloseModal} onAddTasks={addTasks} />
    {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} fullWidth maxWidth="xs"
        PaperProps={{
          sx: {
            borderRadius: 10,
            padding: 2,
            minWidth: '300px',
            boxShadow: 3,
          },
        }}>
        <DialogTitle sx={{ textAlign: 'center', paddingBottom: 2 }}>Confirm Deletion</DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <DialogContentText sx={{ textAlign: 'center', marginBottom: 2 }}>
            Are you sure you want to delete this task? 
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            sx={{
              backgroundColor: 'error.main',
              color: 'white',
              '&:hover': {
                backgroundColor: 'error.dark',
              },
              padding: '8px 16px',
              marginRight: 1,
            }}
          >
            Yes
          </Button>
          <Button
            onClick={handleCloseDeleteDialog}
            variant="outlined"
            sx={{
              color: 'blue',
              borderColor: 'blue',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: 'blue',
                color: 'white',
              },
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

    </Grid>
  );
};

export default TasksList;
