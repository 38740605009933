import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { dataFetch, dataUpdate } from "../../../services/ApiEndPoint"; // Use PUT method for updating
import { useAuth } from "../../../contexts/AuthContext";

const EditCompaniesModal = ({ onClose, open, companies,onUpdate }) => {
  const { token } = useAuth();
  const [users, setUsers] = useState([]); 

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token);
         
        if(response.status === 200)
{
        setUsers(Array.isArray(response.data.data) ? response.data.data : []); 
      }
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, [token]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phonenumber: Yup.string().required("Phone Number is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    owner: Yup.string().required("Owner is required"),
    annualrevenue: Yup.string().required("Annual Revenue is required"),
    industry: Yup.string().required("Industry is required"),
    noofemployees: Yup.string().required("Number of Employees is required"),
  });

  const initialValues = {
   name:companies?.name || "",
    email:companies?.email || "",
    phonenumber:companies?.phonenumber || "",
    city:companies?.city || "",
    state:companies?.state || "",
    owner:companies?.owner || "",
    annualrevenue:companies?.annualrevenue || "",
    industry:companies?.industry || "",
    noofemployees:companies?.noofemployees || "",

  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const response = await dataUpdate(
        `companies/${companies._id}`,
        values,
        token
      ); // PUT method for updating
      console.log("Response:", response);
      if (response.status === 200) {
        toast.success("Companies updated successfully");
        onUpdate(values); 
        resetForm();
    
      } else {
        console.error("Failed to update companies:", response.data);
        toast.error("Failed to update companies");  
      }
    } catch (error) {
      console.error(
        "Error updating companies:",
        error.response || error.message || error
      );
      toast.error("Error updating companies");
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  useEffect(() => {
    // Reset the form with employee data when the modal opens
    if (open) {
initialValues.name = companies?.name || "";
initialValues.email = companies?.email || "";
initialValues.phonenumber = companies?.phonenumber || "";
initialValues.city = companies?.city || "";
initialValues.state = companies?.state || "";
initialValues.owner = companies?.owner?._id || "";
initialValues.annualrevenue = companies?.annualrevenue || "";
initialValues.industry = companies?.industry || "";
initialValues.noofemployees = companies?.noofemployees || "";

    }
  }, [companies, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Edit Companies</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true} // This ensures that form is reinitialized when initialValues change
      >
        {({ touched, errors }) => (
          <Form>
              <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="name"
                    as={TextField}
                    label="Name"
                    fullWidth
                    helperText={
                      <>{errors.name && touched.name ? errors.name : ""}</>
                    }
                    error={Boolean(errors.name && touched.name)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="email"
                    as={TextField}
                    label="Email"
                    fullWidth
                    helperText={
                      <>{errors.email && touched.email ? errors.email : ""}</>
                    }
                    error={Boolean(errors.email && touched.email)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="phonenumber"
                    as={TextField}
                    label="Phone Number"
                    fullWidth
                    helperText={
                      <>
                        {errors.phonenumber && touched.phonenumber
                          ? errors.phonenumber
                          : ""}
                      </>
                    }
                    error={Boolean(errors.phonenumber && touched.phonenumber)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="city"
                    as={TextField}
                    label="City"
                    fullWidth
                    helperText={
                      <>{errors.city && touched.city ? errors.city : ""}</>
                    }
                    error={Boolean(errors.city && touched.city)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="state"
                    as={TextField}
                    label="State"
                    fullWidth
                    helperText={
                      <>{errors.state && touched.state ? errors.state : ""}</>
                    }
                    error={Boolean(errors.state && touched.state)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                <Field
                    name="owner"
                    as={TextField}
                    select
                    label="Owner"
                    fullWidth
                    helperText={touched.owner ? errors.owner : ""}
                    error={Boolean(errors.owner && touched.owner)}
                  >
                    {users.length > 0 ? (
                      users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          {user.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No users available</MenuItem>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="annualrevenue"
                    as={TextField}
                    label="Annual Revenue"
                    fullWidth
                    helperText={
                      <>
                        {errors.annualrevenue && touched.annualrevenue
                          ? errors.annualrevenue
                          : ""}
                      </>
                    }
                    error={Boolean(
                      errors.annualrevenue && touched.annualrevenue
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="industry"
                    as={TextField}
                    label="Industry"
                    fullWidth
                    helperText={
                      <>
                        {errors.industry && touched.industry
                          ? errors.industry
                          : ""}
                      </>
                    }
                    error={Boolean(errors.industry && touched.industry)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="noofemployees"
                    as={TextField}
                    label="Number of Employees"
                    fullWidth
                    helperText={
                      <>
                        {errors.noofemployees && touched.noofemployees
                          ? errors.noofemployees
                          : ""}
                      </>
                    }
                    error={Boolean(
                      errors.noofemployees && touched.noofemployees
                    )}
                  />
                </Grid>

                {/* You can add more fields like Leaves, Attendance etc. similarly */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
             Update Companies
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditCompaniesModal;
