import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  TableSortLabel,
  Box,
  Select,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { dataFetch, dataUpdate } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../utils/theme";
import { getDateTimeFullWithTimeSync } from "../../../utils/DateTimeHelper";
import UserDealsCard from "./UserDealsCard";
import toast from "react-hot-toast";
import UserAddDealsModal from "./UserAddDeals";

const stages = [
  "New",
  "Contacted",
  "Qualified",
  "Lost",
  "Won",
  "Inactive",
  "Converted",
  "Unqualified",
  "Reassigned",
  "Reopened",
];

const UserDealsList = () => {
  const [deals, setDeals] = useState([]);
  const [totalDeals, setTotalDeals] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { token } = useAuth();
  const [open, setOpen] = useState(false);
  const [currentDealId, setCurrentDealId] = useState(null);
  const [comment, setComment] = useState("");
  const [pipelineStage, setPipelineStage] = useState("");

  useEffect(() => {
    const fetchDeals = async () => {
      setLoadingData(true);
      try {
        const skip = (page - 1) * rowsPerPage;
        let url = `deals/mydeals/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
        let response = await dataFetch(url, token);

        if (response.status === 200) {
          setDeals(response.data.data);
          setTotalDeals(response.data.total);
        } else {
          console.error(`Error fetching deals: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching deals:", error);
      } finally {
        setLoadingData(false);
      }
    };

    fetchDeals();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleDealClick = async (dealId) => {
    setLoadingData(true);
    try {
      let url = `deals/${dealId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedDeal(response.data.data);
      } else {
        console.error(`Error fetching deal details: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching deal details:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const addDeals = (newDeals) => {
    setDeals((prevDeals) => [...prevDeals, newDeals]);
    setTotalDeals((prevTotal) => prevTotal + 1);
  };
  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalDeals / rowsPerPage);

  const updatePipelineStage = async (dealId, newPipelineStage, comment) => {
    setLoadingData(true);
    try {
        const url = `/deals/${dealId}/pipelinestage`;
        const payload = {
            pipelinestage: newPipelineStage,
            pipelineComment: comment,
        };

        const response = await dataUpdate(url, payload, token);

        if (response.status === 200) {
            toast.success("Pipeline stage and comment updated successfully");
            setDeals((prevDeals) =>
                prevDeals.map((deal) =>
                    deal._id === dealId
                        ? {
                              ...deal,
                              pipelinestage: newPipelineStage,
                              pipelineComment: deal.pipelineComment
                                  ? [...deal.pipelineComment, comment]
                                  : [comment],
                          }
                        : deal
                )
            );
        } else {
            console.error(`Error updating pipeline stage: ${response.status}`, response.data);
        }
    } catch (error) {
        console.error("Error updating pipeline stage:", error);
    } finally {
        setLoadingData(false);
        setComment("");
        setPipelineStage("");
        handleClose();
    }
};


  const handleOpen = (dealId) => {
    setCurrentDealId(dealId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setComment(""); // Reset comment state when dialog closes
    setPipelineStage(""); // Reset pipeline stage state when dialog closes
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      {selectedDeal ? (
        <Grid item xs={12}>
          <UserDealsCard deals={selectedDeal} token={token} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h5" gutterBottom>
              Deals Dashboard - Page {page}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: 'blue',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'yellow',
                    color: 'black'
                  },
                  borderRadius: '8px',
                  padding: '8px 16px'
                }}
                onClick={handleOpenModal}
              >
                Add Deal
              </Button>
          </Grid>
          <Grid item xs={12}>
            {loadingData ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "50vh" }}>
                <CircularProgress />
              </Grid>
            ) : deals.length === 0 ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "50vh" }}>
                <Typography variant="h6" color="textSecondary">
                  No deals found! Please check back later.
                </Typography>
              </Grid>
            ) : (
              <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {["name", "actualdate", "company", "pipelinestage", "estimatedclosuredate"].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: "bold",
                            padding: { xs: "8px", md: "16px" },
                            textAlign: "center",
                            backgroundColor: colors.primary[500],
                            color: "white",
                            "&:hover": {
                              backgroundColor: "white",
                              color: "black",
                            },
                          }}
                        >
                          <TableSortLabel
                            active={sortField === header}
                            direction={sortField === header ? sortOrder : "asc"}
                            onClick={() => handleSortRequest(header)}
                            sx={{ textAlign: "center" }}
                          >
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deals.map((deal) => (
                      <TableRow
                        key={deal._id}
                        onClick={() => handleDealClick(deal._id)}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: colors.primary[500],
                            color: "white",
                            "& td": {
                              color: "white",
                              backgroundColor: colors.primary[500],
                            },
                          },
                        }}
                      >
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                          {deal?.name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {getDateTimeFullWithTimeSync(deal?.actualdate)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {deal?.company}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent row click event
                              handleOpen(deal._id); // Open the comment modal
                            }}
                          >
                            +
                          </Button>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {getDateTimeFullWithTimeSync(deal?.estimatedclosuredate)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{
                  "& .MuiPaginationItem-root": {
                    fontSize: { xs: "0.8rem", md: "1rem" },
                    margin: "0 4px",
                  },
                  "& .MuiPaginationItem-ellipsis": { display: "none" },
                  "& .MuiPaginationItem-page.Mui-selected": {
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  },
                }}
              />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
          </Grid>

          {/* Dialog for updating pipeline stage and comment */}
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Update Pipeline Stage</DialogTitle>
            <DialogContent>
              <Select
            value={pipelineStage}
            onChange={(e) => setPipelineStage(e.target.value)}
            displayEmpty
            fullWidth
            sx={{ mb: 2 }}
              >
            <MenuItem value="" disabled>Select Pipeline Stage</MenuItem>
            {stages.map((stage) => (
              <MenuItem key={stage} value={stage}>
                {stage}
              </MenuItem>
            ))}
              </Select>
              <TextField
                margin="dense"
                label="Comment"
                type="text"
                fullWidth
                variant="outlined"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={() => {
                  if (pipelineStage && comment) {
                    updatePipelineStage(currentDealId, pipelineStage, comment);
                  } else {
                    toast.error("Please select a stage and add a comment.");
                  }
                }}
                color="primary"
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
        <UserAddDealsModal open={openModal} onClose={handleCloseModal} onAddDeals={addDeals} />
    </Grid>
  );
};

export default UserDealsList;
