import React from "react";
import { Card, CardContent, Typography, Grid, Avatar } from "@mui/material";
import { getDateTimeFullWithTimeSync } from "../../../../utils/DateTimeHelper";

function SuperAdminProfile({ user }) {
  return (
    <Grid container spacing={2} justifyContent="center">
      {/* Card for Image and Status */}
      <Grid item xs={12} sm={8} md={5}>
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            padding: "16px",
            maxWidth: "100%",
            margin: "auto",
            mt: "10px",
            textAlign: "center",
          }}
        >
          <Avatar
            alt={user?.name}
            src={user?.img}
            sx={{ width: 100, height: 100, margin: "auto", mb: 2 }}
          />
          <Typography variant="h6">{user?.name}</Typography>
          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Typography variant="body1" fontWeight="bold">Status:</Typography>
            <Typography variant="body1" sx={{ ml: 1 }}>{user?.status?.toUpperCase()}</Typography>
          </Grid>
        </Card>
      </Grid>

      {/* Card for User Details */}
      <Grid item xs={12} sm={8} md={7}>
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            padding: "16px",
            maxWidth: "100%",
            margin: "auto",
            mt: "10px",
          }}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Details</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">Role:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{user?.role}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">Phone:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{user?.phone}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">Email:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{user?.email}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight="bold">Joining Date:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{getDateTimeFullWithTimeSync(user?.createdAt)}</Typography>
              </Grid>
              
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default SuperAdminProfile;
