import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FormControl, Stack } from "react-bootstrap";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { dataPost } from "../../services/ApiEndPoint";
import { useAuth } from "../../contexts/AuthContext";
import { toast } from "react-hot-toast";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Grid,
  Tooltip,
} from "@mui/material";

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Must be 3 characters or more")
    .max(24, "Must be 24 characters or less")
    .required("Name is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
  password: Yup.string()
    .required("This field is required")
    .min(8, "Password must be 8 or more characters")
    .matches(/(?=.*[a-z])(?=.*[A-Z])\w+/, "Password should contain at least one uppercase and lowercase character")
    .matches(/\d/, "Password should contain at least one number")
    .matches(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, "Password should contain at least one special character"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
  rememberMe: Yup.boolean(),
  organizationName: Yup.string().required("Organization name is required"),
  organizationSize: Yup.number().required("Organization size is required").positive().integer(),
});

const RegisterCard = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);




  // Initial form values
  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    organizationName: "",
    organizationSize: "",
    rememberMe: false,
  };

  const { login } = useAuth();

  // Form submission handler
  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    const { name, email, password, organizationName, organizationSize } = values;
    try {
      const response = await dataPost("auth/signup", {
        name,
        email,
        password,
        organizationName,
        organizationSize,
      });
      const data = await response.data;

      if (response.status === 201) {
        resetForm();
        login(data.token, data.user);
        toast.success(data.message);
        navigate("/dashboard");
      } else {
        toast.custom(data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Card
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 3,
      }}
    >
        <Tooltip title="ATS INNOVATIONS" placement="right">
                <IconButton>
                  <img
                    src="https://atsinnovations.in/logo.png"
                    alt="Company Logo"
                    style={{ width: 120, height: 60 }}
                  />
                </IconButton>
              </Tooltip>
      <CardContent>
        <Typography variant="h4" component="div" gutterBottom>
          Welcome!
        </Typography>
        <Typography variant="h5" component="div" gutterBottom>
          New User Register Here
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="name"
                      type="text"
                      label="Full Name"
                      fullWidth
                      variant="outlined"
                      helperText={errors.name && touched.name ? errors.name : ""}
                      error={Boolean(errors.name && touched.name)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="email"
                      type="email"
                      label="Email"
                      fullWidth
                      variant="outlined"
                      helperText={errors.email && touched.email ? errors.email : ""}
                      error={Boolean(errors.email && touched.email)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="organizationName"
                      type="text"
                      label="Organization Name"
                      fullWidth
                      variant="outlined"
                      helperText={errors.organizationName && touched.organizationName ? errors.organizationName : ""}
                      error={Boolean(errors.organizationName && touched.organizationName)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="organizationSize"
                      type="number"
                      label="Organization Size"
                      fullWidth
                      variant="outlined"
                      helperText={errors.organizationSize && touched.organizationSize ? errors.organizationSize : ""}
                      error={Boolean(errors.organizationSize && touched.organizationSize)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <Field
                    name="password"
                    as={TextField}
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="confirmPassword"
                    as={TextField}
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    fullWidth
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={<Field as={Checkbox} name="rememberMe" />}
                      label="Remember me"
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    mt: 2,
                    borderRadius: "10px",
                    color: "#ffffff",
                    minWidth: "170px",
                    minHeight: "50px",
                    backgroundColor: "#000080",
                  }}
                  disabled={isSubmitting}
                >
                  Register
                </Button>
                <Stack direction="row" spacing={2} >
                  <Typography variant="body1"  component="span"
                        sx={{ width: "60%", alignContent: "center", mt: 3 }}>
                    Already Have an Account?{" "}
                    <span
                      style={{
                        color: "#000080",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate("/")}
                    >
                      Login Here
                    </span>
                  </Typography>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default RegisterCard;
