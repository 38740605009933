import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { dataFetch, dataPost } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";

const AddQuotationsModal = ({ onClose, open,onAddQuotations }) => {
  const { token } = useAuth();

  const [users, setUsers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [deals, setDeals] = useState([]);
  const [companies, setCompanies] = useState([]);

  // Fetch Users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token);
        if(response.status===200){  
        setUsers(Array.isArray(response.data.data) ? response.data.data : []);
        }
      } catch (error) {
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, [token]);

  // Fetch Contacts
  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await dataFetch("/contacts/", token);
       
        if(response.status===200){
          setContacts(response.data.data);
        }
      } catch (error) {
        toast.error("Failed to fetch contacts");
      }
    };
    fetchContacts();
  }, [token]);

  // Fetch Deals
  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const response = await dataFetch("/deals/", token);
        if(response.status===200){
          setDeals(response.data.data);
        }
      } catch (error) {
        toast.error("Failed to fetch deals");
      }
    };
    fetchDeals();
  }, [token]);

  // Fetch Companies
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await dataFetch("/companies/get/", token);
        console.log("Fetched companies:", response.data);
        if(response.status===200){
          setCompanies(response.data.data);
        }
      } catch (error) {
        toast.error("Failed to fetch companies");
      }
    };
    fetchCompanies();
  }, [token]);

  // Validation Schema (createdAt and updatedAt are not included)
  const validationSchema = Yup.object({
    quotationssummary: Yup.string().required("Required"),
    associatedcompany: Yup.string().required("Required"),
    associateddeal: Yup.string().required("Required"),
    associatedcontacts: Yup.string().required("Required"),
    quotationsstatus: Yup.string().required("Required"),
    grandtotal: Yup.string().required("Required"),
    createdby: Yup.string().required("Required"),
  });

  // Initial Values (createdAt and updatedAt are omitted)
  const initialValues = {
    quotationssummary: "",
    associatedcompany: "",
    associateddeal: "",
    associatedcontacts: "",
    quotationsstatus: "",
    grandtotal: "",
    createdby: "",
  };

  // Handle Form Submission
  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const response = await dataPost("quotations/", values, token);
      if (response.status === 201) {
        toast.success("Quotation added successfully");
        onAddQuotations(response.data.data);
        resetForm();
        onClose();
      } else {
        toast.error("Failed to add quotation");
      }
    } catch (error) {
      toast.error("Error adding quotation");
    } finally {
      setSubmitting(false);
    }
  };
console.log(contacts);
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add New Quotation</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, errors, touched, isSubmitting }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Quotations Summary"
                    name="quotationssummary"
                    fullWidth
                    value={values.quotationssummary}
                    onChange={handleChange}
                    error={touched.quotationssummary && Boolean(errors.quotationssummary)}
                    helperText={touched.quotationssummary && errors.quotationssummary}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    label="Associated Company"
                    name="associatedcompany"
                    fullWidth
                    value={values.associatedcompany}
                    onChange={handleChange}
                    error={touched.associatedcompany && Boolean(errors.associatedcompany)}
                    helperText={touched.associatedcompany && errors.associatedcompany}
                  >
                    {companies.map((company) => (
                      <MenuItem key={company._id} value={company._id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    label="Associated Deal"
                    name="associateddeal"
                    fullWidth
                    value={values.associateddeal}
                    onChange={handleChange}
                    error={touched.associateddeal && Boolean(errors.associateddeal)}
                    helperText={touched.associateddeal && errors.associateddeal}
                  >
                    {deals.map((deal) => (
                      <MenuItem key={deal._id} value={deal._id}>
                        {deal.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    label="Associated Contacts"
                    name="associatedcontacts"
                    fullWidth
                    value={values.associatedcontacts}
                    onChange={handleChange}
                    error={touched.associatedcontacts && Boolean(errors.associatedcontacts)}
                    helperText={touched.associatedcontacts && errors.associatedcontacts}
                  >
                    {contacts.map((contact) => (
                      <MenuItem key={contact._id} value={contact._id}>
                        {contact.fullName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                  select
                    label="Quotation Status"
                    name="quotationsstatus"
                    fullWidth
                    value={values.quotationsstatus}
                    onChange={handleChange}
                    error={touched.quotationsstatus && Boolean(errors.quotationsstatus)}
                    helperText={touched.quotationsstatus && errors.quotationsstatus}
                  >

                    <MenuItem value="Draft">Draft</MenuItem>
                    <MenuItem value="Sent">Sent</MenuItem>
                    <MenuItem value="Accepted">Accepted</MenuItem>
                    <MenuItem value="Rejected">Rejected</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Grand Total"
                    name="grandtotal"
                    fullWidth
                    value={values.grandtotal}
                    onChange={handleChange}
                    error={touched.grandtotal && Boolean(errors.grandtotal)}
                    helperText={touched.grandtotal && errors.grandtotal}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    label="Created By"
                    name="createdby"
                    fullWidth
                    value={values.createdby}
                    onChange={handleChange}
                    error={touched.createdby && Boolean(errors.createdby)}
                    helperText={touched.createdby && errors.createdby}
                  >
                    {users.map((user) => (
                      <MenuItem key={user._id} value={user._id}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
                        <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
               </Button>
             <Button type="submit" color="primary">
                Add Quotation
              </Button>
            </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddQuotationsModal;
