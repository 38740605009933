import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
} from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";
import { dataFetch } from "../../../services/ApiEndPoint";
import UserMeetingsCard from "./UserMeetingsCard";

function UserMeetingsSearch() {
  const [meetings, setMeetings] = useState([]); 
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMeetings, setSelectedMeetings] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [sortBy, setSortBy] = useState("meetingName"); // Updated to match backend
  const [meetingTitleFilter, setMeetingTitleFilter] = useState("");
  const [meetingDurationFilter, setMeetingDurationFilter] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { token } = useAuth();

  const fetchMeetings = async () => {
    setLoadingData(true);
    try {
      let url = `meetings/mymeetings/query?page=${page }&limit=${rowsPerPage}&sort=${sortBy}&meetingName=${searchTerm}&meetingTitle=${meetingTitleFilter}&meetingDuration=${meetingDurationFilter}`;
      let response = await dataFetch(url, token, "GET");
      if (response.status === 200) {
        setMeetings(response.data.data);
      } else {
        setMeetings([]);
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Error fetching Meetings:", error);
      setMeetings([]);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchMeetings();
  }, [searchTerm, sortBy, meetingTitleFilter, meetingDurationFilter, page, rowsPerPage]);

  const handleMeetingsClick = async (meetingsId) => {
    setLoadingData(true);
    try {
      let url = `meetings/${meetingsId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedMeetings(response.data.data);
      } else {
        console.error(`Error fetching meetings details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching meetings details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  return (
    <Grid container spacing={2} >
      {selectedMeetings ? (
        <UserMeetingsCard meetings={selectedMeetings} />
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Meetings Dashboard
            </Typography>
          </Grid>

          {/* Search Bar, Sort, and Filter Controls */}
          <Grid container item xs={12} spacing={2} alignItems="center" justifyContent="space-between">
            <Grid item xs={12} sm={8} md={6}>
              <TextField
                label="Search meetings"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
                sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Sort By</InputLabel>
                <Select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  label="Sort By"
                  sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
                >
                  <MenuItem value="meetingName">Meeting Name</MenuItem>
                  <MenuItem value="meetingTitle">Meeting Title</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Meeting Title</InputLabel>
                <Select
                  value={meetingTitleFilter}
                  onChange={(e) => setMeetingTitleFilter(e.target.value)}
                  label="Meeting Title"
                  sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
                >
                  <MenuItem value="">All Titles</MenuItem>
                  <MenuItem value="Integration of Software">Integration of Software</MenuItem>
                  <MenuItem value="Upgradation of Software">Upgradation of Software</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Meeting Duration</InputLabel>
                <Select
                  value={meetingDurationFilter}
                  onChange={(e) => setMeetingDurationFilter(e.target.value)}
                  label="Meeting Duration"
                  sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
                >
                  <MenuItem value="">All Durations</MenuItem>
                  <MenuItem value="short">Short</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="long">Long</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Dynamic Search Results */}
          {searchTerm && !loadingData && (
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  maxHeight: 200,
                  overflow: "auto",
                  mt: 2,
                  padding: 2,
                  bgcolor: "#f1f1f1",
                  borderRadius: 2,
                }}
              >
                <List>
                  {meetings.map((meeting) => (
                    <ListItem
                      key={meeting?._id}
                      sx={{
                        bgcolor: "#f4f4f4",
                        mb: 1,
                        borderRadius: 2,
                        boxShadow: 1,
                      }}
                      button
                      onClick={() => handleMeetingsClick(meeting?._id)}
                    >
                      <ListItemText
                        primary={meeting?.meetingTitle}
                        secondary={`${meeting?.meetingName} - ${meeting?.meetingDuration}`}
                        primaryTypographyProps={{ fontWeight: "bold" }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          )}

          {loadingData && (
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: "100vh" }}
            >
              <CircularProgress />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default UserMeetingsSearch;
