import React, { useEffect,useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Drawer,
  Grid,
  Typography,
  MenuItem,
} from "@mui/material";
import { dataUpdate } from "../../../services/ApiEndPoint"; // Use PUT method for updating
import { useAuth } from "../../../contexts/AuthContext";

const EditContactsDrawer = ({ onClose, open, contacts,onUpdate }) => {
  const { token,userData } = useAuth();
  const [users, setUsers] = useState([]);


  useEffect(() => {
  setUsers(userData);

  }, [userData]);


  // Validation schema for form fields
  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    fullName: Yup.string().required("Required"),
    owner: Yup.string().required("Required"),
    company: Yup.string().required("Required"),
    designation: Yup.string().required("Required"),
    phoneNumber: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    address: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
  });

  // Initial values for the form
  const initialValues = {
    firstName: contacts?.firstName || "",
    fullName: contacts?.fullName || "",
    owner: contacts?.owner || "",
    company: contacts?.company || "",
    designation: contacts?.designation || "",
    phoneNumber: contacts?.phoneNumber || "",
    email: contacts?.email || "",
    address: contacts?.address || "",
    city: contacts?.city || "",
    state: contacts?.state || "",
    country: contacts?.country || "",
  };

  // Handle form submission
  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const response = await dataUpdate(
        `contacts/${contacts._id}`,
        values,
        token
      ); // PUT method for updating
      if (response.status === 200) {
        toast.success("Contacts updated successfully");
        onUpdate(values); 
        resetForm();
        
      } else {
        toast.error("Failed to update contact");
      }
    } catch (error) {
      toast.error("Error updating contact");
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  // Update form values when contacts data changes
  useEffect(() => {
    if (open) {
      initialValues.firstName = contacts?.firstName || "";
      initialValues.fullName = contacts?.fullName || "";
      initialValues.owner = contacts?.owner?._id || "";
      initialValues.company = contacts?.company || "";
      initialValues.designation = contacts?.designation || "";
      initialValues.phoneNumber = contacts?.phoneNumber || "";
      initialValues.email = contacts?.email || "";
      initialValues.address = contacts?.address || "";
      initialValues.city = contacts?.city || "";
      initialValues.state = contacts?.state || "";
      initialValues.country = contacts?.country || "";
    }
  }, [contacts, open]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: { xs: "100%", sm: "50%", md: "40%" } } }} // Responsive width
    >
      <div style={{ padding: "20px" }}>
        <Typography variant="h5" gutterBottom>
          Edit Contact
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ touched, errors }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="firstName"
                    as={TextField}
                    label="First Name"
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    error={Boolean(touched.firstName && errors.firstName)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="fullName"
                    as={TextField}
                    label="Full Name"
                    fullWidth
                    helperText={touched.fullName && errors.fullName}
                    error={Boolean(touched.fullName && errors.fullName)}
                  />
                </Grid>
               <Grid item xs={12} md={6}>
               <Field
                name="owner"
                as={TextField}
                label="Owner"
                fullWidth
                // value={values.owner} // Bind formik value for owner
                helperText={touched.owner ? errors.owner : ""}
                error={Boolean(errors.owner && touched.owner)}
                select
              >
                {users.name ? ( // Check if userData exists
                  <MenuItem key={users._id} value={users._id}>
                    {users.name}
                  </MenuItem>
                ) : (
                  <MenuItem disabled>No users available</MenuItem>
                )}
              </Field>
</Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="company"
                    as={TextField}
                    label="Company"
                    fullWidth
                    helperText={touched.company && errors.company}
                    error={Boolean(touched.company && errors.company)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="designation"
                    as={TextField}
                    label="Designation"
                    fullWidth
                    helperText={touched.designation && errors.designation}
                    error={Boolean(touched.designation && errors.designation)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="phoneNumber"
                    as={TextField}
                    label="Phone Number"
                    fullWidth
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="email"
                    as={TextField}
                    label="Email"
                    fullWidth
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="address"
                    as={TextField}
                    label="Address"
                    fullWidth
                    helperText={touched.address && errors.address}
                    error={Boolean(touched.address && errors.address)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="city"
                    as={TextField}
                    label="City"
                    fullWidth
                    helperText={touched.city && errors.city}
                    error={Boolean(touched.city && errors.city)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="state"
                    as={TextField}
                    label="State"
                    fullWidth
                    helperText={touched.state && errors.state}
                    error={Boolean(touched.state && errors.state)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="country"
                    as={TextField}
                    label="Country"
                    fullWidth
                    helperText={touched.country && errors.country}
                    error={Boolean(touched.country && errors.country)}
                  />
                </Grid>
              </Grid>

              <div style={{ marginTop: "20px", display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={onClose} color="secondary" style={{ marginRight: "10px" }}>
                  Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained">
                  Update Contact
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Drawer>
  );
};

export default EditContactsDrawer;
