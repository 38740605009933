import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";

function UserCustomerProfile({ customer }) {
  console.log(customer);
  return (
    <Grid item xs={12} sm={8} md={6}>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "20px",
          padding: "8px",
          maxWidth: "100%",
          margin: "auto",
          mt: "10px",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body" fontWeight="bold">
                Name:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body">{customer?.name}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body" fontWeight="bold">
               Phone:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body">{customer?.phone}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body" fontWeight="bold">
               Email:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body">{customer?.email}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body" fontWeight="bold">
                Status:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body">{customer?.status}</Typography>
            </Grid>

          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default UserCustomerProfile;
