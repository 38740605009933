import React,{useState} from "react";
import{Grid,Tabs,Typography,Button,Box, Tab}from "@mui/material";
import TabPanel from "../../../utils/TabPanel"; 
import UpdateQuotationsModal from "./EditQuotationsModal";
import QuotationsProfile from "./QuotationsProfile";

function QuotationsCard({quotations}){
    const[tabValue,setTabValue]=useState(0);
    const[isModalOpen,setModalOpen]=useState(false);// State to manage modal visiblity
    const [quotationsData, setQuotationsData] = useState(quotations); // Local state for quotations data

    const handleTabChange=(event,newValue)=>{
        setTabValue(newValue);
    };

    const handleModalOpen=()=>{
        setModalOpen(true);
    };

    const handleModalClose=()=>{
        setModalOpen(false);
    };

    const handleQuotationsUpdate = (updatedQuotations) => {
      setQuotationsData(updatedQuotations); // Update the quotations data in state
    };

    return(
        <Grid container spacing={2}>
            {/*Header*/}
            <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            >
                <Typography variant="h5" gutterBottom>
                    {quotationsData?.quotationssummary}'s Profile
                </Typography>
                <Button
                variant="contained"
                color="primary"
                sx={{
                    backgroundColor:'blue',
                    color:'white',
                    '&:hover':{
                        backgroundColor:'yellow',
                        color:'black'
                    },
                    borderRadius:'8px',//Example additional style
                    padding:'8px 16 px'//Example additional style
                }}
                onClick={handleModalOpen}
                >
                    Update Quotations
                </Button>
            </Grid>
            {/* Tabs */}
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
        
        >
          <Tab label="Profile" value={0} />
          <Tab label="Employment" value={1} />
        </Tabs>
      </Grid>

      {/* Profile Tab */}
      <TabPanel value={tabValue} index={0}>
        <Box sx={{ p: 2,  }}> {/* Example border style */}
          <QuotationsProfile quotations={quotationsData} />
        </Box>
      </TabPanel>

      {/* Employment Tab */}
      <TabPanel value={tabValue} index={1}>
        <Box sx={{ p: 2, }}> {/* Example border style */}
          
        </Box>
      </TabPanel>

      {/* Update contact Modal */}
      <UpdateQuotationsModal
        open={isModalOpen}
        onClose={handleModalClose}
        quotations={quotationsData}
        onUpdate={handleQuotationsUpdate} // Pass the update handler
      />
    </Grid>
  );
}

export default QuotationsCard;
