import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  Button,
  TableSortLabel,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { dataDelete, dataFetch } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../utils/theme";
import toast from "react-hot-toast";
import AddDealsModal from "./AddDealsModal";
import DealsCard from "./DealsCard";
import { getDateTimeFullWithTimeSync } from "../../../utils/DateTimeHelper";
import UploadDealsModal from "./UploadDealsModal";

const DealsList = () => {
  const [deals, setDeals] = useState([]);
  const [totalDeals, setTotalDeals] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { token } = useAuth();
  const [uploadOpen, setUploadOpen] = useState(false);

  // New state for delete confirmation
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dealIdToDelete, setDealIdToDelete] = useState(null);
  const fetchDeals = async () => {
    setLoadingData(true);
    try {
      const skip = (page - 1) * rowsPerPage;
      let url = `deals/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setDeals(response.data.data);
        setTotalDeals(response.data.total);
      } else {
        console.error(`Error fetching deals: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching deals:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };
  useEffect(() => {


    fetchDeals();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenUploadModal = () => {
    setUploadOpen(true);
  };

  const handleCloseUploadModal = () => {
    setUploadOpen(false);
  };

  const addDeals = (newDeals) => {
    setDeals((prevDeals) => [...prevDeals, newDeals]);
    setTotalDeals((prevTotal) => prevTotal + 1);
  };

  const handleDealClick = async (dealId) => {
    setLoadingData(true);
    try {
      let url = `deals/${dealId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedDeal(response.data.data);
      } else {
        console.error(`Error fetching deal details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching deal details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  const handleOpenDeleteDialog = (dealId) => {
    setDealIdToDelete(dealId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDealIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (dealIdToDelete) {
      setLoadingData(true);
      try {
        const url = `deals/${dealIdToDelete}`;
        const response = await dataDelete(url, token);

        if (response.status === 200) {
          setDeals(deals.filter(deal => deal._id !== dealIdToDelete));
          setTotalDeals(prev => prev - 1);
          toast.success('Deal deleted successfully');
        } else {
          console.error(`Error deleting deal: ${response.status}`);
          toast.error('Error deleting deal');
        }
      } catch (error) {
        console.error('Error deleting deal:', error);
        toast.error('Error deleting deal');
      } finally {
        setLoadingData(false);
        handleCloseDeleteDialog();
      }
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalDeals / rowsPerPage);

  return (
    <Grid container spacing={2} justifyContent="center">
      {selectedDeal ? (
        <Grid item xs={12}>
          <DealsCard deals={selectedDeal} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5" gutterBottom>
              Deals Dashboard - Page {page}
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <Button 
                variant="contained" 
                color="primary" 
                sx={{ 
                  backgroundColor: 'blue',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'yellow',
                    color: 'black'
                  },
                  borderRadius: '8px',
                  padding: '8px 16px'
                }}
                onClick={handleOpenUploadModal}
              >
                Upload File
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: 'blue',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'yellow',
                    color: 'black'
                  },
                  borderRadius: '8px',
                  padding: '8px 16px'
                }}
                onClick={handleOpenModal}
              >
                Add Deal
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {loadingData ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                <CircularProgress />
              </Grid>
            ) : (
              <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {["name", "actualdate", "company", "pipelinestage", "estimatedclosuredate", "delete"].map(header => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: 'bold',
                            padding: { xs: '8px', md: '16px' },
                            textAlign: 'center',
                            backgroundColor: colors.primary[500],
                            color: 'white',
                            '&:hover': {
                              backgroundColor: 'white',
                              color: 'black',
                            },
                          }}
                        >
                          <TableSortLabel
                            active={sortField === header}
                            direction={sortField === header ? sortOrder : 'asc'}
                            onClick={() => handleSortRequest(header)}
                            sx={{ textAlign: 'center' }}
                          >
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deals.map(deal => (
                      <TableRow
                        key={deal._id}
                        onClick={() => handleDealClick(deal._id)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: colors.primary[500],
                            color: 'white',
                            '& td': {
                              color: 'white',
                              backgroundColor: colors.primary[500],
                            }
                          }
                        }}
                      >
                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{deal?.name}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{getDateTimeFullWithTimeSync(deal?.actualdate)}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{deal?.company}</TableCell>
                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}><b>{deal?.pipelinestage.toUpperCase()}</b></TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{getDateTimeFullWithTimeSync(deal?.estimatedclosuredate)}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenDeleteDialog(deal._id);
                            }}
                            sx={{
                              backgroundColor: 'black',
                              color: 'white',
                              '&:hover': {
                                backgroundColor: 'white',
                                color: 'red',
                              }
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{ 
                  '& .MuiPaginationItem-root': { 
                    fontSize: { xs: '0.8rem', md: '1rem' },
                    margin: '0 4px'
                  },
                  '& .MuiPaginationItem-ellipsis': { display: 'none' },
                  '& .MuiPaginationItem-page.Mui-selected': {
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    }
                  }
                }}
              />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
          </Grid>

          {/* Delete Confirmation Dialog */}
          <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            fullWidth
            maxWidth="xs"
            PaperProps={{
              sx: {
                borderRadius: 10,
                padding: 2,
                minWidth: '300px',
                boxShadow: 3,
              },
            }}
          >
            <DialogTitle sx={{ textAlign: 'center', paddingBottom: 2 }}>
              <Typography variant="h6">Delete Confirmation</Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: 2 }}>
              <DialogContentText sx={{ textAlign: 'center', marginBottom: 2 }}>
                Are you sure you want to delete this deal?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
              <Button
                onClick={handleConfirmDelete}
                variant="contained"
                sx={{
                  backgroundColor: 'error.main',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'error.dark',
                  },
                  padding: '8px 16px',
                  marginRight: 1,
                }}
              >
                Yes
              </Button>
              <Button
                onClick={handleCloseDeleteDialog}
                variant="outlined"
                sx={{
                  color: 'blue',
                  borderColor: 'blue',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: 'blue',
                    color: 'white',
                  },
                }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
          <UploadDealsModal open={uploadOpen} onClose={handleCloseUploadModal}  onUpload={fetchDeals}/>
          <AddDealsModal open={openModal} onClose={handleCloseModal} onAddDeals={addDeals} />
        </>
      )}
    </Grid>
  );
};

export default DealsList;
