import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { dataFetch, dataPost } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";

const AddReportsModal = ({ onClose, open, onAddReports }) => {
  const { token } = useAuth();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token);
        if (response.status === 200) {
          setUsers(Array.isArray(response.data.data) ? response.data.data : []);
        }
      } catch (error) {
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, [token]);

  const reportTypes = [
    "One Dimensional",
    "Multi Dimensional",
    "Hierarchy",
    "Goals vs Achievement",
  ];

  const entityTypes = [
    "Lead",
    "Deal",
    "Contact",
    "Company",
    "Task",
    "Meeting",
    "Calllog",
  ];

  const validationSchema = Yup.object({
    reportname: Yup.string().required("Please enter the report name"),
    entitytype: Yup.string().required("Please enter the entity type"),
    reporttype: Yup.string().required("Please enter the report type"),
    createdat: Yup.date().required("Please enter the creation date"),
    createdby: Yup.string().required("Please enter the creator ID"),
    totalsales: Yup.number().required("Please enter the total sales"),
    daterange: Yup.object({
      startdate: Yup.date().required("Please enter the start date"),
      enddate: Yup.date().required("Please enter the end date"),
    }),
  });

  const initialValues = {
    reportname: "",
    entitytype: "",
    reporttype: "",
    createdat: new Date().toISOString().substring(0, 10), // Default to today
    createdby: "",
    totalsales: "",
    daterange: {
      startdate: "",
      enddate: "",
    },
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const response = await dataPost("reports/", values, token);
      if (response.status === 201) {
        toast.success("Reports added successfully");
        onAddReports(response.data.data);
        resetForm();
        
      // Callback to parent
      } else {
        toast.error("Failed to add reports");
      }
    } catch (error) {
      toast.error("Error adding reports");
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add New Reports</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="reportname"
                    as={TextField}
                    label="Report Name"
                    fullWidth
                    helperText={touched.reportname ? errors.reportname : ""}
                    error={Boolean(touched.reportname && errors.reportname)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={Boolean(touched.entitytype && errors.entitytype)}>
                    <InputLabel>Entity Type</InputLabel>
                    <Field
                      name="entitytype"
                      as={Select}
                      label="Entity Type"
                    >
                      {entityTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Field>
                    <div>{touched.entitytype && errors.entitytype}</div>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth error={Boolean(touched.reporttype && errors.reporttype)}>
                    <InputLabel>Report Type</InputLabel>
                    <Field
                      name="reporttype"
                      as={Select}
                      label="Report Type"
                    >
                      {reportTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Field>
                    <div>{touched.reporttype && errors.reporttype}</div>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="createdat"
                    as={TextField}
                    label="Created At"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    helperText={touched.createdat ? errors.createdat : ""}
                    error={Boolean(touched.createdat && errors.createdat)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="createdby"
                    as={TextField}
                    label="Created By (User ID)"
                    select
                    fullWidth
                    helperText={touched.createdby ? errors.createdby : ""}
                    error={Boolean(touched.createdby && errors.createdby)}
                  >
                    {users.map((user) => (
                      <MenuItem key={user._id} value={user._id}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="totalsales"
                    as={TextField}
                    label="Total Sales"
                    type="number"
                    fullWidth
                    helperText={touched.totalsales ? errors.totalsales : ""}
                    error={Boolean(touched.totalsales && errors.totalsales)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="daterange.startdate"
                    as={TextField}
                    label="Start Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    helperText={touched.daterange?.startdate ? errors.daterange?.startdate : ""}
                    error={Boolean(touched.daterange?.startdate && errors.daterange?.startdate)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="daterange.enddate"
                    as={TextField}
                    label="End Date"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    helperText={touched.daterange?.enddate ? errors.daterange?.enddate : ""}
                    error={Boolean(touched.daterange?.enddate && errors.daterange?.enddate)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Add Reports
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddReportsModal;
