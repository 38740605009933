import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  MenuItem,
  Menu,
} from "@mui/material";
import { dataFetch, dataPost } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";

const AddTasksModal = ({ onClose, open,onAddTasks }) => {
  const { token } = useAuth();
  const [users, setUsers] = useState([]);
 const [customers, setCustomers] = useState([]);
  const [leads, setLeads] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token);
        if (response.status === 200) {
          setUsers(Array.isArray(response.data.data) ? response.data.data : []);
        }
      } catch (error) {
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, [token]);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await dataFetch("/customer/", token);
        if (response.status === 200) {
          setCustomers(response.data.data);
        }
      } catch (error) {
        toast.error("Failed to fetch customers");
      }
    };
    fetchCustomer();
  }, [token]);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await dataFetch("/lead/", token);
        console.log("Response:", response.data);
        if (response.status === 200) {
          setLeads(response.data.data);
        }
      } catch (error) {
        toast.error("Failed to fetch leads");
      }
    };
    fetchLeads();
  }, [token]);

  const validationSchema = Yup.object({
    title: Yup.string().required("Please enter the task title"),
    description: Yup.string().required("Please enter the title description"),
    status: Yup.string().required("Please enter the title status"),
    dueDate: Yup.string().required("Enter the due date"),
    assignedTo: Yup.string().required("Enter the details"),
    relatedCustomer: Yup.string().required("Enter the details"),
    relatedLead: Yup.string().required("Enter the details"),
    totalnoofpendingtasksbyuser: Yup.string().required("Enter the details"),
    totalnoofoverduetasksbyuser: Yup.string().required("Enter the details"),
  });

  const initialValues = {
    title: "",
    description: "",
    status: "",
    dueDate: "",
    assignedTo: "",
    relatedCustomer: "",
    relatedLead: "",
    totalnoofpendingtasksbyuser: "",
    totalnoofoverduetasksbyuser: "",
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    {
      console.log("Values:", values);
    }

    try {
      const response = await dataPost("task/", values, token);
      console.log("Response:", response);
      if (response.status === 201) {
        toast.success("Tasks added successfully");
        onAddTasks(response.data.data);
        resetForm();
       
      } else {
        console.error("Failed to add tasks:", response.data);
        toast.error("Failed to add tasks");
      }
    } catch (error) {
      console.error(
        "Error adding tasks:",
        error.response || error.message || error
        
      );
      toast.error("Error adding tasks");
     
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add New Tasks</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, values }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="title"
                    as={TextField}
                    label="Title"
                    fullWidth
                    helperText={
                      <>{errors.title && touched.title ? errors.name : ""}</>
                    }
                    error={Boolean(errors.title && touched.title)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="description"
                    as={TextField}
                    label="Description"
                    fullWidth
                    helperText={
                      <>
                        {errors.description && touched.description
                          ? errors.description
                          : ""}
                      </>
                    }
                    error={Boolean(errors.description && touched.description)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="status"
                    as={TextField}
                    select
                    label="Status"
                    fullWidth
                    helperText={
                      <>
                        {errors.status && touched.status ? errors.status : ""}
                      </>
                    }
                    error={Boolean(errors.status && touched.status)}
                  >
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                    <MenuItem value="inprogress">In Progress</MenuItem>
                    <MenuItem value="overdue">Overdue</MenuItem>

                 </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="dueDate"
                    type="date"
                    as={TextField}
                    label="Due Date"
                    fullWidth
                    helperText={
                      <>
                        {errors.dueDate && touched.dueDate
                          ? errors.dueDate
                          : ""}
                      </>
                    }
                    error={Boolean(errors.dueDate && touched.dueDate)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="assignedTo"
                    as={TextField}
                    select
                    label="Assigned To"
                    fullWidth
                    helperText={
                      <>
                        {errors.assignedTo && touched.assignedTo
                          ? errors.assignedTo
                          : ""}
                      </>
                    }
                    error={Boolean(errors.assignedTo && touched.assignedTo)}
                  >
                    {users.map((user) => (
                      <MenuItem key={user._id} value={user._id}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="relatedCustomer"
                    as={TextField}
                    select
                    label="Related Customer"
                    fullWidth
                    helperText={
                      <>
                        {errors.relatedCustomer && touched.relatedCustomer
                          ? errors.relatedCustomer
                          : ""}
                      </>
                    }
                    error={Boolean(
                      errors.relatedCustomer && touched.relatedCustomer
                    )}
                  >
                    {customers.map((customer) => (
                      <MenuItem key={customer._id} value={customer._id}>
                        {customer.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                 name="relatedLead"
                    as={TextField}
                    select
                    label="Related Lead"
                    fullWidth
                    helperText={
                      <>
                        {errors.relatedLead && touched.relatedLead
                          ? errors.relatedLead
                          : ""}
                      </>
                    }
                    error={Boolean(errors.relatedLead && touched.relatedLead)}
                  >
                    {leads?.map((lead) => (
                      <MenuItem key={lead?._id} value={lead?._id}>
                        {lead?.fullname}
                      </MenuItem>
                    ))}
                  </Field>

                  
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="totalnoofpendingtasksbyuser"
                    as={TextField}
                    label="Total No of Pending Task By User"
                    fullWidth
                    helperText={
                      <>
                        {errors.totalnoofpendingtasksbyuser &&
                        touched.totalnoofpendingtasksbyuser
                          ? errors.totalnoofpendingtasksbyuser
                          : ""}
                      </>
                    }
                    error={Boolean(
                      errors.totalnoofpendingtasksbyuser &&
                        touched.totalnoofpendingtasksbyuser
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="totalnoofoverduetasksbyuser"
                    as={TextField}
                    label="Total No of Overdue Task By User"
                    fullWidth
                    helperText={
                      <>
                        {errors.totalnoofoverduetasksbyuser &&
                        touched.totalnoofoverduetasksbyuser
                          ? errors.totalnoofoverduetasksbyuser
                          : ""}
                      </>
                    }
                    error={Boolean(
                      errors.totalnoofoverduetasksbyuser &&
                        touched.totalnoofoverduetasksbyuser
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary" onSubmit={handleSubmit}>
                Add Tasks
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddTasksModal;
