import moment from 'moment';
import { format } from 'date-fns';

// Plain JavaScript Implementation
function getDayDateTime() {
  const now = new Date();
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
  };
  return now.toLocaleDateString('en-US', options) + ' ' + now.toLocaleTimeString('en-US', { hour12: true });
}

// date-fns Implementation
function getDateTimeWithDayMonthYearSyncDateFns() {
  const now = new Date();
  return format(now, "eeee, MMMM d, yyyy 'at' h:mm:ss a");
}

// moment.js Implementation
function getDateTimeFullWithTimeSync(date) {
  const momentDate = moment(date);
  return momentDate.format('dddd, MMMM Do YYYY, h:mm:ss a');
}

function getDateTimeSync(date) {
  const momentDate = moment(date);
  return momentDate.format('MMMM Do YYYY');
}
function getTimeSync(date) {
  const momentDate = moment(date);
  return momentDate.format('h:mm a');
}

// Function to format date and time for start and end dates
function formatDateRangeWithTime(startDate, endDate) {
  const start = moment(startDate).format('MMMM Do YYYY, h:mm a');
  const end = moment(endDate).format('MMMM Do YYYY, h:mm a');
  return `From ${start} to ${end}`;
}

// New function to get time zone
function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const isExpired = (meetingDate, meetingTime, meetingDuration) => {
  if (!meetingDate || !meetingTime || !meetingDuration) return true;

  // Convert meetingDuration from string to number
  const durationInMinutes = parseInt(meetingDuration, 10);
  if (isNaN(durationInMinutes)) return true; // Handle invalid duration

  // Create a Date object for the meeting date
  const meetingDateObj = new Date(meetingDate);
  if (isNaN(meetingDateObj.getTime())) return true; // Handle invalid date

  // Extract hours and minutes from meetingTime
  const [hours, minutes] = meetingTime.split(':').map(Number);
  if (isNaN(hours) || isNaN(minutes)) return true; // Handle invalid time

  // Set the hours and minutes
  meetingDateObj.setHours(hours, minutes, 0, 0); // Set to start of meeting

  // Calculate the end time by adding the duration
  const endDateTime = new Date(meetingDateObj.getTime() + durationInMinutes * 60000);

  // Get the current time
  const now = new Date();
  
  // Log for debugging
  console.log('Meeting Date Object:', meetingDateObj);
  console.log('End DateTime:', endDateTime);
  console.log('Current Time:', now);

  // Return true if the meeting has expired
  return now > endDateTime;
};


// Export functions
export {
  getDayDateTime,
  getDateTimeSync,
  getDateTimeWithDayMonthYearSyncDateFns as getDateTimeWithDayMonthYearSync,
  getDateTimeFullWithTimeSync,
  formatDateRangeWithTime, // New function for date range formatting
  getTimeZone,
  getTimeSync,
  isExpired
};


