import React from "react";
import { Grid } from "@mui/material";
import ImageCard from "./Login/ImageCard";
import RegisterCard from "./Register/RegisterCard";

export default function Register() {
  return (
    <Grid container spacing={2} sx={{ height: "100vh" }}>
      <Grid item xs={12} md={6}>
        <ImageCard
          loginimg={
            "https://www.mediatraining.ltd.uk/images/forgot-password.png"
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <RegisterCard />
      </Grid>
    </Grid>
  );
}
