import React, { useState, useEffect } from 'react';
import {
  Grid, Typography, CircularProgress, Box, Paper, useTheme, Pagination
} from '@mui/material';
import { tokens } from '../../../../utils/theme';
import { dataFetch } from '../../../../services/ApiEndPoint';
import { useAuth } from '../../../../contexts/AuthContext';
import { getDateTimeSync, getTimeSync } from '../../../../utils/DateTimeHelper';
import { format, parseISO } from 'date-fns';

const ActivityLogTimeline = ({ userId }) => {
  const [activityLogs, setActivityLogs] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [groupedLogs, setGroupedLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [logsPerPage] = useState(5);  // Logs per page per group (month)
  const { token } = useAuth();

  const fetchActivityLogs = async () => {
    try {
      const response = await dataFetch(`/user/activityLogs/${userId}`, token);
      if (response.data && Array.isArray(response.data.data)) {
        setActivityLogs(response.data.data);
      } else {
        console.error('Invalid activity logs data format');
        setActivityLogs([]);
      }
    } catch (error) {
      console.error('Error fetching activity logs', error);
      setActivityLogs([]);
    } finally {
      setLoadingData(false);
    }
  };

  const groupByMonth = (logs) => {
    const grouped = logs.reduce((acc, log) => {
      const date = parseISO(log.timestamp);
      const monthYear = format(date, 'MMMM yyyy'); // Format as "Month Year"
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(log);
      return acc;
    }, {});

    return Object.entries(grouped).map(([monthYear, logs]) => ({
      monthYear,
      logs,
    }));
  };

  useEffect(() => {
    fetchActivityLogs();
  }, []);

  useEffect(() => {
    if (activityLogs.length > 0) {
      const grouped = groupByMonth(activityLogs);
      setGroupedLogs(grouped);
    }
  }, [activityLogs]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const getColorForAction = (action) => {
    switch (action.toLowerCase()) {
      case 'login':
        return 'green'; // Green for login
      case 'logout':
        return 'red'; // Red for logout
      default:
        return colors.primary[300]; // Default color
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const totalPages = groupedLogs.length;

  // Separate login and logout logs
  const separateLogs = (logs) => {
    const loginLogs = logs.filter(log => log.action.toLowerCase() === 'login');
    const logoutLogs = logs.filter(log => log.action.toLowerCase() === 'logout');
    return { loginLogs, logoutLogs };
  };

  // Count logins and logouts
  const countLoginLogout = (logs) => {
    const loginLogs = logs.filter(log => log.action.toLowerCase() === 'login').length;
    const logoutLogs = logs.filter(log => log.action.toLowerCase() === 'logout').length;
    return { loginLogs, logoutLogs };
  };

  return (
    <Grid container spacing={2} justifyContent="center" sx={{ width: '100%' }}>
      <Grid item xs={12}>
        {loadingData ? (
          <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
            <CircularProgress />
          </Grid>
        ) : (
          <Box sx={{ width: '100%' }}>
            {groupedLogs.length > 0 &&
              groupedLogs
                .slice((page - 1) * logsPerPage, page * logsPerPage)
                .map(({ monthYear, logs }) => {
                  const { loginLogs, logoutLogs } = separateLogs(logs);
                  const { loginLogs: loginCount, logoutLogs: logoutCount } = countLoginLogout(logs);
                  return (
                    <Box key={monthYear} sx={{ marginBottom: 3 }}>
                      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                        {monthYear} ({loginCount} Logins, {logoutCount} Logouts)
                      </Typography>

                      <Grid container spacing={2}>
                        {/* Left side: Login Logs */}
                        <Grid item xs={12} md={6}>
                          {loginLogs.map((log) => (
                            <Paper key={log._id} sx={{ padding: 2, marginBottom: 2, borderRadius: 2, backgroundColor: 'transparent' }}>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={2} sx={{ textAlign: 'center' }}>
                                  <Box
                                    sx={{
                                      display: 'inline-block',
                                      width: 10,
                                      height: 10,
                                      borderRadius: '50%',
                                      backgroundColor: getColorForAction(log.action)
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={10}>
                                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {log.action.toUpperCase()}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {getDateTimeSync(log?.timestamp || "-")} | {getTimeSync(log?.timestamp || "-")}
                                  </Typography>
                                  <Typography variant="body2">{log.details || '-'}</Typography>
                                </Grid>
                              </Grid>
                            </Paper>
                          ))}
                        </Grid>

                        {/* Right side: Logout Logs */}
                        <Grid item xs={12} md={6}>
                          {logoutLogs.map((log) => (
                            <Paper key={log._id} sx={{ padding: 2, marginBottom: 2, borderRadius: 2, backgroundColor: 'transparent' }}>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={2} sx={{ textAlign: 'center' }}>
                                  <Box
                                    sx={{
                                      display: 'inline-block',
                                      width: 10,
                                      height: 10,
                                      borderRadius: '50%',
                                      backgroundColor: getColorForAction(log.action)
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={10}>
                                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {log.action.toUpperCase()}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {getDateTimeSync(log?.timestamp || "-")} | {getTimeSync(log?.timestamp || "-")}
                                  </Typography>
                                  <Typography variant="body2">{log.details || '-'}</Typography>
                                </Grid>
                              </Grid>
                            </Paper>
                          ))}
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
          </Box>
        )}
      </Grid>

      {groupedLogs.length > 0 && (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            sx={{
              '& .MuiPaginationItem-root': {
                fontSize: { xs: '0.8rem', md: '1rem' },
                margin: '0 4px',
              },
              '& .MuiPaginationItem-ellipsis': { display: 'none' },
              '& .MuiPaginationItem-page.Mui-selected': {
                backgroundColor: 'black',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'black',
                },
              },
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ActivityLogTimeline;
