import React, { useState } from "react";
import { Grid, Tabs, Tab, Typography, Button, Box } from "@mui/material";
import TabPanel from "../../../../utils/TabPanel";
import SuperAdminProfile from "./SuperAdminProfile";
import EditAdminDrawer from "./EditAdminDrawer";

 // Import the modal

function SuperAdminCard({ user }) {
  const [tabValue, setTabValue] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false); // State to manage modal visibility
  const [userData, setUserData] = useState(user); // Local state for user data

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleUserUpdate = (updatedUser) => {
    setUserData(updatedUser); // Update the user data in state
  };

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" gutterBottom>
          {userData.name}'s Profile
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ 
  
            backgroundColor: 'blue',
            color: 'white',
            '&:hover': {
              backgroundColor: 'yellow',
              color: 'black'
            },
            borderRadius: '8px', // Example additional style
            padding: '8px 16px' // Example additional style
          }}
          onClick={handleModalOpen}
        >
          Update user
        </Button>
      </Grid>

      {/* Tabs */}
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
         
        >
          <Tab label="Profile" value={0} />
          <Tab label="Employment" value={1} />
        </Tabs>
      </Grid>

      {/* Profile Tab */}
      <TabPanel value={tabValue} index={0}>
        <Box > {/* Example border style */}
          <SuperAdminProfile user={userData} />
        </Box>
      </TabPanel>

      {/* Employment Tab */}
      <TabPanel value={tabValue} index={1}>
        <Box sx={{ p: 2,  }}> {/* Example border style */}
          
        </Box>
      </TabPanel>

      {/* Update user Modal */}
      <EditAdminDrawer
        open={isModalOpen}
        onClose={handleModalClose}
        user={userData}
        onUpdate={handleUserUpdate} // Pass the update handler
      />
    </Grid>
  );
}

export default SuperAdminCard;
