import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Stack } from "react-bootstrap";
import { dataPost } from "../../services/ApiEndPoint";
import { useAuth } from "../../contexts/AuthContext";
import { toast } from "react-hot-toast";
import { redirectByRole } from "../../utils/RedirectByrole";

import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  AlertTitle,
  Alert,
  Grid,
  Tooltip,
} from "@mui/material";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
  password: Yup.string()
    .required("This field is required")
    .min(8, "Pasword must be 8 or more characters")
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])\w+/,
      "Password ahould contain at least one uppercase and lowercase character"
    )
    .matches(/\d/, "Password should contain at least one number")
    .matches(
      /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
      "Password should contain at least one special character"
    ),
  rememberMe: Yup.boolean(),
});

const LoginCard = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  //Show password
  const EndAdorment = ({ visible, setVisible }) => {
    return (
      <InputAdornment position="end">
        <IconButton
          onClick={() => {
            setVisible(!visible);
          }}
        >
          {visible ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
        </IconButton>
      </InputAdornment>
    );
  };

  // Initial form values
  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const { login } = useAuth();
  // Form submission handler
  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    console.log("Form values:", values);

    const { email, password } = values;
    try {
      const response = await dataPost("auth/login", { email, password });
      const data = await response.data;
      const { token, user } = data;
      localStorage.setItem("token", token);
      localStorage.setItem("role", user.role);

      if (response.status === 200) {
        resetForm();
        login(data.token, data.user);
        toast.success(data.message);
        <Navigate to={redirectByRole(data.role)} />;
      } else if (response.status == 401) {
        console.log("Invalid!", data.message);
        toast.custom(data.message);
      } else if (response.status == 404) {
        console.log("User Not Found", data.message);
        toast.custom(data.message);
      } else {
        console.log("Login Failed", data.message);
        toast.error("Login Failed", data.message);
      }
    } catch (error) {
      console.log("error", error.response.data.message);
      toast.error(error.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Card
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 3,
      }}
    >
      <Tooltip title="ATS INNOVATIONS" placement="right">
                <IconButton>
                  <img
                    src="https://atsinnovations.in/logo.png"
                    alt="Company Logo"
                    style={{ width: 120, height: 60 }}
                  />
                </IconButton>
              </Tooltip>
      <CardContent>
        <Typography variant="h4" component="div" gutterBottom>
          Welcome Back !
        </Typography>
        <Typography variant="h5" component="div" gutterBottom>
          Login to your account
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched, onChange }) => (
            <Form>

              <Box sx={{ mt: 2 }}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>

                    <Field
                      as={TextField}
                      name="email"
                      type="email"
                      label="Email"
                      fullWidth
                      variant="outlined"
                      sx={{ mt: 2 }}
                      margin="normal"
                      helperText={
                        <>{errors.email && touched.email ? errors.email : ""}</>
                      }
                      error={Boolean(errors.email && touched.email)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="password"
                      as={TextField}
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      helperText={touched.password && errors.password}
                      error={Boolean(touched.password && errors.password)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Stack direction="row" spacing={2}>
                      <FormControlLabel
                        control={<Field as={Checkbox} name="rememberMe" />}
                        label="Remember me"
                      />
                      <Typography
                        variant="body1"
                        component="span"
                        onClick={() => {
                          navigate("/forget-pwd");
                        }}
                        style={{
                          marginTop: "10px",
                          cursor: "pointer",
                          color: "#000080",
                        }}
                        color="primary"
                        sx={{ mt: 1, mb: 1, display: "block" }}
                      >
                        Forgot password?
                      </Typography>
                    </Stack>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={isSubmitting}
                      sx={{
                        mt: "10px",
                        mr: "20px",
                        borderRadius: "10px",
                        color: "#ffffff",
                        minWidth: "170px",
                        minHeight: "50px",
                        backgroundColor: "#000080",
                      }}
                    >
                      Login
                    </Button>
                    {/* <Stack direction="row" spacing={2}>
                      <Typography
                        variant="body1"
                        component="span"
                        sx={{ width: "60%", alignContent: "center", mt: 3 }}
                      >
                        Not registered yet?{" "}
                        <span
                          style={{
                            color: "blue",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate("/register");
                          }}
                        >
                          Create an Account
                        </span>
                      </Typography>
                    </Stack> */}
                  </Grid>


                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default LoginCard;
