import React, { useState, useEffect } from 'react';
import {
  Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Pagination, Typography, CircularProgress, Button, TableSortLabel,
  useTheme, IconButton, Box, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle
} from '@mui/material';
import { dataFetch, dataDelete } from '../../../../services/ApiEndPoint';

// import AdduserModal from './AdduserModal';
import { useAuth } from '../../../../contexts/AuthContext';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { tokens } from '../../../../utils/theme';
import DeleteIcon from '@mui/icons-material/Delete';
// import UploaduserModal from './UploaduserModal';
import toast from 'react-hot-toast';
import UserCard from './UserCard';
import AddUserModal from './AddUserDrawer';
import AddUserDrawer from './AddUserDrawer';

const UserList = () => {
  const [user, setUser] = useState([]);
  const [totalUser, setTotalUser] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [selecteduser, setSelecteduser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const { token } = useAuth();
  const [uploadOpen, setUploadOpen] = useState(false);

  // Delete confirmation dialog state
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [userNameToDelete, setUserNameToDelete] = useState('');


  const fetchUser = async () => {
    setLoadingData(true);
    try {
      const skip = (page - 1) * rowsPerPage;
      let url = `user/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}&role=user|admin`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setUser(response.data.data);
        setTotalUser(response.data.total);
      } else {
        console.error(`Error fetching user: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching user:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const addUser = (newuser) => {
    setUser((prevuser) => [...prevuser, newuser]);
    setTotalUser((prevTotal) => prevTotal + 1);
  };

  const handleUserClick = async (userId) => {
    setLoadingData(true);
    try {
      let url = `user/${userId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelecteduser(response.data.data);
      } else {
        console.error(`Error fetching user details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching user details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortField(field);
  };

  const handleDeleteUser = (user) => {
    setUserIdToDelete(user._id);
    setUserNameToDelete(user.name);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (userIdToDelete) {
      setLoadingData(true);
      try {
        const url = `user/${userIdToDelete}`;
        const response = await dataDelete(url, token);

        if (response.status === 200) {
          setUser(user.filter(user => user._id !== userIdToDelete));
          setTotalUser(prev => prev - 1);
         toast.success('user deleted successfully');
        } else {
          console.error(`Error deleting user: ${response.status}`);
          toast.error('Error deleting user', response.data.message);
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        toast.error('Error deleting user', error.message);
      } finally {
        setLoadingData(false);
        setOpenDeleteDialog(false);
        setUserIdToDelete(null);
        setUserNameToDelete('');
      }
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setUserIdToDelete(null);
    setUserNameToDelete('');
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalUser / rowsPerPage);



  return (
    <Grid container spacing={2} justifyContent="center">
      {selecteduser ? (
        <Grid item xs={12}>
          <UserCard user={selecteduser} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="h5" gutterBottom
            >
              User Dashboard - Page {page}
            </Typography>

            <Box sx={{ display: 'flex', gap: '8px' }}>
        

              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: 'blue',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'yellow',
                    color: 'black'
                  },
                  borderRadius: '8px',
                  padding: '8px 16px'
                }}
                onClick={handleOpenModal}
              >
                Add user
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12}>
            {loadingData ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                <CircularProgress />
              </Grid>
            ) : (
              <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {['name', 'email', 'role', 'status', 'phone', 'delete'].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: 'bold',
                            padding: { xs: '8px', md: '16px' },
                            textAlign: 'center',
                            backgroundColor: colors.primary[300],
                            color: 'white',
                            '&:hover': {
                              backgroundColor: 'white',
                              color: colors.primary[300],
                            },
                          }}
                        >
                          <TableSortLabel
                            active={sortField === header}
                            direction={sortField === header ? sortOrder : 'asc'}
                            onClick={() => handleSortRequest(header)}
                            sx={{ textAlign: 'center' }}
                          >
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user.map((user) => (
                      <TableRow
                        key={user._id}
                        onClick={() => handleUserClick(user._id)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: colors.primary[300],
                            color: 'white',
                            '& td': {
                              color: 'white',
                              backgroundColor: colors.primary[300],
                            }
                          }
                        }}
                      >
                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{user?.name}</TableCell>
                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{user?.email}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              width: '70%',
                              m: '0 auto',
                              p: '5px',
                              borderRadius: '10px',
                              backgroundColor:
                                user?.role === "admin"
                                  ? colors.greenAccent[400]
                                  : user?.role === "manager"
                                  ? colors.greenAccent[500]
                                  : colors.greenAccent[600],
                              '&:hover': {
                                backgroundColor: user?.role === "admin"
                                  ? colors.greenAccent[200]
                                  : user?.role === "manager"
                                  ? colors.greenAccent[200]
                                  : colors.greenAccent[300],
                              }
                            }}
                          >
                            {user?.role === "admin" && <AdminPanelSettingsOutlinedIcon />}
                            {user?.role === "manager" && <SecurityOutlinedIcon />}
                            {user?.role === "user" && <LockOpenOutlinedIcon />}
                            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                              {user?.role}
                            </Typography>
                          </Grid>
                        </TableCell>
                        <TableCell
                      sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: user?.status === 'active' ? 'green' : 'red',
                        textTransform: 'uppercase',
                      }}
                    >
                      {user?.status || '-'}
                    </TableCell>
                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{user?.phone}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the row click event when clicking the delete button
                              handleDeleteUser(user);
                            }}
                            sx={{
                              backgroundColor: 'black',
                              color: 'white',
                              '&:hover': {
                                backgroundColor: 'white',
                                color: 'red',
                              }
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{
                  '& .MuiPaginationItem-root': {
                    fontSize: { xs: '0.8rem', md: '1rem' },
                    margin: '0 4px'
                  },
                  '& .MuiPaginationItem-ellipsis': { display: 'none' },
                  '& .MuiPaginationItem-page.Mui-selected': {
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    }
                  }
                }}
              />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
          </Grid>
        </>
      )}

      {/* Add user Modal */}
      <AddUserDrawer open={openModal} onClose={handleCloseModal} onAddUser={addUser} />

    

      {/* Delete Confirmation Dialog */}
      <Dialog
  open={openDeleteDialog}
  onClose={handleCloseDeleteDialog}
  fullWidth
  maxWidth="xs"
  PaperProps={{
    sx: {
      borderRadius: 10,
      padding: 2,
      minWidth: '300px',  // Set a minimum width for better appearance
    },
  }}
>
  <DialogTitle sx={{ textAlign: 'center', paddingBottom: 2 }}>Delete Confirmation</DialogTitle>
  <DialogContent sx={{ padding: 2 }}>
    <DialogContentText sx={{ textAlign: 'center', marginBottom: 2 }}>
      Are you sure you want to delete {userNameToDelete}?
    </DialogContentText>
  </DialogContent>
  <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
    <Button
      onClick={handleConfirmDelete}
      variant="contained"
      sx={{
        backgroundColor: 'blue',
        color: 'white',
        '&:hover': {
          backgroundColor: 'yellow',
          color: 'black',
        },
        padding: '8px 16px', // Adjusted padding for better button size
        marginRight: 1, // Margin between buttons
      }}
    >
      Yes
    </Button>
    <Button
      onClick={handleCloseDeleteDialog}
      variant="contained"
      sx={{
        backgroundColor: 'blue',
        color: 'white',
        '&:hover': {
          backgroundColor: 'yellow',
          color: 'black',
        },
        padding: '8px 16px', // Adjusted padding for consistency
      }}
    >
      No
    </Button>
  </DialogActions>
</Dialog>

    </Grid>
  );
};

export default UserList;
