import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
  Box,
  Pagination,
} from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";
import { dataFetch } from "../../../services/ApiEndPoint";
import ReportsCard from "./ReportsCard"; // Assuming this component exists

function ReportsSearch() {
  const [reports, setReports] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedReport, setSelectedReport] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [sortBy, setSortBy] = useState("reportname");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalReports, setTotalReports] = useState(0);

  const { token } = useAuth();

  const fetchReports = async () => {
    setLoadingData(true);
    try {
      const url = `reports/query?skip=${(page - 1) * rowsPerPage}&limit=${rowsPerPage}&sort=${sortBy}&reportname=${searchTerm}`;
      let response = await dataFetch(url, token, "GET");

      if (response.status === 200) {
        setReports(response.data.data);
        setTotalReports(response.data.total); // Update total reports for pagination
      } else {
        console.error("Error fetching reports:", response.status);
      }
    } catch (error) {
      console.error("Error fetching Reports:", error);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchReports();
  }, [searchTerm, sortBy, page, rowsPerPage]);

  const handleReportClick = async (reportId) => {
    setLoadingData(true);
    try {
      const url = `reports/${reportId}`;
      const response = await dataFetch(url, token);
      if (response.status === 200) {
        setSelectedReport(response.data.data);
      } else {
        console.error(`Error fetching report details: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching report details:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset to page 1 when search term changes
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid container spacing={2}>
      {selectedReport ? (
        <ReportsCard reports={selectedReport} />
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Reports Dashboard
            </Typography>
          </Grid>

          {/* Search Bar and Sort Controls */}
          <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item xs={12} sm={8}>
              <TextField
                label="Search reports"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
                sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Sort By</InputLabel>
                <Select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  label="Sort By"
                  sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
                >
                  <MenuItem value="reportname">Report Name</MenuItem>
                  <MenuItem value="entitytype">Entity Type</MenuItem>
                  <MenuItem value="reporttype">Report Type</MenuItem>
                  <MenuItem value="createdat">Created At</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Conditional Search Results Display */}
          {loadingData ? (
            <Grid item xs={12} container justifyContent="center" alignItems="center" sx={{ minHeight: "100vh" }}>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {searchTerm && reports.length > 0 ? ( // Only show results if search term is present
                <>
                  <Grid item xs={12}>
                    <Paper elevation={3} sx={{ maxHeight: 300, overflow: "auto", mt: 2, padding: 2 }}>
                      <List>
                        {reports.map((report) => (
                          <ListItem
                            key={report._id}
                            sx={{
                              bgcolor: "#f4f4f4",
                              mb: 1,
                              borderRadius: 2,
                              boxShadow: 1,
                            }}
                            button
                            onClick={() => handleReportClick(report._id)}
                          >
                            <ListItemText
                              primary={report?.reportname}
                              secondary={`${report?.entitytype} - ${report?.reporttype}`}
                              primaryTypographyProps={{ fontWeight: "bold" }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Grid>

                  {/* Pagination */}
                  <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                    <Pagination
                      count={Math.ceil(totalReports / rowsPerPage)}
                      page={page}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    {searchTerm ? "No results found." : "Start searching for reports."}
                  </Typography>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  );
}

export default ReportsSearch;
