import React from "react";
import {Grid,Typography,useTheme} from "@mui/material";

import{tokens} from "../../../utils/theme";
import ReportSearch from "./ReportsSearch.jsx";
import ReportList from "./ReportsList.jsx";

const  ReportsDashboard=()=>{
    const theme=useTheme();
    const colors=tokens(theme.palette.mode);

    return(
        <Grid
        container
        spacing={2}
        sx={{padding:3,backgroundColor:"#ffffff",minHeight:"50vh"}}
        >
            <Grid item xs={12} >
                <ReportSearch/>
            </Grid>

            <Grid item xs={12} sx={{marginTop:2}}>
                <ReportList/>
            </Grid>
        </Grid>
    );
};

export default ReportsDashboard;