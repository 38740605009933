import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";
import { dataPost1 } from "../../../services/ApiEndPoint"; // Ensure this is correctly set up
import toast from "react-hot-toast";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AttachFile } from "@mui/icons-material";

const UploadContactsModal = ({ onClose, open, onUpload }) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    file: Yup.mixed().required("A file is required"),
  });

  const handleFileChange = (event, setFieldValue) => {
    const selectedFile = event.currentTarget.files[0];

    if (!selectedFile) {
      console.error('No file selected'); // Debugging line
      return;
    }

    // Validate file extension
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
    if (!['csv', 'xlsx'].includes(fileExtension)) {
      toast.error("Invalid file type. Please select a .csv or .xlsx file.");
      setFieldValue("file", null);
      return;
    }

    setFieldValue("file", selectedFile);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("file", values.file); // 'file' must match the key used in multer

    // Debugging: Log FormData entries
    for (let [key, value] of formData.entries()) {
      console.log(key, value); // Check if file is included
    }

    setLoading(true);
    try {
      const response = await dataPost1("contacts/upload", formData, token); // Changed endpoint for contacts

      if (response.status === 201) {
        onUpload(response.data.data); // Pass response data to parent component
        toast.success("Contacts uploaded successfully!");
      } else {
        toast.error("Contacts upload failed.");
      }
    } catch (error) {
      console.error("Error uploading contacts:", error);
      toast.error("Error uploading contacts: " + (error.response?.data?.message || "Unexpected error"));
    } finally {
      setLoading(false);
      onClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Upload Contacts File</DialogTitle> {/* Changed title */}
      <DialogContent>
        <Formik
          initialValues={{ file: null }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, errors, touched, isSubmitting, values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <input
                    type="file"
                    id="file-input"
                    onChange={(event) => handleFileChange(event, setFieldValue)}
                    accept=".csv,.xlsx"
                    style={{ display: 'none' }}
                    aria-label="File upload"
                  />
                  <TextField
                    fullWidth
                    label="Selected File"
                    value={values.file ? values.file.name : ""}
                    placeholder="Choose a file"
                    onClick={() => document.getElementById('file-input').click()}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <IconButton onClick={() => document.getElementById('file-input').click()}>
                          <AttachFile />
                        </IconButton>
                      ),
                    }}
                    helperText={errors.file && touched.file ? errors.file : "Selected file will be uploaded"}
                    error={Boolean(errors.file && touched.file)}
                  />
                  <Typography variant="body2" color="textSecondary" style={{ marginTop: 8 }}>
                    Accepts .csv and .xlsx formats.
                  </Typography>
                </Grid>
              </Grid>
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting || loading}>
                  {loading ? <CircularProgress size={24} /> : "Upload"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default UploadContactsModal;
