import React from "react";
import { Grid, Typography } from "@mui/material";
import ImageCard from "./Login/ImageCard";

export default function ErrorPage() {
  return (
    <Grid container spacing={2} sx={{ height: "100vh" }}>
      <Grid item xs={12} md={7}>
        <ImageCard
          loginimg={
            "https://sitechecker.pro/wp-content/uploads/2023/06/404-status-code.png"
          }
        />
      </Grid>
      <Grid item xs={12} md={5} spacing={2}>
      < Typography variant="h4" color="primary" fontWeight="bold">
        Error 404
        </Typography>
        <Typography variant="h6" color="primary" fontWeight="bold">
          Page Not Found
        </Typography>

        <Typography variant="body1" color="primary">
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
            </Typography>
      </Grid>
    </Grid>
  );
}
