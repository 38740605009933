import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
 const [emailToken, setEmailToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("user_data"));
    const storedEmailToken = JSON.parse(localStorage.getItem("email_token"));

    if (storedData) {
      const { userToken, user } = storedData;
      setToken(userToken);
      setUserData(user);
      setIsAuthenticated(true);
    }

    if (storedEmailToken) {

      setEmailToken(storedEmailToken);
    }
    // const token = localStorage.getItem("token");
    // const role = localStorage.getItem("role");
    // if (token && role) {
    //   setUserData({ token, role });
    // }
    setLoading(false);
  }, []);

  const login = (newToken, newData) => {
    localStorage.setItem(
      "user_data",
      JSON.stringify({ userToken: newToken, user: newData })
    );
    setToken(newToken);
    setUserData(newData);
    setIsAuthenticated(true);
  };

  

  const adminLogin = (newToken, newData) => {
    localStorage.setItem(
      "user_data",
      JSON.stringify({ userToken: newToken, user: newData })
    );
    setToken(newToken);
    setUserData(newData);
    setIsAuthenticated(true);
  };


  const  signup = (newEmailToken) => {
    localStorage.setItem(
      "email_token",
      JSON.stringify({ emailToken: newEmailToken })
    );
    setEmailToken(newEmailToken);
  };

  const resetPassword = (newEmailToken) => {
    localStorage.setItem(
      "email_token",
      JSON.stringify({ emailToken: newEmailToken })
    );
    setEmailToken(newEmailToken);
  } ;

  const logout = () => {
    localStorage.removeItem("user_data");
    setToken(null);
    setUserData(null);
    setIsAuthenticated(false);
  };

  const adminLogout = () => {
    localStorage.removeItem("user_data");
    setToken(null);
    setUserData(null);
    setIsAuthenticated(false);
  }


  return (
    <AuthContext.Provider
      value={{
        userData,
        token,
        isAuthenticated,
        login,
        logout,
        loading,
        signup,
        adminLogin,
        emailToken,
        resetPassword,
        adminLogout
        
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
