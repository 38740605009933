import React,{useState} from "react";
import{Grid,Tabs,Typography,Button,Box, Tab}from "@mui/material";
import TabPanel from "../../../utils/TabPanel"; 
import UpdateTaskModal from "./EditTasksModal";

import TasksProfile from "./TasksProfile";

function TasksCard({tasks}){
    const[tabValue,setTabValue]=useState(0);
    const[isModalOpen,setModalOpen]=useState(false);// State to manage modal visiblity
    const [tasksData, setTasksData] = useState(tasks); // Local state for tasks data

    const handleTabChange=(event,newValue)=>{
        setTabValue(newValue);
    };

    const handleModalOpen=()=>{
        setModalOpen(true);
    };

    const handleModalClose=()=>{
        setModalOpen(false);
    };

    const handleTasksUpdate = (updatedTasks) => {
      setTasksData(updatedTasks); // Update the tasks data in state
    };

    return(
        <Grid container spacing={2}>
            {/*Header*/}
            <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            >
                <Typography variant="h5" gutterBottom>
                    {tasksData?.title}'s Profile
                </Typography>
                <Button
                variant="contained"
                color="primary"
                sx={{
                    backgroundColor:'blue',
                    color:'white',
                    '&:hover':{
                        backgroundColor:'yellow',
                        color:'black'
                    },
                    borderRadius:'8px',//Example additional style
                    padding:'8px 16 px'//Example additional style
                }}
                onClick={handleModalOpen}
                >
                    Update Tasks
                </Button>
            </Grid>
            {/* Tabs */}
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
         
        >
          <Tab label="Profile" value={0} />
          <Tab label="Employment" value={1} />
        </Tabs>
      </Grid>

      {/* Profile Tab */}
      <TabPanel value={tabValue} index={0}>
        <Box sx={{ p: 2 }}> {/* Example border style */}
          <TasksProfile tasks={tasksData} />
        </Box>
      </TabPanel>

      {/* Employment Tab */}
      <TabPanel value={tabValue} index={1}>
        <Box sx={{ p: 2 }}> {/* Example border style */}
          
        </Box>
      </TabPanel>

      {/* Update task Modal */}
      <UpdateTaskModal
        open={isModalOpen}
        onClose={handleModalClose}
        tasks={tasksData}
        onUpdate={handleTasksUpdate} // Pass the update handler


      />
    </Grid>
  );
}

export default TasksCard;
