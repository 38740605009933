import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";

function MgrCompaniesProfile({ companies }) {
  return (
    <Grid item xs={12} sm={8} md={6}>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "20px",
          padding: "8px",
          maxWidth: "100%",
          margin: "auto",
          mt: "10px",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Name:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{companies?.name}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Owner:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{companies?.owner?.name}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Annual Revenue:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {companies?.annualrevenue}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Industry:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{companies?.industry}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Employee Count:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {companies?.noofemployees}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Email:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{companies?.email}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Phone Number:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{companies?.phonenumber}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                City:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{companies?.city}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                State:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{companies?.state}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default MgrCompaniesProfile;
