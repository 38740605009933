import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  Button,
  TableSortLabel,
  Box,
  useTheme,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { dataFetch, dataDelete } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";
import { tokens } from "../../../utils/theme";
import CustomerCard from "./CustomerCard";
import AddCustomerModal from "./AddCustomerModal";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";
import UploadCustomerModal from "./UploadCustomerModal";

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [customerIdToDelete, setCustomerIdToDelete] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { token } = useAuth();
  const [uploadOpen, setUploadOpen] = useState(false);


  const fetchCustomers = async () => {
    setLoadingData(true);
    try {
      const skip = (page - 1) * rowsPerPage;
      let url = `customer/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setCustomers(response.data.data);
        setTotalCustomers(response.data.total);
      } else {
        console.error(`Error fetching customers: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching customers:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const addCustomer = (newCustomer) => {
    setCustomers((prevCustomers) => [...prevCustomers, newCustomer]);
    setTotalCustomers((prevTotal) => prevTotal + 1);
  };

  const handleCustomerClick = async (customerId) => {
    setLoadingData(true);
    try {
      let url = `customer/${customerId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedCustomer(response.data.data);
      } else {
        console.error(`Error fetching customer details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching customer details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenUploadModal = () => {
    setUploadOpen(true);
  };

  const handleCloseUploadModal = () => {
    setUploadOpen(false);
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  const handleOpenDeleteDialog = (customerId) => {
    setCustomerIdToDelete(customerId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setCustomerIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (customerIdToDelete) {
      setLoadingData(true);
      try {
        const url = `customer/${customerIdToDelete}`;
        const response = await dataDelete(url, token);

        if (response.status === 200) {
          setCustomers(customers.filter((customer) => customer._id !== customerIdToDelete));
          setTotalCustomers((prev) => prev - 1);
          toast.success("Customer deleted successfully");
        } else {
          console.error(`Error deleting customer: ${response.status}`);
          toast.error("Error deleting customer");
        }
      } catch (error) {
        console.error("Error deleting customer:", error);
        toast.error("Error deleting customer");
      } finally {
        setLoadingData(false);
        handleCloseDeleteDialog(); // Close dialog after deletion
      }
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalCustomers / rowsPerPage);

  return (
    <Grid container spacing={2} justifyContent="center">
      {selectedCustomer ? (
        <Grid item xs={12}>
          <CustomerCard customer={selectedCustomer} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h5" gutterBottom>
              Customer Dashboard - Page {page}
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: 'blue',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'yellow',
                    color: 'black'
                  },
                  borderRadius: '8px',
                  padding: '8px 16px'
                }}
                onClick={handleOpenUploadModal}
              >
                Upload File
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "blue",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "yellow",
                    color: "black",
                  },
                  borderRadius: '8px',
                  padding: '8px 16px'
                }}
                onClick={handleOpenModal}
              >
                Add Customer
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {loadingData ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "50vh" }}>
                <CircularProgress />
              </Grid>
            ) : (
              <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {["name", "email", "address", "status", "phone", "delete"].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: "bold",
                            padding: { xs: "8px", md: "16px" },
                            textAlign: "center",
                            backgroundColor: colors.primary[500],
                            color: "white",
                            "&:hover": {
                              backgroundColor: "white",
                              color: "black",
                            },
                          }}
                        >
                          <TableSortLabel
                            active={sortField === header}
                            direction={sortField === header ? sortOrder : "asc"}
                            onClick={() => handleSortRequest(header)}
                            sx={{ textAlign: "center" }}
                          >
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customers.map((customer) => (
                      <TableRow
                        key={customer._id}
                        onClick={() => handleCustomerClick(customer._id)}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: colors.primary[500],
                            color: "white",
                            "& td": {
                              color: "white",
                              backgroundColor: colors.primary[500],
                            },
                          },
                        }}
                      >
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                          {customer?.name.charAt(0).toUpperCase() + customer?.name.slice(1)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                          {customer?.email}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                          {customer?.address.charAt(0).toUpperCase() + customer?.address.slice(1)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                          <b>{customer?.status.toUpperCase()}</b>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                          {customer?.phone}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenDeleteDialog(customer._id);
                            }}
                            sx={{
                              backgroundColor: "black",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "red",
                              },
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{
                  "& .MuiPaginationItem-root": {
                    fontSize: { xs: "0.8rem", md: "1rem" },
                    margin: "0 4px",
                  },
                  "& .MuiPaginationItem-ellipsis": { display: "none" },
                  "& .MuiPaginationItem-page.Mui-selected": {
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                    },
                  },
                }}
              />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
          </Grid>

          {/* Delete Confirmation Dialog */}
          <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            fullWidth
            maxWidth="xs"
            PaperProps={{
              sx: {
                borderRadius: 10,
                padding: 2,
                minWidth: '300px',
                boxShadow: 3,
              },
            }}
          >
            <DialogTitle sx={{ textAlign: 'center', paddingBottom: 2 }}>
              <Typography variant="h6">Delete Confirmation</Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: 2 }}>
              <DialogContentText sx={{ textAlign: 'center', marginBottom: 2 }}>
                Are you sure you want to delete this customer?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
              <Button
                onClick={handleConfirmDelete}
                variant="contained"
                sx={{
                  backgroundColor: 'error.main',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'error.dark',
                  },
                  padding: '8px 16px',
                  marginRight: 1,
                }}
              >
                Yes
              </Button>
              <Button
                onClick={handleCloseDeleteDialog}
                variant="outlined"
                sx={{
                  color: 'blue',
                  borderColor: 'blue',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: 'blue',
                    color: 'white',
                  },
                }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>

        </>
      )}
      <UploadCustomerModal  open={uploadOpen} onClose={handleCloseUploadModal}  onUpload={fetchCustomers}/>
      <AddCustomerModal open={openModal} onClose={handleCloseModal} onAddCustomer={addCustomer} />
    </Grid>
  );
};

export default CustomerList;
