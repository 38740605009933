import React, { useState } from "react";
import { Grid, Tabs, Tab, Typography } from "@mui/material";
import TabPanel from "../../../utils/TabPanel";
import UserTaskProfile from "./UserTasksProfile";

function UserTasksCard({ tasks }) {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <Typography variant="h5" >
          {tasks?.title}'s Profile
        </Typography>
      </Grid>

      {/* Tabs */}
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
        >
          <Tab label="Profile" value={0} />
          <Tab label="Employment" value={1} />
        </Tabs>
      </Grid>

      {/* Profile Tab */}
      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UserTaskProfile tasks={tasks} />
          </Grid>
        </Grid>
      </TabPanel>

      {/* Employment Tab */}
      <TabPanel value={tabValue} index={1}> {/* Changed index from 0 to 1 for Employment Tab */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* Add content for Employment tab here */}
            <Typography variant="h6">Employment Details</Typography>
            {/* Add more content as needed */}
          </Grid>
        </Grid>
      </TabPanel>
    </Grid>
  );
}

export default UserTasksCard;
