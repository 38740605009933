import React from 'react';
import { Grid, Card, CardContent, Typography, Paper, IconButton } from '@mui/material';
import {
  Group,
  ContactPage,
  MonetizationOnOutlined,
  ContactPageOutlined,
  Work,
  MonetizationOn,
  TaskAltOutlined,
  ScheduleOutlined,
  DocumentScannerOutlined,
  GroupAddSharp,
  SwitchAccessShortcutOutlined,
  AccountCircle
} from '@mui/icons-material';

// Dummy data for metrics
const metrics = [
  { label: 'Managed Employees', value: 24 },
  { label: 'Active Leads', value: 92 },
  { label: 'Deals in Progress', value: 32 },
  { label: 'Pending Tasks', value: 14 },
];

// Dummy data for recent activities
const recentActivities = [
  { activity: 'Lead assigned to team', time: '1 hour ago' },
  { activity: 'Task completed by employee', time: 'Today' },
  { activity: 'Meeting scheduled', time: 'Yesterday' },
  { activity: 'Feedback from customer', time: '2 days ago' },
];

// Menu items for manager's dashboard sections
const menuItems = [
  { name: "Employees", icon: <Group /> },
  { name: "Customers", icon: <Group /> },
  { name: "Leads", icon: <ContactPage /> },
  { name: "Deals", icon: <MonetizationOnOutlined /> },
  { name: "Contacts", icon: <ContactPageOutlined /> },
  { name: "Companies", icon: <Work /> },
  { name: "Quotations", icon: <MonetizationOn /> },
  { name: "Tasks", icon: <TaskAltOutlined /> },
  { name: "Meetings", icon: <ScheduleOutlined /> },
  { name: "Reports", icon: <DocumentScannerOutlined /> },
  { name: "Users", icon: <GroupAddSharp /> },
  { name: "Access Control", icon: <SwitchAccessShortcutOutlined /> },
  { name: "Profile", icon: <AccountCircle /> },
];

const ManagerDashboard = () => {
  return (
    <Grid container spacing={3} padding={3}>
      {/* Dashboard Overview */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Manager Dashboard
        </Typography>
      </Grid>

      {/* Metrics Section */}
      {metrics.map((metric, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card>
            <CardContent>
              <Typography variant="h6">{metric.label}</Typography>
              <Typography variant="h4">{metric.value}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}

      {/* Recent Activities Section */}
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            Recent Activities
          </Typography>
          {recentActivities.map((activity, index) => (
            <Typography key={index} variant="body1">
              - {activity.activity} ({activity.time})
            </Typography>
          ))}
        </Paper>
      </Grid>

      {/* Performance Chart Placeholder */}
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            Team Performance (Placeholder)
          </Typography>
          <Typography variant="body2">
            This section could include a chart or data visualization showing the team's performance or task completion metrics.
          </Typography>
        </Paper>
      </Grid>

      {/* Manager Menu Items */}
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Dashboard Sections
        </Typography>
      </Grid>
      {menuItems.map((item, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card>
            <CardContent>
              <IconButton size="large" color="primary">
                {item.icon}
              </IconButton>
              <Typography variant="h6">{item.name}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ManagerDashboard;
