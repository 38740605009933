import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";

function LeadProfile({ lead }) {
  const renderComments = () => {
    const comments = [];

    // Check if both pipeline stage and comment exist
    if (lead?.pipelinestage && lead?.pipelineComment) {
      comments.push(
        <Typography variant="body1" key="pipeline-comment" sx={{ color: 'blue', fontWeight: 'bold' }}>
          {`${lead.pipelinestage} - ${lead.pipelineComment}`}
        </Typography>
      );
    } else if (lead?.pipelineComment) {
      // If only pipeline comment exists
      comments.push(
        <Typography variant="body1" key="pipeline-comment" sx={{ color: 'blue', fontWeight: 'bold' }}>
          {`Pipeline Comment: ${lead.pipelineComment}`}
        </Typography>
      );
    } else if (lead?.additionalComments && lead.additionalComments.length > 0) {
      // If additional comments exist
      comments.push(
        <Typography variant="body1" key="additional-comments">
          {lead.additionalComments.join(', ')}
        </Typography>
      );
    } else {
      comments.push(<Typography variant="body1">No comments available</Typography>);
    }

    return comments;
  };
  const renderAssignedLeads = () => {
    if (!lead?.assignedLeads || lead.assignedLeads.length === 0) {
      return <Typography variant="body1">No assigned leads available</Typography>;
    }
  
    // Extract names and join them with a separator
    const assignedLeadNames = lead.assignedLeads.map((assignedLead) => assignedLead.name).join(" | ");
  
    return (
      <Typography variant="body1">
        {assignedLeadNames}
      </Typography>
    );
  };

  return (
    <Grid item xs={12} sm={8} md={6}>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "20px",
          padding: "8px",
          maxWidth: "100%",
          margin: "auto",
          mt: "10px",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                First Name:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{lead?.firstname}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Full Name:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{lead?.fullname}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Lead Owner:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{lead?.leadowner?.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Date:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{getDateTimeSync(lead?.date)}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Location:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{lead?.location}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Pipeline Stage:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{lead?.pipelinestage}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Company Name:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{lead?.companyname}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Phone Number:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{lead?.phonenumber}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Email:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{lead?.email}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Timezone:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{lead?.timezone}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Comments:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {renderComments()}
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Assigned To:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {renderAssignedLeads()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default LeadProfile;
