import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Stack } from "react-bootstrap";
import { dataPost } from "../../services/ApiEndPoint";
import { toast } from "react-hot-toast";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";

// import { useAuth } from "../../contexts/AuthContext";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
});

const ForgetPwdCard = () => {
  const navigate = useNavigate();

  // Initial form values
  const initialValues = {
    email: "",
  };

  // const { sendOTP } = useAuth();
  // Form submission handler
  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    console.log("Form values:", values);

    const { email } = values;
    try {
      const response = await dataPost("auth/send-otp", { email });
      const data = await response.data;

      if (response.status === 200) {
        resetForm();
        // sendOTP(data.token, data.user);
        toast.success(data.message);
        navigate("/new-pwd");
      } else if (response.status === 401) {
        toast.custom(data.message);
        console.log("user", data.message);
      } else {
        console.log("error", data.message);
        toast.custom(data.message);
      }
    } catch (error) {
      console.log("error", error.response.data.message);
      toast.error( error.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Card
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 3,
      }}
    >
      <CardContent>
        <Typography variant="h4" component="div" gutterBottom>
          Lost Your Password!
        </Typography>
        <Typography variant="h5" component="div" gutterBottom>
          Send OTP to your Email
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Box sx={{ mt: 2 }}>
                <Field
                  as={TextField}
                  name="email"
                  type="email"
                  label="Email"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 2 }}
                  margin="normal"
                  helperText={
                    <>{errors.email && touched.email ? errors.email : ""}</>
                  }
                  error={Boolean(errors.email && touched.email)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting}
                  sx={{
                    mt: "10px",
                    mr: "20px",
                    borderRadius: "10px",
                    color: "#ffffff",
                    minWidth: "170px",
                    minHeight: "50px",
                    backgroundColor: "#000080",
                  }}
                >
                  Send OTP
                </Button>
                <Stack direction="row" spacing={2}>
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{ width: "60%", alignContent: "center", mt: 3 }}
                  >
                    Already Have an Account ?{" "}
                    <span
                      style={{
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Login Here
                    </span>
                  </Typography>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default ForgetPwdCard;
