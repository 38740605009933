import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
} from "@mui/material";
import { useAuth } from "../../../contexts/AuthContext";
import { dataFetch } from "../../../services/ApiEndPoint";
import QuotationsCard from "./QuotationsCard";

function QuotationSearch() {
  const [quotations, setQuotations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [sortBy, setSortBy] = useState("name");
  const [statusFilter, setStatusFilter] = useState("name");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { token } = useAuth();

  const fetchQuotation = async () => {
    setLoadingData(true);
    try {
    
    
      let url = `quotations/query?page=${page}&limit=${rowsPerPage}&sort=${sortBy}&searchTerm=${searchTerm}`;
      let response = await dataFetch(url, token, "GET");
      console.log(response.data.data);
      if (response.status === 200) {
        setQuotations(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching Quotations:", error);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchQuotation();
  }, [searchTerm, sortBy, statusFilter, page, rowsPerPage]);

  const handleQuotationClick = async (quotationId) => {
    setLoadingData(true);
    try {
      let url = `quotations/${quotationId}`;
      let response = await dataFetch(url, token);
      if (response.status === 200) {
        setSelectedQuotation(response.data.data);
      } else {
        console.error(`Error fetching quotation details: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching quotation details:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset page to 1 when search term changes
  };

  return (
    <Grid container spacing={2}>
      {selectedQuotation ? (
        <QuotationsCard quotations={selectedQuotation}/>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Quotations Dashboard
            </Typography>
          </Grid>

          {/* Search Bar, Sort, and Filter Controls */}
          <Grid
            container
            item
            xs={12}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={8} md={8}>
              <TextField
                label="Search Quotations"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
                sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Sort By</InputLabel>
                <Select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  label="Sort By"
                  sx={{ backgroundColor: "#f9f9f9", borderRadius: 1 }}
                >
                  <MenuItem value="quotationsstatus">Status</MenuItem>
                  <MenuItem value="grandtotal">Total</MenuItem>
                  <MenuItem value="associatedcontacts">Name</MenuItem>
                  <MenuItem value="associateddeal">Deal</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Dynamic Search Results */}
          {searchTerm && !loadingData && (
            <Grid item xs={12}>
              <Paper
                elevation={3}
                sx={{
                  maxHeight: 200,
                  overflow: "auto",
                  mt: 2,
                  padding: 2,
                  bgcolor: "#f1f1f1",
                  borderRadius: 2,
                }}
              >
                <List>
  {quotations
    ?.filter((quotation) => 
      typeof quotation?.associateddeal?.name === "string" &&
      quotation?.associateddeal?.name
        ?.toLowerCase()
        ?.startsWith(searchTerm?.toLowerCase())
    )
    .map((quotation) => (
      <ListItem
        key={quotation._id}
        sx={{
          bgcolor: "#f4f4f4",
          mb: 1,
          borderRadius: 2,
          boxShadow: 1,
        }}
        button
        onClick={() => handleQuotationClick(quotation._id)}
      >
        <ListItemText
          primary={quotation?.associateddeal?.name}
          secondary={`${quotation?.grandtotal} - ${quotation?.createdby?.name}`}
          primaryTypographyProps={{ fontWeight: "bold" }}
        />
      </ListItem>
    ))}
</List>
              </Paper>
            </Grid>
          )}

          {loadingData && (
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: "100vh" }}
            >
              <CircularProgress />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default QuotationSearch;
