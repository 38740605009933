import React from 'react';
import { Grid, Card, CardContent, Typography, Paper, IconButton } from '@mui/material';
import {
  GroupAddRounded,
  Group,
  ContactPage,
  MonetizationOnOutlined,
  ContactPageOutlined,
  Work,
  MonetizationOn,
  TaskAltOutlined,
  ScheduleOutlined,
  DocumentScannerOutlined,
  GroupAddSharp,
  SwitchAccessShortcutOutlined,
  AccountCircle
} from '@mui/icons-material';

// Dummy data for metrics
const metrics = [
  { label: 'Total Users', value: 1050 },
  { label: 'Active Leads', value: 245 },
  { label: 'Deals Closed', value: 78 },
  { label: 'Revenue', value: '$124,000' },
];

// Dummy data for recent activities
const recentActivities = [
  { activity: 'New user registration', time: '2 hours ago' },
  { activity: 'Lead updated', time: '5 hours ago' },
  { activity: 'Deal closed', time: 'Yesterday' },
  { activity: 'User feedback received', time: 'Yesterday' },
];

// Menu items for CRM dashboard sections
const menuItems = [
  { name: "Employees", icon: <GroupAddRounded /> },
  { name: "Customers", icon: <Group /> },
  { name: "Leads", icon: <ContactPage /> },
  { name: "Deals", icon: <MonetizationOnOutlined /> },
  { name: "Contacts", icon: <ContactPageOutlined /> },
  { name: "Companies", icon: <Work /> },
  { name: "Quotations", icon: <MonetizationOn /> },
  { name: "Tasks", icon: <TaskAltOutlined /> },
  { name: "Meetings", icon: <ScheduleOutlined /> },
  { name: "Reports", icon: <DocumentScannerOutlined /> },
  { name: "Users", icon: <GroupAddSharp /> },
  { name: "Access Control", icon: <SwitchAccessShortcutOutlined /> },
  { name: "Profile", icon: <AccountCircle /> },
];

const AdminDashboard = () => {
  return (
    <Grid container spacing={3} padding={3}>
      {/* Dashboard Overview */}
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Dashboard Overview
        </Typography>
      </Grid>

      {/* Metrics Section */}
      {metrics.map((metric, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card>
            <CardContent>
              <Typography variant="h6">{metric.label}</Typography>
              <Typography variant="h4">{metric.value}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}

      {/* Recent Activities Section */}
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            Recent Activities
          </Typography>
          {recentActivities.map((activity, index) => (
            <Typography key={index} variant="body1">
              - {activity.activity} ({activity.time})
            </Typography>
          ))}
        </Paper>
      </Grid>

      {/* Sales Chart Placeholder */}
      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            Sales Chart (Example Placeholder)
          </Typography>
          <Typography variant="body2">
            This section could include a chart or visualization of recent sales or performance metrics.
          </Typography>
        </Paper>
      </Grid>

      {/* CRM Menu Items */}
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          CRM Sections
        </Typography>
      </Grid>
      {menuItems.map((item, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card>
            <CardContent>
              <IconButton size="large" color="primary">
                {item.icon}
              </IconButton>
              <Typography variant="h6">{item.name}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default AdminDashboard;
