import React, { useState } from "react";
import { Grid, Tabs, Tab, Typography } from "@mui/material";
import TabPanel from "../../../utils/TabPanel";
import UserCompaniesProfile from "./UserCompaniesProfile.jsx";

function UserCompaniesCard({ companies }) {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <Typography variant="h5" gutterBottom>
          {companies?.name}'s Profile
        </Typography>
      </Grid>

      {/* Tabs */}
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
        >
          <Tab label="Profile" value={0} />
          <Tab label="Employment" value={1} />
        </Tabs>
      </Grid>

      {/* Profile Tab Content */}
      <Grid item xs={12}>
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UserCompaniesProfile companies={companies} />
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>

      {/* Employment Tab Content */}
      <Grid item xs={12}>
        <TabPanel value={tabValue} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* Employment details can be added here */}
              <Typography variant="h6">Employment Information</Typography>
              {/* Add more employment-related content as needed */}
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default UserCompaniesCard;
