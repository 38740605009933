import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  Button,
  TableSortLabel,
  useTheme,
  IconButton,
  Box,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { dataFetch, dataDelete } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";
import { tokens } from "../../../utils/theme";
import DeleteIcon from "@mui/icons-material/Delete";
import ContactsCard from "./ContactsCard";
import AddContactsDrawer from "./AddContactsDrawer";
import toast from "react-hot-toast";
import UploadContactsModal from "./UploadContactsModal";

const ContactsList = () => {
  const [selectedStatus, setSelectedStatus] = useState({});
  const [contacts, setContacts] = useState([]);
  const [totalContacts, setTotalContacts] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedContact, setSelectedContact] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [contactIdToDelete, setContactIdToDelete] = useState(null);
  const { token } = useAuth();
  const [uploadOpen, setUploadOpen] = useState(false);

  
  const fetchContacts = async () => {
    setLoadingData(true);
    try {
      const skip = (page - 1) * rowsPerPage;
      let url = `contacts/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setContacts(response.data.data);
        setTotalContacts(response.data.total);
      } else {
        console.error(`Error fetching contacts: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching contacts:", error);
    } finally {
      setLoadingData(false);
    }
  };
  useEffect(() => {
  

    fetchContacts();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const addContacts = (newContacts) => {
    setContacts((prevContacts) => [...prevContacts, newContacts]);
    setTotalContacts((prevTotal) => prevTotal + 1);
  };

  const handleContactClick = async (contactId) => {
    setLoadingData(true);
    try {
      let url = `contacts/${contactId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedContact(response.data.data);
      } else {
        console.error(`Error fetching contact details: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching contact details:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const handleOpenUploadModal = () => {
    setUploadOpen(true);
  };

  const handleCloseUploadModal = () => {
    setUploadOpen(false);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  const handleDeleteContact = async () => {
    setLoadingData(true);
    try {
      const url = `contacts/${contactIdToDelete}`;
      const response = await dataDelete(url, token);

      if (response.status === 200) {
        setContacts(contacts.filter((contact) => contact._id !== contactIdToDelete));
        setTotalContacts((prev) => prev - 1);
        toast.success("Contact deleted successfully");
      } else {
        console.error(`Error deleting contact: ${response.status}`);
      }
    } catch (error) {
      console.error("Error deleting contact:", error);
    } finally {
      setLoadingData(false);
      handleCloseDeleteDialog();
    }
  };

  const handleOpenDeleteDialog = (contactId) => {
    setContactIdToDelete(contactId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setContactIdToDelete(null);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalContacts / rowsPerPage);

  return (
    <Grid container spacing={2}>
      {selectedContact ? (
        <Grid item xs={12}>
          <ContactsCard contacts={selectedContact} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h5" gutterBottom>
              Contact Dashboard - Page {page}
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <Button variant="contained" color="primary" sx={{ backgroundColor: 'blue', color: 'white', '&:hover': { backgroundColor: 'yellow', color: 'black' }, borderRadius: '8px', padding: '8px 16px' }} onClick={handleOpenUploadModal}>
                Upload File
              </Button>
              <Button variant="contained" color="primary" sx={{ backgroundColor: "blue", color: "white", "&:hover": { backgroundColor: "yellow", color: "black" }, borderRadius: '8px', padding: '8px 16px' }} onClick={handleOpenDrawer}>
                Add contact
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {loadingData ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "50vh" }}>
                <CircularProgress />
              </Grid>
            ) : (
              <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {["name", "email", "phone", "initiate", "end", "status", "delete"].map((header) => (
                        <TableCell key={header} sx={{ fontWeight: "bold", padding: { xs: "8px", md: "16px" }, textAlign: "center", backgroundColor: colors.primary[300], color: "white", "&:hover": { backgroundColor: "white", color: colors.primary[300] } }}>
                          <TableSortLabel active={sortField === header} direction={sortField === header ? sortOrder : "asc"} onClick={() => handleSortRequest(header)} sx={{ textAlign: "center" }}>
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {contacts.map((contact) => (
                      <TableRow key={contact._id} onClick={() => handleContactClick(contact._id)} sx={{ cursor: "pointer", "&:hover": { backgroundColor: colors.primary[300], color: "white", "& td": { color: "white", backgroundColor: colors.primary[300] } } }}>
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>{contact?.firstName}</TableCell>
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>{contact?.email}</TableCell>
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>{contact?.phoneNumber}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <Button variant="contained" color="success" sx={{ margin: 1 }} onClick={(e) => { e.stopPropagation(); /* Initiate Call */ }}>
                            Initiate Call
                          </Button>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <Button variant="contained" color="error" sx={{ margin: 1 }} onClick={(e) => { e.stopPropagation(); /* End Call */ }}>
                            End Call
                          </Button>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                          <Select value={selectedStatus[contact._id] || contact.status || ""} onChange={(event) => { const newStatus = event.target.value; setSelectedStatus((prev) => ({ ...prev, [contact._id]: newStatus })); /* handleUpdateStatus */ }}>
                            {["available", "on_call", "busy", "switched_off", "ringing"].map((statusOption) => (
                              <MenuItem key={statusOption} value={statusOption}>
                                {statusOption.charAt(0).toUpperCase() + statusOption.slice(1)}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <IconButton aria-label="delete" color="error" onClick={(e) => { e.stopPropagation(); handleOpenDeleteDialog(contact._id); }}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" sx={{ '& .MuiPaginationItem-root': { margin: '0 4px' }, '& .MuiPaginationItem-page.Mui-selected': { backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'black' }, }, }} />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
          </Grid>
        </>
      )}
      <AddContactsDrawer open={openDrawer} onClose={handleCloseDrawer} onAddContacts={addContacts} />
      <UploadContactsModal open={uploadOpen} onClose={handleCloseUploadModal} onUpload={fetchContacts} />

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}  fullWidth
            maxWidth="xs"
            PaperProps={{
              sx: {
                borderRadius: 10,
                padding: 2,
                minWidth: '300px',
                boxShadow: 3,
              },
            }}>
        <DialogTitle sx={{ textAlign: "center" }}>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this contact?</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
          <Button onClick={handleDeleteContact} variant="contained" color="error" sx={{ padding: '8px 16px', marginRight: 1 }}>
            Yes
          </Button>
          <Button onClick={handleCloseDeleteDialog} variant="outlined" sx={{ padding: '8px 16px', marginRight: 1 }}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ContactsList;
