import React,{useState} from "react";
import {Grid,Tabs,Tab,Typography,Button} from "@mui/material";
import TabPanel from "../../../utils/TabPanel";
import MeetingsProfile from "../Meetings/MeetingsProfile";
import EditMeetingsModal from "./EditMeetingsModal";
import MeetingsCalendar from "./MeetingsCalendar";


function MeetingsCard({meetings}){
    const[tabValue,setTabValue]=useState(0);
    const[isModalOpen,setModalOpen]=useState(false);
    const [meetingsData, setMeetingsData] = useState(meetings); // Local state for meetings data

    const handleTabChange=(event,newValue)=>{
        setTabValue(newValue);
    };

    const handleModalOpen=()=>{
        setModalOpen(true);
    };

    const handleModalClose=()=>{
        setModalOpen(false);
    };

    const handleMeetingsUpdate = (updatedMeetings) => {
      setMeetingsData(updatedMeetings); // Update the meetings data in state
    };

    return(
        <Grid container spacing={2}>
            {/*Header*/}
            <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
            >
            <Typography variant="h5" gutterBottom>
                {meetingsData?.meetingName}'s Profile
            </Typography>
            <Button
            variant="contained"
            color="primary"
            sx={{
                backgroundColor:'blue',
                color:'white',
                '&:hover':{
                    backgroundColor:'yellow',
                    color:'black'

                },  borderRadius: '8px', // Example additional style
            padding: '8px 16px'
            }}
            onClick={handleModalOpen}
            >
                Update Meetings
            </Button>
            </Grid>
    

          {/* Tabs */}
      <Grid item xs={12}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="secondary"
        textColor="primary"
      >
        <Tab label="Profile" value={0} />
        <Tab label="Calendar" value={1} />
      </Tabs>
    </Grid>

    {/* Profile Tab */}
    <TabPanel value={tabValue} index={0}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MeetingsProfile meetings={meetingsData} /> 
        </Grid>
      </Grid>
    </TabPanel>

    {/* Meetings Name Tab */}
    <TabPanel value={tabValue} index={1}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MeetingsCalendar/>
        </Grid>
      </Grid>
    </TabPanel>

    {/* Update Meetings Modal */}
    <EditMeetingsModal
      open={isModalOpen}
      onClose={handleModalClose}
      meetings={meetingsData}
      onUpdate={handleMeetingsUpdate} // Pass the update handler
    />
  </Grid>
);
}

export default MeetingsCard;

    
