import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { getDateTimeFullWithTimeSync } from "../../../utils/DateTimeHelper";

function ContactProfile({ contacts }) {
  return (
    <Grid item xs={12} sm={8} md={6}>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "20px",
          padding: "8px",
          maxWidth: "100%",
          margin: "auto",
          mt: "10px",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                First Name:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{contacts?.firstName}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Full Name:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{contacts?.fullName}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Role/Designation:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{contacts?.designation}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Company:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{contacts?.company}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Phone:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{contacts?.phoneNumber}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Email:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{contacts?.email}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Address:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {contacts?.address}, {contacts?.city}, {contacts?.state}, {contacts?.country}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Owner:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{contacts?.owner?.name}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Joining Date:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {getDateTimeFullWithTimeSync(contacts?.createdAt)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default ContactProfile;
