import React, { useState } from "react";
import { Grid, Tabs, Tab, Typography } from "@mui/material";
import TabPanel from "../../../utils/TabPanel";
import UserMeetingsProfile from "./UserMeetingsProfile";

function UserMeetingsCard({ meetings }) {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <Typography variant="h5" gutterBottom>
          {meetings?.meetingName}'s Profile
        </Typography>
      </Grid>

      {/* Tabs */}
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
        >
          <Tab label="Profile" value={0} />
          <Tab label="Employment" value={1} />
        </Tabs>
      </Grid>

      {/* Profile Tab */}
      <Grid item xs={12}>
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UserMeetingsProfile meetings={meetings} />
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>

      {/* Employment Tab (Placeholder) */}
      <Grid item xs={12}>
        <TabPanel value={tabValue} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Employment Information</Typography>
              {/* Add employment-related details here */}
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default UserMeetingsCard;
