import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { getDateTimeFullWithTimeSync } from "../../../utils/DateTimeHelper";

function UserQuotationsProfile({ quotations}) {
  console.log(quotations);
  return (
    <Grid item xs={12} sm={8} md={6}>
     <Card
        variant="outlined"
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: "20px",
          padding: "8px",
          maxWidth: "100%",
          margin: "auto",
          mt: "10px",
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Quotation Summary:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {quotations?.quotationssummary}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
               Associated Company:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {quotations?.associatedcompany?.name}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Associated Deal:
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="body1">
                {quotations?.associateddeal?.name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Associated Contacts
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">
                {quotations?.associatedcontacts?.fullName}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="body1" fontWeight="bold">
                Quotations Status:
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography variant="body1">
                {quotations?.quotationsstatus}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography varaint="body1" fontWeight="bold">
                Grand Total
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{quotations?.grandtotal}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography varaint="body1" fontWeight="bold">
                Created By
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{quotations?.createdby?.name}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography varaint="body1" fontWeight="bold">
                Created At
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{getDateTimeFullWithTimeSync(quotations?.createdat)}</Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography varaint="body1" fontWeight="bold">
                Updated At
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{getDateTimeFullWithTimeSync(quotations?.updatedat)}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default UserQuotationsProfile;
