import React from "react";
import { Grid, useTheme } from "@mui/material";
import { tokens } from "../../../../utils/theme";
import SuperAdminSearch from "./SuperAdminSearch";
import SuperAdminList from "./SuperAdminList";


const SuperAdminDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Grid
      container
      spacing={2} // Add space between grid items
      sx={{ padding: 3, backgroundColor: "#ffffff", minHeight: "50vh" }}
    >
      <Grid item xs={12} >
        <SuperAdminSearch />
      </Grid>

      <Grid item xs={12} sx={{marginTop:2}}>
         <SuperAdminList /> 
      </Grid>
    </Grid>
  );
};

export default SuperAdminDashboard;
