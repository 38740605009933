import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar'; // Importing the Calendar component
import { Grid, Typography, Paper, CircularProgress, Box, Collapse, IconButton } from '@mui/material';
import { dataFetch } from '../../../services/ApiEndPoint';
import { useAuth } from '../../../contexts/AuthContext';
import { getDateTimeFullWithTimeSync } from '../../../utils/DateTimeHelper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const MeetingsCalendar = () => {
  const [date, setDate] = useState(new Date());
  const [meetings, setMeetings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noMeetings, setNoMeetings] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const { token } = useAuth();

  const fetchMeetingsForDate = async (selectedDate) => {
    setLoading(true);
    try {
      const formattedDate = selectedDate.toISOString().split('T')[0];
      const url = `meetings/query?meetingdate=${formattedDate}&limit=100`;
      const response = await dataFetch(url, token);

      if (response.status === 200) {
        if (response.data.data.length === 0) {
          setNoMeetings(true);
        } else {
          setMeetings(response.data.data);
          console.log('Meetings:', response.data.data);
          setNoMeetings(false);
        }
      } else {
        console.error(`Error fetching meetings: ${response.status}`);
        setNoMeetings(true);
      }
    } catch (error) {
      console.error('Error fetching meetings:', error);
      setNoMeetings(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMeetingsForDate(date);
  }, [date, token]);

  const handleDateChange = (newDate) => {
    setDate(newDate);
    fetchMeetingsForDate(newDate);
    setShowDetails(true); // Show details on date click
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <Grid container spacing={2} sx={{ padding: 3, backgroundColor: '#f5f5f5' }}>
      <Grid item xs={12} md={6}>
        {/* Calendar component */}
        <Paper sx={{ padding: 2, borderRadius: 2, boxShadow: 3 }}>
          <Calendar
            onChange={handleDateChange}
            value={date}
            minDate={new Date()} // Optional: disable past dates
            sx={{
              '.react-calendar': {
                border: 'none',
                fontSize: '1rem',
                width: '100%',
              },
              '.react-calendar__tile': {
                backgroundColor: '#ffffff',
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
              },
              '.react-calendar__tile--active': {
                backgroundColor: '#1e88e5',
                color: '#ffffff',
              },
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        {loading ? (
          <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
            <CircularProgress />
          </Grid>
        ) : noMeetings ? (
          <Typography variant="h6" component="div" align="center">
            No meetings scheduled for {date.toDateString()}.
          </Typography>
        ) : (
          <Box>
            <IconButton
              onClick={toggleDetails}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                color: 'primary.main',
                mb: 2,
              }}
            >
              {showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              <Typography sx={{ ml: 1 }}>{showDetails ? 'Hide Details' : 'Show Details'}</Typography>
            </IconButton>
            <Collapse in={showDetails}>
              <Paper sx={{ padding: 2, borderRadius: 2, boxShadow: 3 }}>
                {meetings.map((meeting) => (
                  <Box key={meeting._id} sx={{ marginBottom: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {meeting?.meetingname}
                    </Typography>
                    <Typography variant="body1">
                      {getDateTimeFullWithTimeSync(meeting?.meetingdate)}
                    </Typography>
                    <Typography variant="body2">
                      {meeting?.meetingtitle}
                    </Typography>
                    <Typography variant="body2">
                      {meeting?.timezone}
                    </Typography>
                  </Box>
                ))}
              </Paper>
            </Collapse>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default MeetingsCalendar;
