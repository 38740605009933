import React, { useState } from "react";
import { Grid, Tabs, Tab, Typography, Button, Box } from "@mui/material";
import TabPanel from "../../../utils/TabPanel"; // Assuming you have this utility
import OrganizationProfile from "./OrganizationProfile"; // Component to display organization's profile
import OrganizationUser from "./OrganizationUser";


function OrganizationCard({ organization }) {
  const [tabValue, setTabValue] = useState(0);
 
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" gutterBottom>
          {organization.name}'s Details
        </Typography>
       
      </Grid>

      {/* Tabs */}
      <Grid item xs={12}>
      <Box sx={{ width: '100%' }}>
    <Tabs
      value={tabValue}
      onChange={handleTabChange}
      indicatorColor="secondary"
      textColor="primary"

    >
      <Tab label="Profile" value={0} sx={{ padding: '12px 16px', minWidth: '100px' }} />
      <Tab label="Users" value={1} sx={{ padding: '12px 16px', minWidth: '100px' }} />
    </Tabs>
  </Box>
      </Grid>

      {/* Profile Tab */}
      <TabPanel value={tabValue} index={0}>
        <Box sx={{ p: 2, }}>
         <OrganizationProfile organization={organization} /> 
        </Box>
      </TabPanel>

      {/* Users */}
      <TabPanel value={tabValue} index={1} >
        <Box sx={{ p: 2, }}>
        <OrganizationUser organization={organization} />
        </Box>
      </TabPanel>

     
    </Grid>
  );
}

export default OrganizationCard;
