import React, { useState } from "react";
import { Grid, Tabs, Tab, Typography, Button, Box } from "@mui/material";
import TabPanel from "../../../utils/TabPanel.jsx";

import UpdateCompanies from "./MgrEditCompaniesModal.jsx"
import MgrCompaniesProfile from "./MgrCompaniesProfile.jsx";

function MgrCompaniesCard({ companies }) {
  const [tabValue, setTabValue] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false); // State to manage modal visibility
  const [companiesData, setCompaniesData] = useState(companies); // Local state for companies data

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleCompaniesUpdate = (updatedCompanies) => {
    setCompaniesData(updatedCompanies); // Update the companies data in state
  };

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" gutterBottom>
          {companiesData?.name}'s Profile
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            
            backgroundColor: "blue",
            color: "white",
            "&:hover": {
              backgroundColor: "yellow",
              color: "black",
            },
            borderRadius: "8px", // Example additional style
            padding: "8px 16px", // Example additional style
          }}
          onClick={handleModalOpen}
        >
          Update Companies
        </Button>
      </Grid>

      {/* Tabs */}
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
         
        >
          <Tab label="Profile" value={0} />
          <Tab label="Employment" value={1} />
        </Tabs>
      </Grid>

      <TabPanel value={tabValue} index={0}>
        <Box
          sx={{
            p: 2,
         
          }}
        >
          {" "}
          {/* Example border style */}
          <MgrCompaniesProfile companies={companiesData} />
        </Box>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <Box
          sx={{
            p: 2,
           
          }}
        >
          {" "}
          {/* Example border style */}
        </Box>
      </TabPanel>

      {/* Update companies Modal */}
      <UpdateCompanies
        open={isModalOpen}
        onClose={handleModalClose}
        companies={companiesData}
        onUpdate={handleCompaniesUpdate} // Pass the update handler
      />
    </Grid>
  );
}

export default MgrCompaniesCard;
