import React, { useState, useEffect } from 'react';
import {
  Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Pagination, Typography, CircularProgress, IconButton, Box, Select, MenuItem,
  useTheme, TableSortLabel, Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@mui/material';
import { dataFetch, dataDelete, dataUpdate } from '../../../services/ApiEndPoint';
import { useAuth } from '../../../contexts/AuthContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from '../../../utils/theme';
import toast from 'react-hot-toast';

const AccessAdminControlList = () => {
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const { token } = useAuth();
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Fetch users
  useEffect(() => {
    const fetchUsers = async () => {
      setLoadingData(true);
      try {
        const skip = (page - 1) * rowsPerPage;
        let url = `admin/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
        
        let response = await dataFetch(url, token);

        if (response.status === 200) {
          setUsers(response.data.data);
          setTotalUsers(response.data.total);
        } else {
          console.error(`Error fetching Users: ${response.status}`);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized access - possibly due to an invalid or expired token.");
        } else {
          console.error("Error fetching Users:", error);
        }
      } finally {
        setLoadingData(false);
      }
    };
  
    fetchUsers();
  }, [page, sortField, sortOrder, token]);


  

  // Update role
  const handleRoleUpdate = async (userId, newRole) => {
    setLoadingData(true);
    try {
      const url = `admin/${userId}/role`; 
      const response = await dataUpdate(url, { role: newRole }, token); 
      
      if (response.status === 200) {
        setUsers(prevUsers => 
          prevUsers.map(user => 
            user._id === userId ? { ...user, role: newRole } : user
          )
        );
        toast.success('User role updated successfully');
        console.log('User role updated successfully');
      } else {
        console.error(`Error updating user role: ${response.status}`);
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error updating user role:', error);
      toast.error(response.data.message);
    } finally {
      setLoadingData(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortField(field);
  };

  const handleDeleteUser = async () => {
    if (userIdToDelete) {
      setLoadingData(true);
      try {
        const url = `admin/${userIdToDelete}`;
        const response = await dataDelete(url, token);
  
        if (response.status === 200) {
          setUsers(users.filter(user => user._id !== userIdToDelete));
          setTotalUsers(prev => prev - 1);
          toast.success('User deleted successfully');
        } else {
          throw new Error(response.data.message || 'Failed to delete user');
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        toast.error(error.message || 'An error occurred while deleting the user');
      } finally {
        setLoadingData(false);
        setOpenDeleteDialog(false);
      }
    }
  };
  

  const totalPages = Math.ceil(totalUsers / rowsPerPage);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" gutterBottom>
          Access Control - Page {page}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {loadingData ? (
          <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
            <CircularProgress />
          </Grid>
        ) : (
          <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
            <Table>
              <TableHead>
                <TableRow>
                  {['name', 'email', 'role', 'status', 'phone', 'delete'].map(header => (
                    <TableCell
                      key={header}
                      sx={{
                        fontWeight: 'bold',
                        padding: { xs: '8px', md: '16px' },
                        textAlign: 'center',
                        backgroundColor: colors.primary[300],
                        color: 'white',
                        '&:hover': {
                          backgroundColor: 'white',
                          color: colors.primary[300],
                        },
                      }}
                    >
                      <TableSortLabel
                        active={sortField === header}
                        direction={sortField === header ? sortOrder : 'asc'}
                        onClick={() => handleSortRequest(header)}
                        sx={{ textAlign: 'center' }}
                      >
                        {header.charAt(0).toUpperCase() + header.slice(1)}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user._id} sx={{ cursor: 'pointer' }}>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{user?.name}</TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{user?.email}</TableCell>
                    <TableCell>
                      <Select
                        value={user?.role}
                        onChange={(e) => handleRoleUpdate(user._id, e.target.value)}
                        displayEmpty
                        sx={{
                          minWidth: 120,
                          backgroundColor: 'white',
                          '&:hover': {
                            backgroundColor: colors.primary[300],
                            color: 'white',
                          },
                          '& .MuiSelect-select': {
                            padding: '10px',
                          },
                          '& .MuiSelect-icon': {
                            color: colors.primary[300],
                          },
                        }}
                      >
                        <MenuItem value="admin">Admin</MenuItem>
                        <MenuItem value="manager">Manager</MenuItem>
                 
                      </Select>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: user?.status === 'active' ? 'green' : 'red',
                        textTransform: 'uppercase',
                      }}
                    >
                      {user?.status || '-'}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{user?.phone ? user?.phone : "-"}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => { setUserIdToDelete(user._id); setOpenDeleteDialog(true); }}
                        sx={{ 
                          backgroundColor: 'black',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: 'white',
                            color: 'red',
                          }
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            sx={{
              '& .MuiPaginationItem-root': {
                margin: '0 4px',
              },
              '& .MuiPaginationItem-page.Mui-selected': {
                backgroundColor: 'black',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'black',
                },
              },
            }}
          />
          <Typography sx={{ ml: 2 }}>
            <strong>Total Pages: {totalPages}</strong>
          </Typography>
        </Box>
      </Grid>

      {/* Delete Confirmation Dialog */}

<Dialog 
  open={openDeleteDialog} 
  onClose={() => setOpenDeleteDialog(false)} 
  PaperProps={{
    sx: {
      borderRadius: 10,
      padding: 2,
      minWidth: '300px',
      boxShadow: 3,
    },
  }}>
  <DialogTitle sx={{ textAlign: 'center', paddingBottom: 2 }}>
    Confirm Deletion
  </DialogTitle>
  <DialogContent>
    <Typography>Are you sure you want to delete this user?</Typography>
  </DialogContent>
  <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
    <Button
      onClick={handleDeleteUser}
      variant="contained"
      sx={{
        backgroundColor: 'error.main',
        color: 'white',
        '&:hover': {
          backgroundColor: 'error.dark',
        },
        padding: '8px 16px',
        marginRight: 1,
      }}
    >
      Yes
    </Button>
    <Button
      onClick={() => setOpenDeleteDialog(false)}
      variant="outlined"
      sx={{
        color: 'blue',
        borderColor: 'blue',
        padding: '8px 16px',
        '&:hover': {
          backgroundColor: 'blue',
          color: 'white',
        },
      }}
    >
      No
    </Button>
  </DialogActions>
</Dialog>

    </Grid>
  );
};

export default AccessAdminControlList;
