import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";

import { tokens } from "../../../utils/theme";
import LeadSearch from "./LeadSearch";
import LeadList from "./LeadList";
import AssignLeadList from "../../AdminDashboard/Leads/AssignLeadList";

const MgrLeadsDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Grid
      container
      spacing={2} 
      sx={{ padding: 3, backgroundColor: "#ffffff", minHeight: "50vh" }}
    >
      <Grid item xs={12} >
        <LeadSearch />
      </Grid>
      <Grid item xs={12} sx={{marginTop:2}}>
        <AssignLeadList />
      </Grid>
      <Grid item xs={12} sx={{marginTop:2}}>
        <LeadList />
      </Grid>
    </Grid>
  );
};

export default MgrLeadsDashboard;
