import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Stack } from "react-bootstrap";
import { dataPost } from "../../services/ApiEndPoint";
import { toast } from "react-hot-toast";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  AlertTitle,
} from "@mui/material";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
  otp: Yup.string()
    .required("This field is required")
    .min(6, "Must be exactly 6 digits")
    .max(7, "Must be exactly 6 digits"),
  newPassword: Yup.string()
    .required("This field is required")
    .min(8, "Pasword must be 8 or more characters")
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])\w+/,
      "Password ahould contain at least one uppercase and lowercase character"
    )
    .matches(/\d/, "Password should contain at least one number")
    .matches(
      /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
      "Password should contain at least one special character"
    ),
});

const NewPwdCard = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  //Show password
  const EndAdorment = ({ visible, setVisible }) => {
    return (
      <InputAdornment position="end">
        <IconButton
          onClick={() => {
            setVisible(!visible);
          }}
        >
          {visible ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
        </IconButton>
      </InputAdornment>
    );
  };

  // Initial form values
  const initialValues = {
    email: "",
    otp: "",
    newPassword: "",
  };

  // const { submitOTP } = useAuth();
  // Form submission handler
  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    console.log("Form values:", values);

    const { email, otp, newPassword } = values;
    try {
      const response = await dataPost("auth/submit-otp", {
        email,
        otp,
        password: newPassword,
      });
      const data = await response.data;
      if (response.status === 200) {
        resetForm();
        navigate("/");
        toast.success(data.message);
        console.log("New Password Created SuccessFully", response.message);
      } else if (response.status === 400) {
        console.log("error", data.message);
        toast.custom(data.message);
      } else if (response.status === 404) {
        console.log("error", data.message);
        toast.custom(data.message);
      } else {
        console.log("error", data.message);
        toast.custom(data.message);
      }
    } catch (error) {
      console.log("error",  error.response.data.message);
      toast.error(error.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Card
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 3,
      }}
    >
      <CardContent>
        <Typography variant="h4" component="div" gutterBottom>
          Have You Recieved Your OTP !
        </Typography>
        <Typography variant="h5" component="div" gutterBottom>
          Create New Password
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Box sx={{ mt: 2 }}>
                <Field
                  as={TextField}
                  name="email"
                  type="email"
                  label="Email"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 2 }}
                  margin="normal"
                  helperText={
                    <>{errors.email && touched.email ? errors.email : ""}</>
                  }
                  error={Boolean(errors.email && touched.email)}
                />
                <Field
                  as={TextField}
                  name="otp"
                  type="text"
                  label="OTP"
                  fullWidth
                  variant="outlined"
                  sx={{ mt: 2 }}
                  margin="normal"
                  helperText={
                    <>{errors.otp && touched.otp ? errors.otp : ""}</>
                  }
                  error={Boolean(errors.otp && touched.otp)}
                />
                <Field
                  as={TextField}
                  fullWidth
                  name="newPassword"
                  type={visible ? "password" : "text"}
                  label="New Password"
                  variant="outlined"
                  sx={{ mt: 2 }}
                  margin="normal"
                  helperText={
                    <>
                      {errors.newPassword && touched.newPassword
                        ? errors.newPassword
                        : ""}
                    </>
                  }
                  error={Boolean(errors.newPassword && touched.newPassword)}
                  InputProps={{
                    endAdornment: (
                      <EndAdorment visible={visible} setVisible={setVisible} />
                    ),
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting}
                  sx={{
                    mt: "10px",
                    mr: "20px",
                    borderRadius: "10px",
                    color: "#ffffff",
                    minWidth: "170px",
                    minHeight: "50px",
                    backgroundColor: "#000080",
                  }}
                >
                  Create New Password
                </Button>

                <Stack direction="row" spacing={2}>
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{ width: "60%", alignContent: "center", mt: 3 }}
                  >
                    Wanna Go Back ?{" "}
                    <span
                      style={{
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/forget-pwd");
                      }}
                    >
                      Forget Password ?
                    </span>
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{ width: "60%", alignContent: "center", mt: 3 }}
                  >
                    Already Have an Account ? {""}
                    <span
                      style={{
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Login Here
                    </span>
                  </Typography>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default NewPwdCard;
