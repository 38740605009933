import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import React from "react";
import { useAuth } from "./contexts/AuthContext.jsx";
import Login from "./Auth/Login";
import Register from "./Auth/Register.jsx";
import ForgetPwd from "./Auth/ForgetPwd.jsx";
import NewPwd from "./Auth/NewPwd.jsx";
import { Toaster } from "react-hot-toast";
import { redirectByAdminRole, redirectByRole } from "./utils/RedirectByrole.jsx";
import Layout from "./Layout/Layout.jsx";
import ErrorPage from "./Auth/ErrorPage.jsx";
import VerifyEmail from "./Auth/verifyEmail.jsx";
import UserLeadsDashboard from "./Dashboard/UserDashboard/Leads/UserLeadsDashboard.jsx";
import AdminLogin from "./Auth/AdminLogin.jsx";
import AdminLayout from "./Layout/AdminLayout.jsx";
import OrganizationDashboard from "./Dashboard/Admin/Organizations/OrganizationDasboard.jsx";
import AdminDashboard from "./Dashboard/AdminDashboard/AdminDashboard.jsx";
import UserDashboard from "./Dashboard/UserDashboard/Dashboard.jsx";


const App = () => {
  const { isAuthenticated, userData } = useAuth();
  return (

    <Router>
      <Toaster />
      <Routes>
        {/* Public Route */}
        <Route
          path="/"
          element={
            !isAuthenticated ? (
              <Login />
            ) : (
              <Navigate to={redirectByRole(userData?.role)} />
            )
          }
        />
        <Route
          path="/admin/login"
          element={
            !isAuthenticated ? (
              <AdminLogin />
            ) : (
              <Navigate to={redirectByAdminRole(userData?.role)} />
            )
          }
        />

        <Route
          path="/register"
          element={
            !isAuthenticated ? (
              <Register />
            ) : (
              <Navigate to={redirectByRole(userData?.role)} />
            )
          }
        />
        <Route
          path="/forget-pwd"
          element={!isAuthenticated ? <ForgetPwd /> : <Navigate to="/" />}
        />
        \
        <Route
          path="/new-pwd"
          element={!isAuthenticated ? <NewPwd /> : <Navigate to="/" />}
        />
        <Route
          path="/verifyEmail/:token"
          element={!isAuthenticated ? <VerifyEmail /> : <Navigate to="/" />}
        />
        <Route
          path="*"
          element={!isAuthenticated ? <ErrorPage /> : <Navigate to="/" />}
        />

        {/* Private Route */}

        <Route element={isAuthenticated ? <Layout /> : <Navigate to="/" />}>
          <Route
            path="/admin/dashboard"
            element={
              isAuthenticated ? <AdminDashboard /> : <Navigate to="/" />
            }
          />
          <Route
            path="/manager/dashboard"
            element={
              isAuthenticated ? <UserDashboard/> : <Navigate to="/" />
            }
          />
          <Route path="/user/dashboard" element={isAuthenticated ? <UserLeadsDashboard /> : <Navigate to="/" />} />

        </Route>

        <Route element={isAuthenticated ? <AdminLayout /> : <Navigate to="/admin/login" />}>


          <Route path="/admin/organization" element={isAuthenticated ? <OrganizationDashboard /> : <Navigate to="/admin/login" />} />

        </Route>



      </Routes>
    </Router>

  );
};

export default App;



// src/App.jsx


// import React from "react";
// import "./App.css";
// import AppRouter from "./Router";
// import { Toaster } from "react-hot-toast";

// const App = () => {
//   return (
//     <>
//       <Toaster />
//       <AppRouter />
//     </>
//   );
// };

// export default App;
