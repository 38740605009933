import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  Button,
  TableSortLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { dataFetch, dataPost } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../utils/theme";
import toast from "react-hot-toast";
import { getDateTimeSync } from "../../../utils/DateTimeHelper";

const AssignDealsList = () => {
  const [deals, setDeals] = useState([]);
  const [totalDeals, setTotalDeals] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedDeals, setSelectedDeals] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [users, setUsers] = useState([]);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { token } = useAuth();

  // Fetch users for the assignment modal
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token);
        setUsers(Array.isArray(response.data.data) ? response.data.data : []);
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, [token]);

  // Fetch deals with pagination, sorting, etc.
  const fetchDeals = async () => {
    setLoadingData(true);
    try {
      const skip = (page - 1) * rowsPerPage;
      const url = `deals/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
      const response = await dataFetch(url, token);

      if (response.status === 200) {
        setDeals(response.data.data);
        setTotalDeals(response.data.total);
      } else {
        console.error(`Error fetching deals: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching deals:", error);
    } finally {
      setLoadingData(false);
    }
  };

  // Re-fetch deals whenever pagination or sorting changes
  useEffect(() => {
    fetchDeals();
  }, [page, sortField, sortOrder, token]);

  // Handle page change for pagination
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Handle sorting by deal field
  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  // Handle selecting individual deals
  const handleSelectDeal = (dealId) => {
    setSelectedDeals((prevSelected) =>
      prevSelected.includes(dealId)
        ? prevSelected.filter((id) => id !== dealId)
        : [...prevSelected, dealId]
    );
  };

  // Handle selecting all deals
  const handleSelectAllDeals = (event) => {
    setSelectedDeals(event.target.checked ? deals.map((deal) => deal._id) : []);
  };

  // Open the assign modal
  const openAssignModal = () => {
    if (selectedDeals.length === 0) {
      toast.error("Please select at least one deal to assign.");
      return;
    }
    setIsAssignModalOpen(true);
  };

  // Close the assign modal
  const closeAssignModal = () => {
    setIsAssignModalOpen(false);
    setSelectedUsers([]);
  };

  // Handle users selection in the modal
  const handleUserSelect = (event) => {
    const { value } = event.target;
    setSelectedUsers(typeof value === "string" ? value.split(",") : value);
  };

  // Assign selected deals to users
  const handleAssignDeals = async () => {
    if (selectedUsers.length === 0) {
      toast.error("Please select at least one user to assign.");
      return;
    }

    if (selectedDeals.length === 0) {
      toast.error("Please select at least one deal to assign.");
      return;
    }

    const payload = {
      dealsIds: selectedDeals,
      assignedUsers: selectedUsers,
    };

    try {
      const response = await dataPost("/deals/assign-deals", payload);

      if (response.data && response.data.success) {
        toast.success("Deals assigned successfully!");
        setIsAssignModalOpen(false);
        setSelectedDeals([]);
        fetchDeals();
      } else {
        toast.error(response.data.message || "Failed to assign deals.");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error assigning deals");
    }
  };

  // Calculate total number of pages for pagination
  const totalPages = Math.ceil(totalDeals / rowsPerPage);

  // Fetch the theme colors
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h5" gutterBottom>
          Deal Dashboard - Page {page}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={openAssignModal}
          disabled={selectedDeals.length === 0}
          sx={{
            backgroundColor: "blue",
            color: "white",
            "&:hover": { backgroundColor: "yellow", color: "black" },
            borderRadius: "8px",
            padding: "8px 16px",
          }}
        >
          Assign Deal
        </Button>
      </Grid>

      <Grid item xs={12}>
        {loadingData ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox checked={selectedDeals.length === deals.length} onChange={handleSelectAllDeals} />
                  </TableCell>
                  {["dealname", "actualDate", "companyname", "pipelinestage", "productOrService", "estimatedClosureDate"].map((header) => (
                    <TableCell key={header} sx={{ fontWeight: "bold", textAlign: "center", backgroundColor: colors.primary[500], color: "white" }}>
                      <TableSortLabel
                        active={sortField === header}
                        direction={sortField === header ? sortOrder : "asc"}
                        onClick={() => handleSortRequest(header)}
                      >
                        {header.charAt(0).toUpperCase() + header.slice(1)}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {deals.map((deal) => (
                  <TableRow key={deal._id} sx={{ "&:hover": { backgroundColor: "#e3f2fd" } }}>
                    <TableCell padding="checkbox">
                      <Checkbox checked={selectedDeals.includes(deal._id)} onChange={() => handleSelectDeal(deal._id)} />
                    </TableCell>
                    <TableCell>{deal?.name}</TableCell>
                    <TableCell>{getDateTimeSync(deal?.actualdate)}</TableCell>
                    <TableCell>{deal?.company}</TableCell>
                    <TableCell>{deal?.pipelinestage}</TableCell>
                    <TableCell>{deal?.productorservice}</TableCell>
                    <TableCell>{getDateTimeSync(deal?.estimatedclosuredate)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>

      <Dialog open={isAssignModalOpen} onClose={closeAssignModal}>
        <DialogTitle>Assign Deals</DialogTitle>
        <DialogContent>
        <FormControl fullWidth margin="normal">
                <InputLabel>Users</InputLabel>
                <Select
                  multiple
                  value={selectedUsers}
                  onChange={handleUserSelect}
                  renderValue={(selected) => {
                    // Get the names of the selected users
                    const selectedUserNames = users
                      .filter((user) => selected.includes(user._id)) // Find users whose IDs match the selected ones
                      .map((user) => user.name); // Extract the user names
                    return selectedUserNames.join(", "); // Join the names with commas
                  }}
                >
                  {users.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      <Checkbox
                        checked={selectedUsers.indexOf(user._id) > -1}
                      />
                      <ListItemText primary={user.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAssignModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAssignDeals} color="primary">
            Assign
          </Button>
        </DialogActions>
      </Dialog>

      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
        <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
      </Grid>
    </Grid>
  );
};

export default AssignDealsList;
