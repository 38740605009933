import React from "react";
import { Card, CardContent, Typography, Grid, Button } from "@mui/material";
import { getDateTimeSync, isExpired } from "../../../utils/DateTimeHelper";

function MeetingsProfile({ meetings }) {
  if (!meetings) {
    return <Typography variant="body1" color="error">Meeting details are not available.</Typography>;
  }

  const { meetingDate, meetingTime, meetingDuration, meetingName, meetingTitle, country, timezone, agenda, notes, meetingLink } = meetings;
  
  // Log inputs for debugging
  console.log('Meeting Date:', meetingDate);
  console.log('Meeting Time:', meetingTime);
  console.log('Meeting Duration:', meetingDuration);

  const expired = isExpired(meetingDate, meetingTime, meetingDuration);

  return (
    <Grid item xs={12} sm={8} md={6}>
      <Card variant="outlined" sx={{ backgroundColor: "#ffffff", borderRadius: "20px", padding: "8px", maxWidth: "100%", margin: "auto", mt: "10px" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}><Typography variant="body1" fontWeight="bold">Meeting Name:</Typography></Grid>
            <Grid item xs={8}><Typography variant="body1">{meetingName || 'N/A'}</Typography></Grid>
            <Grid item xs={4}><Typography variant="body1" fontWeight="bold">Meeting Duration:</Typography></Grid>
            <Grid item xs={8}><Typography variant="body1">{meetingDuration ? `${meetingDuration} minutes` : 'N/A'}</Typography></Grid>
            <Grid item xs={4}><Typography variant="body1" fontWeight="bold">Meeting Date:</Typography></Grid>
            <Grid item xs={8}><Typography variant="body1">{meetingDate ? getDateTimeSync(meetingDate) : 'N/A'}</Typography></Grid>
            <Grid item xs={4}><Typography variant="body1" fontWeight="bold">Meeting Time:</Typography></Grid>
            <Grid item xs={8}><Typography variant="body1">{meetingTime || 'N/A'}</Typography></Grid>
            <Grid item xs={4}><Typography variant="body1" fontWeight="bold">Meeting Title:</Typography></Grid>
            <Grid item xs={8}><Typography variant="body1">{meetingTitle || 'N/A'}</Typography></Grid>
            <Grid item xs={4}><Typography variant="body1" fontWeight="bold">Country:</Typography></Grid>
            <Grid item xs={8}><Typography variant="body1">{country || 'N/A'}</Typography></Grid>
            <Grid item xs={4}><Typography variant="body1" fontWeight="bold">Timezone:</Typography></Grid>
            <Grid item xs={8}><Typography variant="body1">{timezone || 'N/A'}</Typography></Grid>
            <Grid item xs={4}><Typography variant="body1" fontWeight="bold">Agenda:</Typography></Grid>
            <Grid item xs={8}><Typography variant="body1">{agenda || 'N/A'}</Typography></Grid>
            <Grid item xs={4}><Typography variant="body1" fontWeight="bold">Notes:</Typography></Grid>
            <Grid item xs={8}><Typography variant="body1">{notes || 'N/A'}</Typography></Grid>
            <Grid item xs={4}><Typography variant="body1" fontWeight="bold">Meeting Link:</Typography></Grid>
            <Grid item xs={8}>
              {meetingLink ? (
                expired ? (
                  <Typography variant="body1" color="error">Expired</Typography>
                ) : (
                  <Button variant="contained" color="primary" onClick={() => window.open(meetingLink, "_blank")}>
                    Join Now
                  </Button>
                )
              ) : (
                <Typography variant="body1">No meeting link available</Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default MeetingsProfile;
