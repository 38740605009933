import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { dataFetch, dataPost } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";

const UserAddLeadModal = ({ onClose, open,onAddLead }) => {
  const { token,userData } = useAuth();
  const [users, setUsers] = useState([]); 

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token);
        console.log("Fetched users:", response.data.data); 
        
        setUsers(Array.isArray(response.data.data) ? response.data.data : []); 
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, [token]);

  const validationSchema = Yup.object({
    firstname: Yup.string().required("Please add a first name"),
    fullname: Yup.string().required("Please add a full name"),
    date: Yup.string().required("Please add a date"),
    location: Yup.string().required("Please add a location"),
    leadowner: Yup.string().required("Please add a lead owner"),
    companyname: Yup.string().required("Please add a company name"),
    phonenumber: Yup.string().required("Please add a phone number"),
    email: Yup.string().email("Invalid email").required("Please add an email"),
    timezone: Yup.string().required("Please add a timezone"),


  });

  const initialValues = {
    firstname: "",
    fullname: "",
    date  : "",
    location: "",
    leadowner: "",
    companyname: "",
    phonenumber: "",
    email: "",
    timezone: "",
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const response = await dataPost(`lead/`, values, token);
      if (response.status === 201) {
        toast.success("Lead created successfully");
        onAddLead(response.data.data);
        resetForm();
        onClose(); 
      } else {
        console.error("Failed to create lead:", response.data);
        toast.error("Failed to create lead");
      }
    } catch (error) {
      console.error(
        "Error creating lead:",
        error.response || error.message || error
      );
      toast.error("Error creating lead");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add New Lead</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true} // Reinitialize form if initialValues change
      >
        {({ touched, errors }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="firstname"
                    as={TextField}
                    label="First Name"
                    fullWidth
                    helperText={touched.firstname ? errors.firstname : ""}
                    error={Boolean(errors.firstname && touched.firstname)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="fullname"
                    as={TextField}
                    label="Full Name"
                    fullWidth
                    helperText={touched.fullname ? errors.fullname : ""}
                    error={Boolean(errors.fullname && touched.fullname)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="leadowner"
                    as={TextField}
                    select
                    label="Lead Owner"
                    fullWidth
                    helperText={touched.leadowner ? errors.leadowner : ""}
                    error={Boolean(errors.leadowner && touched.leadowner)}
                  >
                    {users.length > 0 ? (
                      users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          {user.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No users available</MenuItem>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="date"
                    as={TextField}
                    label="Date"
                    type="date"
                    fullWidth
                    helperText={touched.date ? errors.date : ""}
                    error={Boolean(errors.date && touched.date)}
                  />
                </Grid>

              <Grid item xs={12} md={6}>
                  <Field
                    name="location"
                    as={TextField}
                    label="Location"
                    fullWidth
                    helperText={touched.location ? errors.location : ""}
                    error={Boolean(errors.location && touched.location)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="companyname"
                    as={TextField}
                    label="Company Name"
                    fullWidth
                    helperText={touched.companyname ? errors.companyname : ""}
                    error={Boolean(errors.companyname && touched.companyname)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="phonenumber"
                    as={TextField}
                    label="Phone Number"
                    fullWidth
                    helperText={touched.phonenumber ? errors.phonenumber : ""}
                    error={Boolean(errors.phonenumber && touched.phonenumber)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="email"
                    as={TextField}
                    label="Email"
                    fullWidth
                    helperText={touched.email ? errors.email : ""}
                    error={Boolean(errors.email && touched.email)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="timezone"
                    as={TextField}
                    label="Timezone"
                    fullWidth
                    helperText={touched.timezone ? errors.timezone : ""}
                    error={Boolean(errors.timezone && touched.timezone)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Add Lead
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UserAddLeadModal;
