import React from "react";
import { Grid,useTheme } from "@mui/material";
import { tokens } from "../../../utils/theme";
import CustomerSearch from "./CustomerSearch";
import CustomerList from "./CustomerList";

const CustomerDashboard = () => {
  const theme=useTheme();
  const colors= tokens(theme.palette.mode);

  return (
    <Grid
      container
      spacing={2} // Add space between grid items
      sx={{ padding: 3, backgroundColor: "#ffffff", minHeight: "50vh" }}
    >
      <Grid item xs={12} >
       <CustomerSearch/>
      </Grid>

      <Grid item xs={12} sx={{marginTop:2}}>
       <CustomerList/>
      </Grid>
    </Grid>
  );
};

export default CustomerDashboard;
