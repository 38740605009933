import React, { useState } from "react";
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Box,
  useMediaQuery,
  useTheme,
  Tooltip,
  IconButton,
} from "@mui/material";
import Sidebar from "./Sidebar";
import { useAuth } from "../contexts/AuthContext";
import { dataPost } from "../services/ApiEndPoint";
import toast from "react-hot-toast";

import {
  AccountCircle,
  Work,
  SwitchAccessShortcutOutlined,
  GroupAddSharp,
} from "@mui/icons-material";
import OrganizationDashboard from "../Dashboard/Admin/Organizations/OrganizationDasboard";
import AdminTopbar from "./AdminTopbar";
import AdminProfile from "../Dashboard/Admin/User/AdminProfile";
import AccessAdminControlDashboard from "../Dashboard/Admin/Access-Control/AccessAdminControlDashboard";
import SuperAdminDashboard from "../Dashboard/Admin/User/SuperAdminDashboard/SuperAdminDashboard";


function AdminLayout() {
  const [themeMode, setThemeMode] = useState("light");
  const { userData, adminLogout } = useAuth();
  const [selectedSection, setSelectedSection] = useState("Organization");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const drawerWidth = isSmallScreen ? 170 : isMediumScreen ? 200 : 240;

  const handleSectionChange = (section) => {
    setSelectedSection(section);
  };

  const allMenuItems = {
    admin: [
  
      { name: "Organization", icon: <Work />, },
      {name:"Users",icon:<GroupAddSharp/>,},
      {name:"Access Control",icon:<SwitchAccessShortcutOutlined/>},
     { name: "Profile", icon: <AccountCircle /> },
    ],
    manager: [
      { name: "Organization", icon: <Work />, },
      {name:"Users",icon:<GroupAddSharp/>,},
      {name:"Access Control",icon:<SwitchAccessShortcutOutlined/>},
     { name: "Profile", icon: <AccountCircle /> },
    ],
  };

  const renderContent = () => {
    const userRole = userData?.role;

    switch (userRole) {
      case "admin":
        switch (selectedSection) {
          case "Organization":
            return <OrganizationDashboard />;
          case "Access Control":
            return <AccessAdminControlDashboard />;
          case "Users":
            return <SuperAdminDashboard/>;

          case "Profile":
            return <AdminProfile/>;
          
          default:
            return <OrganizationDashboard />;
        }
      case "manager":
        switch (selectedSection) {
          case "Organization":
            return <OrganizationDashboard />;
          case "Access Control":
              return <AccessAdminControlDashboard />;
          case "Users":
            return <SuperAdminDashboard/>;
          case "Profile":
            return <AdminProfile/>;
          default:
            return <OrganizationDashboard />;
        }
      default:
        return <OrganizationDashboard />;

    }
  };

  const lightTheme = createTheme({
    palette: {
      mode: "light",
      background: { default: "#ffffff", paper: "#ffffff" },
      text: { primary: "#000000" },
      primary: { main: "#1976d2" },
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      background: { default: "#121212", paper: "#1d1d1d" },
      text: { primary: "#ffffff" },
      primary: { main: "#bb86fc" },
    },
  });

  const handleThemeChange = () => {
    setThemeMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const handleLogout = async () => {
    try {
      const response = await dataPost("auth/logout");
      if (response.status === 200) {
        localStorage.clear();
        toast.success(response.data.message);
        adminLogout();
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  

  const logo =()=>{
    return <Tooltip title="ATS INNOVATIONS" placement="right">
    <IconButton>
      <img
        src="https://atsinnovations.in/logo.png"
        alt="Company Logo"
        style={{ width: 60, height: 50, borderRadius: "10%" }}
      />
    </IconButton>
  </Tooltip>
  }

  return (
    <ThemeProvider theme={themeMode === "light" ? lightTheme : darkTheme}>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Sidebar
          onSectionChange={handleSectionChange}
          drawerWidth={drawerWidth}
          selectedSection={selectedSection}
          userRole={userData?.role}
          userImage={userData?.img || "https://img.freepik.com/free-photo/portrait-happy-manager-holding-leather-case_1262-5329.jpg"}
          menuItems={allMenuItems[userData?.role]}
          logo={logo()}
        />
        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
          <AdminTopbar
            userName={userData?.name || "User"}
            userEmail={userData?.email || "Email"}
            userRole={userData?.role || "Role"}
            currentTheme={themeMode}
            onThemeChange={handleThemeChange}
            onLogout={handleLogout}
            onSectionChange={handleSectionChange}
            drawerWidth={drawerWidth}
            selectedSection={selectedSection}
            menuItems={allMenuItems[userData?.role]}
            userImage={userData?.img || "https://img.freepik.com/free-photo/portrait-happy-manager-holding-leather-case_1262-5329.jpg"}
             logo={
              logo()
  }
          />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {renderContent()}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default AdminLayout;
