import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, Card, Box } from '@mui/material';
import { dataFetch, dataPost } from '../../../services/ApiEndPoint';
import { useAuth } from '../../../contexts/AuthContext';
import moment from 'moment';

const WorkSession = () => {
  const { token } = useAuth();
  const [sessionData, setSessionData] = useState({
    workTime: 0,
    breakTime: 0,
    breakDetails: { morning: 0, lunch: 0, evening: 0 },
  });
  const [sessionId, setSessionId] = useState(localStorage.getItem('sessionId') || null);
  const [timer, setTimer] = useState(() => parseInt(localStorage.getItem('timer') || '0'));
  const [isSessionActive, setIsSessionActive] = useState(localStorage.getItem('isSessionActive') === 'true');
  const [canEndWork, setCanEndWork] = useState(false);
  const [breaks, setBreaks] = useState({
    morning: { active: false, timer: parseInt(localStorage.getItem('morningBreakTimer') || '0') },
    lunch: { active: false, timer: parseInt(localStorage.getItem('lunchBreakTimer') || '0') },
    evening: { active: false, timer: parseInt(localStorage.getItem('eveningBreakTimer') || '0') },
  });
  const [lastSessionTime, setLastSessionTime] = useState(null);

  useEffect(() => {
    if (!sessionId) {
      setTimer(0);
      setIsSessionActive(false);
      localStorage.setItem('timer', '0');
      localStorage.setItem('isSessionActive', 'false');
    }

    localStorage.setItem('timer', timer);
    localStorage.setItem('isSessionActive', isSessionActive);
    if (sessionId) localStorage.setItem('sessionId', sessionId);
    localStorage.setItem('morningBreakTimer', breaks.morning.timer);
    localStorage.setItem('lunchBreakTimer', breaks.lunch.timer);
    localStorage.setItem('eveningBreakTimer', breaks.evening.timer);
  }, [timer, isSessionActive, sessionId, breaks]);

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const response = await dataFetch(`/worksession/${sessionId}`, token);
        if (response.data && response.data.lastSessionTime) {
          setLastSessionTime(moment(response.data.lastSessionTime));
        }
      } catch (error) {
        console.error('Error fetching last session data:', error);
      }
    };

    if (sessionId) fetchSessionData();
  }, [token, sessionId]);

  const startWork = async () => {
    if (localStorage.getItem('sessionStarted')) {
      alert('You can only start one work session per day.');
      return;
    }

    if (lastSessionTime && moment().diff(lastSessionTime, 'hours') < 24) {
      alert('You can only start one work session per day.');
      return;
    }

    try {
      const response = await dataPost(`/worksession/start/`);
      setSessionId(response.data.session._id);
      setIsSessionActive(true);
      localStorage.setItem('sessionStarted', 'true');
      alert('Work session started');
      setLastSessionTime(moment());
    } catch (error) {
      console.error('Error starting work session:', error);
    }
  };

  const endWork = async () => {
    if (!sessionId) {
      alert('No active session to end');
      return;
    }

    if (timer < 14400) {
      alert('You must work for at least 4 hours to end the session.');
      return;
    }

    try {
      const response = await dataPost(`/worksession/${sessionId}/end`, token);
      alert('Work session ended');
      setSessionData({ ...sessionData, workTime: response.data.session.totalWorkedTime });
      setIsSessionActive(false);
      localStorage.removeItem('sessionStarted');
      localStorage.removeItem('sessionId');
      localStorage.removeItem('timer');
      localStorage.removeItem('isSessionActive');
    } catch (error) {
      console.error('Error ending work session:', error);
    }
  };

  const startBreak = (type, duration) => async () => {
    if (breaks[type].active) {
      alert(`You can only start one ${type} break per day.`);
      return;
    }

    if (!sessionId) {
      alert('Start a work session first');
      return;
    }

    try {
      await dataPost(`/worksession/${sessionId}/break/start`, { type }, token);
      setBreaks((prev) => ({
        ...prev,
        [type]: { active: true, timer: duration * 60 } // Break duration in seconds
      }));
      alert(`${type} break started`);
    } catch (error) {
      console.error('Error starting break:', error);
    }
  };

  const endBreak = async (type) => {
    if (!sessionId) {
      alert('Start a work session first');
      return;
    }

    try {
      const response = await dataPost(`/worksession/${sessionId}/break/end`, { type }, token);
      alert(`${type} break ended`);
      setSessionData(response.data.session);
      setBreaks((prev) => ({
        ...prev,
        [type]: { active: false, timer: 0 }
      }));
    } catch (error) {
      console.error('Error ending break:', error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setBreaks((prev) => {
        const updatedBreaks = { ...prev };
        Object.keys(updatedBreaks).forEach((key) => {
          if (updatedBreaks[key].active && updatedBreaks[key].timer > 0) {
            updatedBreaks[key].timer -= 1;
            if (updatedBreaks[key].timer <= 0) {
              endBreak(key);
            }
          }
        });
        return updatedBreaks;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [breaks]);

  useEffect(() => {
    if (isSessionActive) {
      const interval = setInterval(() => {
        setTimer((prevTime) => prevTime + 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isSessionActive]);

  useEffect(() => {
    if (timer >= 14400) setCanEndWork(true);
  }, [timer]);

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={{ padding: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h6" align="center">Start Work</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
            <Typography variant="body1">Time Elapsed: {Math.floor(timer / 60)}:{String(timer % 60).padStart(2, '0')}</Typography>
          </Box>
          <Button variant="contained" color="primary" fullWidth onClick={startWork} disabled={isSessionActive}>
            Start Work
          </Button>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Card sx={{ padding: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h6" align="center">End Work</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
            <Typography variant="body1">Work Time: {sessionData.workTime} minutes</Typography>
          </Box>
          <Button variant="contained" color="secondary" fullWidth onClick={endWork} disabled={!canEndWork}>
            End Work
          </Button>
        </Card>
      </Grid>

      {['morning', 'lunch', 'evening'].map((breakType) => (
        <Grid item xs={12} sm={6} md={4} key={breakType}>
          <Card sx={{ padding: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="h6" align="center">{breakType.charAt(0).toUpperCase() + breakType.slice(1)} Break</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
              <Typography variant="body1">{breakType.charAt(0).toUpperCase() + breakType.slice(1)} Time: {Math.floor(breaks[breakType].timer / 60)}:{String(breaks[breakType].timer % 60).padStart(2, '0')}</Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={breaks[breakType].active ? () => endBreak(breakType) : startBreak(breakType, breakType === 'morning' || breakType === 'evening' ? 15 : 30)}
            >
              {breaks[breakType].active ? `End ${breakType} Break` : `Start ${breakType} Break`}
            </Button>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default WorkSession;
