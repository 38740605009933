import React, { useState, useEffect } from 'react';
import {
  Grid, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, Typography, CircularProgress
} from '@mui/material';
import { dataFetch } from '../../../../services/ApiEndPoint';
import { useAuth } from '../../../../contexts/AuthContext';

const MgrWorkSession = ({ sessionId }) => {
  const [workSessionData, setWorkSessionData] = useState({
    totalWorkedTime: 0,
    totalBreakTime: 0,
    totalWorkDuration: 0,
    breakDetails: {
      morning: 0,
      lunch: 0,
      evening: 0,
    },
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { token } = useAuth();

  const fetchWorkSessionData = async () => {
    setLoading(true);
    try {
      const response = await dataFetch(`/worksession/${sessionId}/calculate`, token);
      const data = response.data;

      setWorkSessionData({
        totalWorkedTime: data.totalWorkedTime || 0,
        totalBreakTime: data.totalBreakTime || 0,
        totalWorkDuration: data.totalWorkDuration || 0,
        breakDetails: {
          morning: data.breakDetails?.morning || 0,
          lunch: data.breakDetails?.lunch || 0,
          evening: data.breakDetails?.evening || 0,
        },
      });
    } catch (err) {
      setError(err.response ? err.response.data.message : 'Failed to fetch work session data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkSessionData();
  }, [sessionId]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Total Work Calculation</Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Detail</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Total Worked Time</TableCell>
                <TableCell>{workSessionData.totalWorkedTime} minutes</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Work Duration (Net)</TableCell>
                <TableCell>{workSessionData.totalWorkDuration} minutes</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Break Time</TableCell>
                <TableCell>{workSessionData.totalBreakTime} minutes</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Break Details</TableCell>
                <TableCell>
                  Morning: {workSessionData.breakDetails.morning} minutes<br />
                  Lunch: {workSessionData.breakDetails.lunch} minutes<br />
                  Evening: {workSessionData.breakDetails.evening} minutes
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default MgrWorkSession;
