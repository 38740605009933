import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Avatar,
  Divider,
  useTheme,
  useMediaQuery,
  IconButton,
  Box,
  Tooltip,
  ButtonBase,
} from "@mui/material";
import { MenuOutlined as MenuOutlinedIcon } from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";
import { tokens } from "../utils/theme";
import { Card } from "@mui/material"; // Updated import

function Sidebar({ onSectionChange, drawerWidth, selectedSection, menuItems, userImage, userRole, logo }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isCollapsed, setIsCollapsed] = useState(
    JSON.parse(localStorage.getItem("sidebarCollapsed")) || false
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.title = `${selectedSection.charAt(0).toUpperCase() + selectedSection.slice(1)} - Dashboard`;
  }, [selectedSection]);

  useEffect(() => {
    localStorage.setItem("sidebarCollapsed", JSON.stringify(isCollapsed));
  }, [isCollapsed]);

  const { userData } = useAuth();
  const userName = userData?.name || "User";

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isMobileScreen && (
        <IconButton
          onClick={toggleDrawer}
          sx={{
            position: "absolute",
            top: 16,
            left: 16,
            color: colors.grey[100],
            zIndex: 1300,
          }}
        >
          <MenuOutlinedIcon />
        </IconButton>
      )}

      <Drawer
        variant={isMobileScreen ? "temporary" : "permanent"}
        open={isMobileScreen ? isOpen : true}
        onClose={toggleDrawer}
        sx={{
          width: isCollapsed ? "64px" : drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isCollapsed ? "64px" : drawerWidth,
            boxSizing: "border-box",
            backgroundColor: colors.primary[400],
            overflowX: "hidden",
          },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: isCollapsed ? "center" : "space-between", alignItems: "center" }}>
          <Typography
            variant="h6"
            sx={{
              color: colors.grey[400],
              whiteSpace: "nowrap",
              fontWeight: "bold",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "uppercase",
              fontFamily: "'Roboto', sans-serif",
              transition: "font-size 0.3s, color 0.3s",
              "&:hover": {
                fontSize: "1.2rem",
                color: colors.redAccent[500],
              },
            }}
          >
            {isCollapsed ? "" : userRole}
          </Typography>
          {!isMobileScreen && (
            <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
              <MenuOutlinedIcon sx={{ color: colors.grey[100] }} />
            </IconButton>
          )}
        </Toolbar>
        <Divider />

        {/* User Info */}
        <Box sx={{ display: "flex", alignItems: "center", padding: 1 }}>
          <Tooltip
            title={
              isCollapsed ? (
                <Typography variant="h6" sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                  {userName}
                </Typography>
              ) : ""
            }
            placement="right"
          >
            <Avatar alt={userName} src={userImage} />
          </Tooltip>
          {!isCollapsed && (
            <Box sx={{ marginLeft: 1 }}>
              <Typography variant="subtitle1" sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                {userName}
              </Typography>
            </Box>
          )}
        </Box>
        <Divider />

        {/* Menu Items */}
        <List>
          {menuItems?.map((item) => (
            <Tooltip key={item.name} title={isCollapsed ? (
              <Typography variant="h6" sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                {item.name}
              </Typography>
            ) : ""} placement="right">
              <ButtonBase
                onClick={() => onSectionChange(item.name)}
                sx={{
                  width: '100%',
                  textAlign: 'left',
                  display: 'flex',
                  backgroundColor: selectedSection === item.name ? 'black' : 'transparent',
                  color: selectedSection === item.name ? 'white' : colors.grey[100],
                  '&:hover': {
                    backgroundColor: selectedSection === item.name ? 'black' : 'rgba(255, 255, 255, 0.1)',
                  },
                }}
              >
                <ListItem
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingY: 1,
                    paddingLeft: 2,
                    paddingRight: isCollapsed ? 2 : 0,
                    width: '100%',
                  }}
                >
                  <ListItemIcon sx={{ color: selectedSection === item.name ? 'white' : colors.grey[100] }}>
                    {item.icon}
                  </ListItemIcon>
                  {!isCollapsed && (
                    <ListItemText
                      primary={item.name}
                      sx={{ color: selectedSection === item.name ? 'white' : colors.grey[100] }}
                    />
                  )}
                </ListItem>
              </ButtonBase>
            </Tooltip>
          ))}
        </List>

        {/* Logo Card */}
        <Box sx={{ mt: 'auto', p: 2 }} >
  {!isCollapsed && (
    <a
      href="https://atsinnovations.in/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          backgroundColor: "background.paper",
          borderRadius: 2,
          boxShadow: 3,
          "&:hover": {
            boxShadow: 6,
          },
          padding: 2,
          cursor: "pointer",
        }}
      >
        {logo}

        <Typography
          variant="h6"
          sx={{
            color: "text.primary",
            fontWeight: "bold",
            transition: "color 0.3s",
            "&:hover": {
              color: "primary.main",
            },
          }}
        >
          ATS INNOVATIONS
        </Typography>

      </Card>
    </a>
  )}
</Box>


      
      </Drawer>
    </>
  );
}

export default Sidebar;
