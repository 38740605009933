import React from "react";
import { Grid } from "@mui/material";
import ImageCard from "./Login/ImageCard";
import VerifyEmailCard from "./VerifyEmail/verifyEmailCard";


export default function verifyEmail() {
  return (
    <Grid container spacing={2} sx={{ height: "100vh" }}>
      <Grid item xs={12} md={7}>
        <ImageCard
          loginimg={
            "https://t3.ftcdn.net/jpg/05/22/42/06/360_F_522420661_5kiI2AiF3pOCgUwl4hveh88dAZDaD9Ol.jpg"
          }
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <VerifyEmailCard/>
      </Grid>
    </Grid>
  );
}
