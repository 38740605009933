import React from "react";
import { Grid, useTheme } from "@mui/material";

import { tokens } from "../../../utils/theme";
import QuotationSearch from "./QuotationSearch";
import QuotationsList from "./QuotationsList";


const MgrQuotationDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Grid
      container
      spacing={2} // Add space between grid items
      sx={{ padding: 3, backgroundColor: "#ffffff", minHeight: "50vh" }}
    >
      <Grid item xs={12} >
    <QuotationSearch/>
      </Grid>

      <Grid item xs={12} sx={{marginTop:2}}>

      <QuotationsList/>
      </Grid>
    </Grid>
  );
};

export default MgrQuotationDashboard;
