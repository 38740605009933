

import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { dataFetch, dataPost } from "../../../services/ApiEndPoint"; // Use PUT method for updating
import { useAuth } from "../../../contexts/AuthContext";

const AddDealsModal = ({ onClose, open,onAddDeals }) => {
  const { token } = useAuth();
  const [users, setUsers] = useState([]); 

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await dataFetch("/user/", token);
        console.log("Fetched users:", response.data); 
        
        setUsers(Array.isArray(response.data.data) ? response.data.data : []); 
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.error("Failed to fetch users");
      }
    };
    fetchUsers();
  }, [token]);

  const validationSchema = Yup.object({
    actualdate: Yup.date().required("Please add an actual date"),
    name: Yup.string().required("Please add a full name"),
    estimatedvalue: Yup.string().required("Please add an estimated value"),
    productorservice: Yup.string().required("Please add a product or service"),
    company: Yup.string().required("Please add a company"),
    estimatedclosuredate: Yup.date().required(
      "Please add an estimated closure date"
    ),
    owner: Yup.string().required("Please add an owner"),
  });

  const initialValues = {
    actualdate: "",
    name: "",
    estimatedvalue: "",
    productorservice: "",
    company: "",
    estimatedclosuredate: "",
    owner: "",
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const response = await dataPost(`deals/`, values, token);
      if (response.status === 201) {
        toast.success("Deals updated successfully");
       
        onAddDeals(response.data.data);
        resetForm();
      } else {
        console.error("Failed to update Deals:", response.data);
        toast.error("Failed to update Deals");
      }
    } catch (error) {
      console.error(
        "Error updating Deals:",
        error.response || error.message || error
      );
      toast.error("Error updating Deals");
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Add New Deals</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true} // This ensures that form is reinitialized when initialValues change
      >
        {({ touched, errors }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="actualdate"
                    type="date"
                    as={TextField}
                    label="Actual Date"
                    fullWidth
                    helperText={
                      <>
                        {errors.actualdate && touched.actualdate
                          ? errors.actualdate
                          : ""}
                      </>
                    }
                    error={Boolean(errors.actualdate && touched.actualdate)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="name"
                    as={TextField}
                    label="Full Name"
                    fullWidth
                    helperText={
                      <>{errors.name && touched.name ? errors.name : ""}</>
                    }
                    error={Boolean(errors.name && touched.name)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="estimatedvalue"
                    as={TextField}
                    label="Estimated Value"
                    fullWidth
                    helperText={
                      <>
                        {errors.estimatedvalue && touched.estimatedvalue
                          ? errors.estimatedvalue
                          : ""}
                      </>
                    }
                    error={Boolean(
                      errors.estimatedvalue && touched.estimatedvalue
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="productorservice"
                    as={TextField}
                    label="Product or Service"
                    fullWidth
                    helperText={
                      <>
                        {errors.productorservice && touched.productorservice
                          ? errors.productorservice
                          : ""}
                      </>
                    }
                    error={Boolean(
                      errors.productorservice && touched.productorservice
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="company"
                    as={TextField}
                    label="Company"
                    fullWidth
                    helperText={
                      <>
                        {errors.company && touched.company
                          ? errors.company
                          : ""}
                      </>
                    }
                    error={Boolean(errors.company && touched.company)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="estimatedclosuredate"
                    as={TextField}
                    type="date"
                    label="Estimated Closure Date"
                    fullWidth
                    helperText={
                      <>
                        {errors.estimatedclosuredate &&
                        touched.estimatedclosuredate
                          ? errors.estimatedclosuredate
                          : ""}
                      </>
                    }
                    error={Boolean(
                      errors.estimatedclosuredate &&
                        touched.estimatedclosuredate
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                <Field
                    name="owner"
                    as={TextField}
                    select
                    label="Owner"
                    fullWidth
                    helperText={touched.owner ? errors.owner : ""}
                    error={Boolean(errors.owner && touched.owner)}
                  >
                    {users.length > 0 ? (
                      users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          {user.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No users available</MenuItem>
                    )}
                  </Field>
                </Grid>

                {/* Add more fields like Leaves, Attendance, etc. as needed */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Add Deals
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AddDealsModal;
