import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import toast from "react-hot-toast";
import {
  TextField,
  Button,
  Drawer,
  Grid,
  IconButton,
  Typography,
  Divider,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { dataPost } from "../../../../services/ApiEndPoint";
import { useAuth } from "../../../../contexts/AuthContext";

const AddUserDrawer = ({ onClose, open, onAddUser }) => {
  const { token } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required("Please add a name"),
    email: Yup.string().email("Invalid email").required("Please add an email"),
    phone: Yup.string().required("Please add a phone number"),
    role: Yup.string()
      .oneOf(["user", "manager", "admin"], "Invalid role")
      .required("Please select a role"),
    password: Yup.string().required("Please add a password"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], "Passwords must match")
      .required("Please confirm your password"),
    status: Yup.string()
      .oneOf(["active", "inactive"], "Invalid status")
      .required("Please select a status"),
  });

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    role: "",
    password: "",
    confirmPassword: "",
    status: "active",
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const response = await dataPost("user/", values, token);
      if (response.status === 201) {
        toast.success("User created successfully");
        onAddUser(response.data.user);
        resetForm();
      } else {
        toast.error("Failed to create user");
      }
    } catch (error) {
      toast.error("Error creating user",error.response || error.message || error);
      console.error("Error creating user:", error.response || error.message || error);
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: { xs: "100%", sm: "50%", md: "40%" } } }} // Responsive width
    >
      <div style={{ padding: 16 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Create New User</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ touched, errors }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="name"
                    as={TextField}
                    label="Name"
                    fullWidth
                    helperText={touched.name && errors.name}
                    error={Boolean(touched.name && errors.name)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="email"
                    as={TextField}
                    label="Email"
                    fullWidth
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="phone"
                    as={TextField}
                    label="Phone"
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    error={Boolean(touched.phone && errors.phone)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="role"
                    as={TextField}
                    label="Role"
                    select
                    fullWidth
                    helperText={touched.role && errors.role}
                    error={Boolean(touched.role && errors.role)}
                  >
                    <MenuItem value="user">User</MenuItem>
                    <MenuItem value="manager">Manager</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="status"
                    as={TextField}
                    label="Status"
                    select
                    fullWidth
                    helperText={touched.status && errors.status}
                    error={Boolean(touched.status && errors.status)}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="password"
                    as={TextField}
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="confirmPassword"
                    as={TextField}
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    fullWidth
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" style={{ marginTop: 16 }}>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Create User
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Drawer>
  );
};

export default AddUserDrawer;
