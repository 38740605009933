import React from "react";
import { Grid, useTheme } from "@mui/material";

import { tokens } from "../../../utils/theme";
import CompaniesSearch from "./CompaniesSearch";
import CompaniesList from "./CompaniesList";

const CompaniesDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Grid
      container
      spacing={2} // Add space between grid items
      sx={{ padding: 3, backgroundColor: "#ffffff", minHeight: "50vh" }}
    >
      <Grid item xs={12} >
        <CompaniesSearch />
      </Grid>

      <Grid item xs={12} sx={{marginTop:2}}>

        <CompaniesList/>
      </Grid>
    </Grid>
  );
};

export default CompaniesDashboard;
