// src/pages/customerCard.js
import React, { useState } from "react";
import { Grid, Tabs, Tab, Typography, Button } from "@mui/material";
import TabPanel from "../../../utils/TabPanel";


import UpdatecustomerModal from "./EditcustomerModal"; // Import the modal
import CustomerProfile from "./CustomerProfile";

function CustomerCard({ customer }) {
  const [tabValue, setTabValue] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false); // State to manage modal visibility
 const [customerData, setCustomerData] = useState(customer); // Local state for customer data

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
const handleCustomerUpdate = (updatedCustomer) => {
    setCustomerData(updatedCustomer); // Update the customer data in state
  }

  

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" gutterBottom>
          {customerData?.name}'s Profile
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ 
            backgroundColor: 'blue',
            color: 'white',
            '&:hover': {
              backgroundColor: 'yellow',
              color: 'black'
            },  borderRadius: '8px', // Example additional style
            padding: '8px 16px'
          }}
          onClick={handleModalOpen}
        >
          Update customer
        </Button>
      </Grid>

      {/* Tabs */}
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
        >
          <Tab label="Profile" value={0} />
          <Tab label="Employment" value={1} />
        </Tabs>
      </Grid>

      {/* Profile Tab */}
      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
      <CustomerProfile customer={customerData} />
          </Grid>
        </Grid>
      </TabPanel>

      {/* Employment Tab */}
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          
          </Grid>
        </Grid>
      </TabPanel>

 

      {/* Update customer Modal */}
      <UpdatecustomerModal
        open={isModalOpen}
        onClose={handleModalClose}
        customer={customerData}
        onUpdate={handleCustomerUpdate}
      />
    </Grid>
  );
}

export default CustomerCard;
