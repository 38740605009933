import { Timeline } from "@mui/icons-material";
import { Grid } from "@mui/material";
import react from "react";




function UserPipelineStage({ lead }) {
    
    
    return (
      <Grid>
        <Timeline position="alternate">
            

        </Timeline>


      </Grid>
        
    )

}



export default UserPipelineStage;