import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  Button,
  TableSortLabel,
  Box,
} from "@mui/material";
import { dataFetch } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";



import { useTheme } from "@emotion/react";
import { tokens } from "../../../utils/theme";

import { getDateTimeFullWithTimeSync, isExpired } from "../../../utils/DateTimeHelper";
import UserMeetingsCard from "./UserMeetingsCard";

const UserMeetingsList = () => {
  const [meetings, setMeetings] = useState([]);
  const [totalMeetings, setTotalMeetings] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedMeetings, setSelectedMeetings] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { token } = useAuth();

  useEffect(() => {
    const fetchMeetings = async () => {
      setLoadingData(true);
      try {
        const skip = (page - 1) * rowsPerPage; 
        let url = `meetings/mymeetings/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
        let response = await dataFetch(url, token);

        if (response.status === 200) {
          setMeetings(response.data.data);
          setTotalMeetings(response.data.total);
        } else {
          console.error(`Error fetching meetings: ${response.status}`);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized access - possibly due to an invalid or expired token.");
        } else {
          console.error("Error fetching meetings:", error);
        }
      } finally {
        setLoadingData(false);
      }
    };

    fetchMeetings();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };



  const handleMeetingsClick = async (meetingsId) => {
    setLoadingData(true);
    try {
      let url = `meetings/${meetingsId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedMeetings(response.data.data);
      } else {
        console.error(`Error fetching meetings details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching meetings details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };


  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };





  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalMeetings / rowsPerPage);




  return (
    <Grid container spacing={2} >
      {selectedMeetings ? (
        <Grid item xs={12}>
          <UserMeetingsCard meetings={selectedMeetings} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5" gutterBottom>
               Meetings Dashboard - Page {page}
            </Typography>
         
          </Grid>
          <Grid item xs={12}>
            {loadingData ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                <CircularProgress />
              </Grid>
            ) : meetings.length === 0 ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "50vh" }}>
                <Typography variant="h6" color="textSecondary">
                  No Customer found! Please check back later.
                </Typography>
              </Grid>
              ): (
              <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {["meetingname", "meetingduration", "meetingdate", "meetingtitle","MeetingLink"].map(header => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: 'bold',
                            padding: { xs: '8px', md: '16px' },
                            textAlign: 'center',
                            backgroundColor: colors.primary[500],
                            color: 'white',
                            '&:hover': {
                              backgroundColor: 'white',
                              color: 'black',
                            }
                          }}
                        >
                          <TableSortLabel
                            active={sortField === header}
                            direction={sortField === header ? sortOrder : 'asc'}
                            onClick={() => handleSortRequest(header)}
                            sx={{ textAlign: 'center' }}
                          >
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {meetings.map(meeting => (
                      <TableRow
                        key={meeting._id}
                        onClick={() => handleMeetingsClick(meeting._id)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: colors.primary[500],
                            color: 'white',
                            '& td': {
                              color: 'white',
                              backgroundColor: colors.primary[500],
                            }
                          }
                        }}
                      >
                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{meeting?.meetingName}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{meeting?.meetingDuration}</TableCell>
                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}><b>{getDateTimeFullWithTimeSync(meeting?.meetingDate)}</b></TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{meeting?.meetingTitle}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}> {meeting?.meetingLink ? (
  isExpired(meeting?.meetingDate, meeting?.meetingTime, meeting?.meetingDuration) ? (
    <Typography variant="body1" color="error">
      Expired
    </Typography>
  ) : (
    <Button
      variant="contained"
      color="primary"
      onClick={() => window.open(meeting.meetingLink, "_blank")}
    >
      Join Now
    </Button>
  )
) : (
  <Typography variant="body1">No meeting link available</Typography>
)}</TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{ 
                  '& .MuiPaginationItem-root': { 
                    fontSize: { xs: '0.8rem', md: '1rem' },
                    margin: '0 4px'
                  },
                  '& .MuiPaginationItem-ellipsis': { display: 'none' },
                  '& .MuiPaginationItem-page.Mui-selected': {
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    }
                  }
                }}
              />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
          </Grid>

         
     
        </>
      )}
    </Grid>
  );
};

export default UserMeetingsList;
