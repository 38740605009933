import React from "react";
import { Grid, useTheme } from "@mui/material";
import { tokens } from "../../../utils/theme";
import ContactsList from "./ContactsList";
import ContactsSearch from "./ContactsSearch";

const ContactsDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Grid
      container
      spacing={3} // Add space between grid items
      sx={{ padding: 3, backgroundColor: "#ffffff", minHeight: "50vh" }}
    >
      {/* Adjust width for each screen size */}
      <Grid item xs={12}>
        <ContactsSearch />
      </Grid>

      <Grid item xs={12}>
        <ContactsList />
      </Grid>
    </Grid>
  );
};

export default ContactsDashboard;
