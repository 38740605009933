import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  CircularProgress,
  Button,
  TableSortLabel,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { dataDelete, dataFetch } from "../../../services/ApiEndPoint";
import { useAuth } from "../../../contexts/AuthContext";
import MeetingsCard from "./MeetingsCard";

import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from "@emotion/react";
import { tokens } from "../../../utils/theme";
import toast from "react-hot-toast";
import AddMeetingsModal from "./AddMeetingsModal";
import { getDateTimeFullWithTimeSync, getDateTimeSync, isExpired } from "../../../utils/DateTimeHelper";

const MeetingsList = () => {
  const [meetings, setMeetings] = useState([]);
  const [totalMeetings, setTotalMeetings] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedMeetings, setSelectedMeetings] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [meetingsIdToDelete, setMeetingsIdToDelete] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { token } = useAuth();

  useEffect(() => {
    const fetchMeetings = async () => {
      setLoadingData(true);
      try {
        const skip = (page - 1) * rowsPerPage; 
        let url = `meetings/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
        let response = await dataFetch(url, token);

        if (response.status === 200) {
          setMeetings(response.data.data);
          setTotalMeetings(response.data.total);
        } else {
          console.error(`Error fetching meetings: ${response.status}`);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized access - possibly due to an invalid or expired token.");
        } else {
          console.error("Error fetching meetings:", error);
        }
      } finally {
        setLoadingData(false);
      }
    };

    fetchMeetings();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const addMeetings= (newMeetings) => {
    // Add the new meetings to the current list
    setMeetings((prevMeetings) => [...prevMeetings, newMeetings]);
    setTotalMeetings((prevTotal) => prevTotal + 1); // Optionally, update the total count
  };

  const handleMeetingsClick = async (meetingsId) => {
    setLoadingData(true);
    try {
      let url = `meetings/${meetingsId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedMeetings(response.data.data);
      } else {
        console.error(`Error fetching meetings details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching meetings details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortField(field);
  };

  const handleOpenDeleteDialog = (meetingsId) => {
    setMeetingsIdToDelete(meetingsId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setMeetingsIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (meetingsIdToDelete) {
      setLoadingData(true);
      try {
        const url = `meetings/${meetingsIdToDelete}`;
        const response = await dataDelete(url, token);
        
        if (response.status === 200) {
          setMeetings(meetings.filter(meetings => meetings._id !== meetingsIdToDelete));
          setTotalMeetings(prev => prev - 1);
          toast.success('Meetings deleted successfully');
        } else {
          console.error(`Error deleting meetings: ${response.status}`);
          toast.error('Error deleting meetings');
        }
      } catch (error) {
        console.error('Error deleting meetings:', error);
        toast.error('Error deleting meetings');
      } finally {
        setLoadingData(false);
        handleCloseDeleteDialog();
      }
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalMeetings / rowsPerPage);




  return (
    <Grid container spacing={2} >
      {selectedMeetings ? (
        <Grid item xs={12}>
          <MeetingsCard meetings={selectedMeetings} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5" gutterBottom>
               Meetings Dashboard - Page {page}
            </Typography>
            <Box sx={{display:'flex', gap:'8px'}}>
           
            <Button
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: 'blue',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'yellow',
                  color: 'black'
                },
                  borderRadius: '8px', // Example additional style
            padding: '8px 16px'
              }}
              onClick={handleOpenModal}
            >
              Add Meetings
            </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {loadingData ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                <CircularProgress />
              </Grid>
            ) : (
              <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {["meetingname", "meetingduration", "meetingdate", "meetingtitle","MeetingLink", "delete"].map(header => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: 'bold',
                            padding: { xs: '8px', md: '16px' },
                            textAlign: 'center',
                            backgroundColor: colors.primary[500],
                            color: 'white',
                            '&:hover': {
                              backgroundColor: 'white',
                              color: 'black',
                            }
                          }}
                        >
                          <TableSortLabel
                            active={sortField === header}
                            direction={sortField === header ? sortOrder : 'asc'}
                            onClick={() => handleSortRequest(header)}
                            sx={{ textAlign: 'center' }}
                          >
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {meetings.map(meeting => (
                      <TableRow
                        key={meeting._id}
                        onClick={() => handleMeetingsClick(meeting._id)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: colors.primary[500],
                            color: 'white',
                            '& td': {
                              color: 'white',
                              backgroundColor: colors.primary[500],
                            }
                          }
                        }}
                      >
                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{meeting?.meetingName}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{meeting?.meetingDuration}</TableCell>
                        <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}><b>{getDateTimeSync(meeting?.meetingDate)}</b></TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>{meeting?.meetingTitle}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}> {meeting?.meetingLink ? (
  isExpired(meeting?.meetingDate, meeting?.meetingTime, meeting?.meetingDuration) ? (
    <Typography variant="body1" color="error">
      Expired
    </Typography>
  ) : (
    <Button
      variant="contained"
      color="primary"
      onClick={() => window.open(meeting.meetingLink, "_blank")}
    >
      Join Now
    </Button>
  )
) : (
  <Typography variant="body1">No meeting link available</Typography>
)}</TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenDeleteDialog(meeting._id);
                            }}
                            sx={{
                              backgroundColor: 'black',
                              color: 'white',
                              '&:hover': {
                                backgroundColor: 'white',
                                color: 'red',
                              }
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{ 
                  '& .MuiPaginationItem-root': { 
                    fontSize: { xs: '0.8rem', md: '1rem' },
                    margin: '0 4px'
                  },
                  '& .MuiPaginationItem-ellipsis': { display: 'none' },
                  '& .MuiPaginationItem-page.Mui-selected': {
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    }
                  }
                }}
              />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
          </Grid>

          {/* Add Meetings Modal */}
          <AddMeetingsModal open={openModal} onClose={handleCloseModal} onAddMeetings={addMeetings}/>
       {/* Delete Confirmation Dialog */}
       <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} fullWidth maxWidth="xs"
        PaperProps={{
          sx: {
            borderRadius: 10,
            padding: 2,
            minWidth: '300px',
            boxShadow: 3,
          },
        }}>
        <DialogTitle sx={{ textAlign: 'center', paddingBottom: 2 }}>Confirm Deletion</DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <DialogContentText sx={{ textAlign: 'center', marginBottom: 2 }}>
            Are you sure you want to delete this quotation? 
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            sx={{
              backgroundColor: 'error.main',
              color: 'white',
              '&:hover': {
                backgroundColor: 'error.dark',
              },
              padding: '8px 16px',
              marginRight: 1,
            }}
          >
            Yes
          </Button>
          <Button
            onClick={handleCloseDeleteDialog}
            variant="outlined"
            sx={{
              color: 'blue',
              borderColor: 'blue',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: 'blue',
                color: 'white',
              },
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
        </>
      )}
    </Grid>
  );
};

export default MeetingsList;
