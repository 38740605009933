import React, { useState } from "react";
import { Grid, Tabs, Tab, Typography, Button } from "@mui/material";
import TabPanel from "../../../utils/TabPanel";
import UserDealsProfile from "./UserDealsProfile";

function UserDealsCard({ deals }) {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" gutterBottom>
          {deals?.name}'s Profile
        </Typography>
        
      </Grid>

      {/* Tabs */}
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
          
        >
          <Tab label="Profile" value={0} />
          <Tab label="Pipeline Stage" value={1} />
        </Tabs>
      </Grid>

      {/* Profile Tab */}
      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UserDealsProfile deals={deals} />
          </Grid>
        </Grid>
      </TabPanel>

      {/* Pipeline Stage Tab */}
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Pipeline Stage Information</Typography>
            <Typography variant="body1">
              Current Stage: {deals?.pipelinestage || "Not Available"}
            </Typography>
            {/* Add more fields or display a history of pipeline stages here */}
          </Grid>
        </Grid>
      </TabPanel>
    </Grid>
  );
}

export default UserDealsCard;
