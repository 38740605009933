export const redirectByRole = (role) => {
  switch (role) {
    case "admin":
      return "/admin/dashboard";
    case "manager":
      return "/manager/dashboard";
    case "user":
      return "/user/dashboard";
    default:
      return "/login";
  }
};


export const redirectByAdminRole = (role) => {  
  switch (role) {
    case "admin":
      return "/admin/organization";
    case "manager":
        return "/admin/organization";
    default:
      return "/admin/login";
  }
}


export const redirectByRoleName = (role)=>{
  switch (role) {
    case "admin":
      return "Dashboard";
    case "manager":
      return "Dashboard";
    case "user":
      return "Dashboard";
    default:
      return "Dashboard";
  }
}