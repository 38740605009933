import React from "react";
import { Card, CardMedia } from "@mui/material";

const 
ImageCard = ({ loginimg }) => {
  return (
    <Card
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CardMedia
        component="img"
        height="100%"
        image={loginimg}
        alt="Placeholder"
        sx={{ width: "100%", objectFit: "cover" }}
      />
    </Card>
  );
};

export default ImageCard;
