import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { getDateTimeFullWithTimeSync } from "../../../utils/DateTimeHelper";

function UserTaskProfile({ tasks}) {
    return (
        <Grid item xs={12} sm={8} md={6}>
          <Card
            variant="outlined"
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "20px",
              padding: "8px",
              maxWidth: "100%",
              margin: "auto",
              mt: "10px",
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body1" fontWeight="bold">
                    Tasks Title:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {tasks?.title}
                  </Typography>
                </Grid>
    
                <Grid item xs={4}>
                  <Typography variant="body1" fontWeight="bold">
                    Description:
                  </Typography>
                </Grid>
    
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {tasks?.description}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" fontWeight="bold">
                    Status
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {tasks?.status}
                  </Typography>
                </Grid>
    
                <Grid item xs={4}>
                  <Typography variant="body1" fontWeight="bold">
                    dueDate
                  </Typography>
                </Grid>
    
                <Grid item xs={8}>
                  <Typography variant="body1">
                    {getDateTimeFullWithTimeSync(tasks?.dueDate)}
                  </Typography>
                </Grid>
    
                <Grid item xs={4}>
                  <Typography varaint="body1" fontWeight="bold">
                    Assigned To
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">{tasks?.assignedTo?.name}</Typography>
                </Grid>
    
                <Grid item xs={4}>
                  <Typography varaint="body1" fontWeight="bold">
                    Related Customer
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">{tasks?.relatedCustomer?.name}</Typography>
                </Grid>
    
                <Grid item xs={4}>
                  <Typography varaint="body1" fontWeight="bold">
                    Related Lead
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">{tasks?.relatedLead?.fullname}</Typography>
                </Grid>
    
                <Grid item xs={4}>
                  <Typography varaint="body1" fontWeight="bold">
                    Total Number Of Pending Task By User
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">{tasks?.totalnoofpendingtasksbyuser}</Typography>
    
                </Grid>
    
                <Grid item xs={4}>
                  <Typography varaint="body1" fontWeight="bold">
                    Total Number Of Overdue Tasks by User
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1">{tasks?.totalnoofoverduetasksbyuser}</Typography>
                </Grid>
    
    
                
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      );
    }


export default UserTaskProfile;