import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Grid, Box, Typography, Button, CircularProgress, Alert, Container } from "@mui/material";
import { dataFetch } from "../../services/ApiEndPoint";
import { toast } from "react-hot-toast";
import { useAuth } from "../../contexts/AuthContext";

const VerifyEmailCard = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isVerifying, setIsVerifying] = useState(true);
  const [verificationStatus, setVerificationStatus] = useState(null);

  const { emailToken } = useAuth(); // Get email token from AuthContext

  console.log(emailToken); // For debugging

  // Email verification logic
  const verifyEmail = async (token) => {
    try {
      const response = await dataFetch(`auth/verifyEmail/${token}`, "GET");
      if (response.status === 200) {
        setVerificationStatus("success");
        toast.success(response.data.message);
        navigate("/");
      } else {
        setVerificationStatus("error");
        toast.error(response.data.message || "Verification failed");
      }
    } catch (error) {
      setVerificationStatus("error");
      toast.error(error.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    const tokenFromUrl = searchParams.get("token"); // Extract token from URL
    if (tokenFromUrl || emailToken) {
      setIsVerifying(true);
      verifyEmail(tokenFromUrl || emailToken); // Use token from URL or from AuthContext
    } else {
      setIsVerifying(false);
      setVerificationStatus("error"); // Handle the case where no token is present
    }
  }, [searchParams, emailToken]);

  return (
    <Container maxWidth="xs">
      <Box mt={10}>
        <Grid container spacing={2} justifyContent="center">
          {isVerifying ? (
            <Grid item xs={12}>
              <CircularProgress />
              <Typography variant="h4" gutterBottom>
                Verifying Email...
              </Typography>
              <Typography variant="body1">
                Please wait while we verify your email. If this takes too long, try refreshing the page or contact support.
              </Typography>
            </Grid>
          ) : (
            <>
              {verificationStatus === "success" ? (
                <Grid item xs={12}>
                  <Alert severity="success">Your email has been verified successfully!</Alert>
                </Grid>
              ) : verificationStatus === "error" ? (
                <Grid item xs={12}>
                  <Alert severity="error">Email verification failed. Please try again.</Alert>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>
                    Verification Failed
                  </Typography>
                  <Typography variant="body1">
                    No verification token found. Please ensure you have the correct link.
                  </Typography>
                  <Button onClick={() => navigate('/')} variant="contained" color="primary" fullWidth>
                    Back to Login
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default VerifyEmailCard;
