
export const timezones = [
    // { value: 'UTC', label: 'UTC (Coordinated Universal Time)', offset: '+00:00' },
    // { value: 'GMT', label: 'GMT (Greenwich Mean Time)', offset: '+00:00' },
  
    // // United States
    // { value: 'PST', label: 'PST (Pacific Standard Time, USA)', offset: '-08:00' },
    // { value: 'PDT', label: 'PDT (Pacific Daylight Time, USA)', offset: '-07:00' },
    // { value: 'MST', label: 'MST (Mountain Standard Time, USA)', offset: '-07:00' },
    // { value: 'MDT', label: 'MDT (Mountain Daylight Time, USA)', offset: '-06:00' },
    // { value: 'CST', label: 'CST (Central Standard Time, USA)', offset: '-06:00' },
    // { value: 'CDT', label: 'CDT (Central Daylight Time, USA)', offset: '-05:00' },
    // { value: 'EST', label: 'EST (Eastern Standard Time, USA)', offset: '-05:00' },
    // { value: 'EDT', label: 'EDT (Eastern Daylight Time, USA)', offset: '-04:00' },
  
    // // Canada
    // { value: 'AST', label: 'AST (Atlantic Standard Time, Canada)', offset: '-04:00' },
    // { value: 'NST', label: 'NST (Newfoundland Standard Time, Canada)', offset: '-03:30' },
  
    // // United Kingdom
    // { value: 'GMT', label: 'GMT (Greenwich Mean Time, UK)', offset: '+00:00' },
    // { value: 'BST', label: 'BST (British Summer Time, UK)', offset: '+01:00' },
  
    // // Europe
    // { value: 'CET', label: 'CET (Central European Time)', offset: '+01:00' },
    // { value: 'CEST', label: 'CEST (Central European Summer Time)', offset: '+02:00' },
    // { value: 'EET', label: 'EET (Eastern European Time)', offset: '+02:00' },
    // { value: 'EEST', label: 'EEST (Eastern European Summer Time)', offset: '+03:00' },
  
    // // Australia
    // { value: 'AEST', label: 'AEST (Australian Eastern Standard Time)', offset: '+10:00' },
    // { value: 'AEDT', label: 'AEDT (Australian Eastern Daylight Time)', offset: '+11:00' },
    // { value: 'ACST', label: 'ACST (Australian Central Standard Time)', offset: '+09:30' },
    // { value: 'ACDT', label: 'ACDT (Australian Central Daylight Time)', offset: '+10:30' },
    // { value: 'AWST', label: 'AWST (Australian Western Standard Time)', offset: '+08:00' },
  
    // Asia
    { value: 'IST', label: 'IST (Indian Standard Time)', offset: '+05:30' },
    { value: 'CST', label: 'CST (China Standard Time)', offset: '+08:00' },
    { value: 'JST', label: 'JST (Japan Standard Time)', offset: '+09:00' },
    { value: 'KST', label: 'KST (Korea Standard Time)', offset: '+09:00' },
  
    // // Africa
    // { value: 'CAT', label: 'CAT (Central Africa Time)', offset: '+02:00' },
    // { value: 'EAT', label: 'EAT (East Africa Time)', offset: '+03:00' },
    // { value: 'WAT', label: 'WAT (West Africa Time)', offset: '+01:00' },
  
    // // South America
    // { value: 'ART', label: 'ART (Argentina Time)', offset: '-03:00' },
    // { value: 'BRT', label: 'BRT (Brazil Time)', offset: '-03:00' },
  
    // // Middle East
    // { value: 'AST', label: 'AST (Arabian Standard Time)', offset: '+03:00' },
    // { value: 'IRST', label: 'IRST (Iran Standard Time)', offset: '+03:30' },
  
    // // Russia
    // { value: 'MSK', label: 'MSK (Moscow Standard Time)', offset: '+03:00' },
    // { value: 'VLAT', label: 'VLAT (Vladivostok Time)', offset: '+10:00' },
  
    // // Timezones for specific countries
    // { value: 'AFG', label: 'Afghanistan Time', offset: '+04:30' },
    // { value: 'ALB', label: 'Albania Time', offset: '+01:00' },
    // { value: 'DZA', label: 'Algeria Time', offset: '+01:00' },
    // { value: 'ASM', label: 'American Samoa Time', offset: '-11:00' },
    // { value: 'AND', label: 'Andorra Time', offset: '+01:00' },
    // { value: 'AGO', label: 'Angola Time', offset: '+01:00' },
    // { value: 'AIA', label: 'Anguilla Time', offset: '-04:00' },
    // { value: 'ATA', label: 'Antarctica Time', offset: '+00:00' },
    // { value: 'ATG', label: 'Antigua and Barbuda Time', offset: '-04:00' },
    // { value: 'ARG', label: 'Argentina Time', offset: '-03:00' },
    // { value: 'ARM', label: 'Armenia Time', offset: '+04:00' },
    // { value: 'ABW', label: 'Aruba Time', offset: '-04:00' },
    // { value: 'AUS', label: 'Australian Eastern Time', offset: '+10:00' },
    // { value: 'AUT', label: 'Austria Time', offset: '+01:00' },
    // { value: 'AZE', label: 'Azerbaijan Time', offset: '+04:00' },
    // { value: 'BHS', label: 'Bahamas Time', offset: '-05:00' },
    // { value: 'BHR', label: 'Bahrain Time', offset: '+03:00' },
    // { value: 'BGD', label: 'Bangladesh Time', offset: '+06:00' },
    // { value: 'BRB', label: 'Barbados Time', offset: '-04:00' },
    // { value: 'BLR', label: 'Belarus Time', offset: '+03:00' },
    // { value: 'BEL', label: 'Belgium Time', offset: '+01:00' },
    // { value: 'BLZ', label: 'Belize Time', offset: '-06:00' },
    // { value: 'BEN', label: 'Benin Time', offset: '+01:00' },
    // { value: 'BMU', label: 'Bermuda Time', offset: '-04:00' },
    // { value: 'BTN', label: 'Bhutan Time', offset: '+06:00' },
    // { value: 'BOL', label: 'Bolivia Time', offset: '-04:00' },
    // { value: 'BES', label: 'Bonaire Time', offset: '-04:00' },
    // { value: 'BIH', label: 'Bosnia and Herzegovina Time', offset: '+01:00' },
    // { value: 'BWA', label: 'Botswana Time', offset: '+02:00' },
    // { value: 'BVT', label: 'Bouvet Island Time', offset: '+00:00' },
    // { value: 'BRA', label: 'Brazil Time', offset: '-03:00' },
    // { value: 'IOT', label: 'British Indian Ocean Territory Time', offset: '+06:00' },
    // { value: 'BRN', label: 'Brunei Darussalam Time', offset: '+08:00' },
    // { value: 'BGR', label: 'Bulgaria Time', offset: '+02:00' },
    // { value: 'BFA', label: 'Burkina Faso Time', offset: '+00:00' },
    // { value: 'BDI', label: 'Burundi Time', offset: '+02:00' },
    // { value: 'CPV', label: 'Cabo Verde Time', offset: '-01:00' },
    // { value: 'KHM', label: 'Cambodia Time', offset: '+07:00' },
    // { value: 'CMR', label: 'Cameroon Time', offset: '+01:00' },
    // { value: 'CAN', label: 'Canada Time', offset: '-03:00' },
    // { value: 'CYM', label: 'Cayman Islands Time', offset: '-05:00' },
    // { value: 'CAF', label: 'Central African Republic Time', offset: '+01:00' },
    // { value: 'TCD', label: 'Chad Time', offset: '+01:00' },
    // { value: 'CHL', label: 'Chile Time', offset: '-03:00' },
    // { value: 'CHN', label: 'China Time', offset: '+08:00' },
    // { value: 'CXR', label: 'Christmas Island Time', offset: '+07:00' },
    // { value: 'CCK', label: 'Cocos (Keeling) Islands Time', offset: '+06:30' },
    // { value: 'COL', label: 'Colombia Time', offset: '-05:00' },
    // { value: 'COM', label: 'Comoros Time', offset: '+03:00' },
    // { value: 'COG', label: 'Congo Time', offset: '+01:00' },
    // { value: 'COD', label: 'Democratic Republic of the Congo Time', offset: '+01:00' },
    // { value: 'COK', label: 'Cook Islands Time', offset: '-10:00' },
    // { value: 'CRI', label: 'Costa Rica Time', offset: '-06:00' },
    // { value: 'CIV', label: 'Côte d\'Ivoire Time', offset: '+00:00' },
    // { value: 'HRV', label: 'Croatia Time', offset: '+01:00' },
    // { value: 'CUB', label: 'Cuba Time', offset: '-05:00' },
    // { value: 'CUW', label: 'Curaçao Time', offset: '-04:00' },
    // { value: 'CYP', label: 'Cyprus Time', offset: '+02:00' },
    // { value: 'CZE', label: 'Czech Republic Time', offset: '+01:00' },
    // { value: 'DNK', label: 'Denmark Time', offset: '+01:00' },
    // { value: 'DJI', label: 'Djibouti Time', offset: '+03:00' },
    // { value: 'DMA', label: 'Dominica Time', offset: '-04:00' },
    // { value: 'DOM', label: 'Dominican Republic Time', offset: '-04:00' },
    // { value: 'ECU', label: 'Ecuador Time', offset: '-05:00' },
    // { value: 'EGY', label: 'Egypt Time', offset: '+02:00' },
    // { value: 'SLV', label: 'El Salvador Time', offset: '-06:00' },
    // { value: 'GNQ', label: 'Equatorial Guinea Time', offset: '+01:00' },
    // { value: 'ERI', label: 'Eritrea Time', offset: '+03:00' },
    // { value: 'EST', label: 'Estonia Time', offset: '+02:00' },
    // { value: 'SWZ', label: 'Eswatini Time', offset: '+02:00' },
    // { value: 'ETH', label: 'Ethiopia Time', offset: '+03:00' },
    // { value: 'FLK', label: 'Falkland Islands Time', offset: '-03:00' },
    // { value: 'FRO', label: 'Faroe Islands Time', offset: '+00:00' },
    // { value: 'FJI', label: 'Fiji Time', offset: '+12:00' },
    // { value: 'FIN', label: 'Finland Time', offset: '+02:00' },
    // { value: 'FRA', label: 'France Time', offset: '+01:00' },
    // { value: 'GAB', label: 'Gabon Time', offset: '+01:00' },
    // { value: 'GMB', label: 'Gambia Time', offset: '+00:00' },
    // { value: 'GEO', label: 'Georgia Time', offset: '+04:00' },
    // { value: 'DEU', label: 'Germany Time', offset: '+01:00' },
    // { value: 'GHA', label: 'Ghana Time', offset: '+00:00' },
    // { value: 'GIB', label: 'Gibraltar Time', offset: '+02:00' },
    // { value: 'GRC', label: 'Greece Time', offset: '+02:00' },
    // { value: 'GRL', label: 'Greenland Time', offset: '-03:00' },
    // { value: 'GRD', label: 'Grenada Time', offset: '-04:00' },
    // { value: 'GLP', label: 'Guadeloupe Time', offset: '-04:00' },
    // { value: 'GUM', label: 'Guam Time', offset: '+10:00' },
    // { value: 'GTM', label: 'Guatemala Time', offset: '-06:00' },
    // { value: 'GIN', label: 'Guinea Time', offset: '+00:00' },
    // { value: 'GNB', label: 'Guinea-Bissau Time', offset: '+00:00' },
    // { value: 'GUY', label: 'Guyana Time', offset: '-04:00' },
    // { value: 'HTI', label: 'Haiti Time', offset: '-05:00' },
    // { value: 'HMD', label: 'Heard Island and McDonald Islands Time', offset: '+05:00' },
    // { value: 'HND', label: 'Honduras Time', offset: '-06:00' },
    // { value: 'HKG', label: 'Hong Kong Time', offset: '+08:00' },
    // { value: 'HUN', label: 'Hungary Time', offset: '+01:00' },
    // { value: 'ISL', label: 'Iceland Time', offset: '+00:00' },
    // { value: 'IND', label: 'India Time', offset: '+05:30' },
    // { value: 'IDN', label: 'Indonesia Time', offset: '+07:00' },
    // { value: 'IRN', label: 'Iran Time', offset: '+03:30' },
    // { value: 'IRQ', label: 'Iraq Time', offset: '+03:00' },
    // { value: 'IRL', label: 'Ireland Time', offset: '+00:00' },
    // { value: 'ISR', label: 'Israel Time', offset: '+02:00' },
    // { value: 'ITA', label: 'Italy Time', offset: '+01:00' },
    // { value: 'JAM', label: 'Jamaica Time', offset: '-05:00' },
    // { value: 'JPN', label: 'Japan Time', offset: '+09:00' },
    // { value: 'JEY', label: 'Jersey Time', offset: '+00:00' },
    // { value: 'JOR', label: 'Jordan Time', offset: '+02:00' },
    // { value: 'KAZ', label: 'Kazakhstan Time', offset: '+06:00' },
    // { value: 'KEN', label: 'Kenya Time', offset: '+03:00' },
    // { value: 'KIR', label: 'Kiribati Time', offset: '+14:00' },
    // { value: 'KWT', label: 'Kuwait Time', offset: '+03:00' },
    // { value: 'KGZ', label: 'Kyrgyzstan Time', offset: '+06:00' },
    // { value: 'LAO', label: 'Laos Time', offset: '+07:00' },
    // { value: 'LVA', label: 'Latvia Time', offset: '+02:00' },
    // { value: 'LBN', label: 'Lebanon Time', offset: '+02:00' },
    // { value: 'LSO', label: 'Lesotho Time', offset: '+02:00' },
    // { value: 'LBR', label: 'Liberia Time', offset: '+00:00' },
    // { value: 'LBY', label: 'Libya Time', offset: '+02:00' },
    // { value: 'LIE', label: 'Liechtenstein Time', offset: '+01:00' },
    // { value: 'LTU', label: 'Lithuania Time', offset: '+02:00' },
    // { value: 'LUX', label: 'Luxembourg Time', offset: '+01:00' },
    // { value: 'MAC', label: 'Macao Time', offset: '+08:00' },
    // { value: 'MDG', label: 'Madagascar Time', offset: '+03:00' },
    // { value: 'MWI', label: 'Malawi Time', offset: '+02:00' },
    // { value: 'MYS', label: 'Malaysia Time', offset: '+08:00' },
    // { value: 'MDV', label: 'Maldives Time', offset: '+05:00' },
    // { value: 'MLI', label: 'Mali Time', offset: '+00:00' },
    // { value: 'MLT', label: 'Malta Time', offset: '+01:00' },
    // { value: 'MHL', label: 'Marshall Islands Time', offset: '+12:00' },
    // { value: 'MTQ', label: 'Martinique Time', offset: '-04:00' },
    // { value: 'MRT', label: 'Mauritania Time', offset: '+00:00' },
    // { value: 'MUS', label: 'Mauritius Time', offset: '+04:00' },
    // { value: 'MYT', label: 'Malaysia Time', offset: '+08:00' },
    // { value: 'MEX', label: 'Mexico Time', offset: '-06:00' },
    // { value: 'FSM', label: 'Micronesia Time', offset: '+11:00' },
    // { value: 'MDA', label: 'Moldova Time', offset: '+02:00' },
    // { value: 'MCO', label: 'Monaco Time', offset: '+02:00' },
    // { value: 'MNG', label: 'Mongolia Time', offset: '+08:00' },
    // { value: 'MHL', label: 'Marshall Islands Time', offset: '+12:00' },
    // { value: 'MRT', label: 'Mauritania Time', offset: '+00:00' },
    // { value: 'MUS', label: 'Mauritius Time', offset: '+04:00' },
    // { value: 'MYT', label: 'Malaysia Time', offset: '+08:00' },
    // { value: 'MEX', label: 'Mexico Time', offset: '-06:00' },
    // { value: 'FSM', label: 'Micronesia Time', offset: '+11:00' },
    // { value: 'MDA', label: 'Moldova Time', offset: '+02:00' },
    // { value: 'MCO', label: 'Monaco Time', offset: '+02:00' },
    // { value: 'MNG', label: 'Mongolia Time', offset: '+08:00' },
    // { value: 'MYS', label: 'Malaysia Time', offset: '+08:00' },
    // { value: 'MAR', label: 'Morocco Time', offset: '+01:00' },
    // { value: 'MOZ', label: 'Mozambique Time', offset: '+02:00' },
    // { value: 'NAM', label: 'Namibia Time', offset: '+01:00' },
    // { value: 'NRU', label: 'Nauru Time', offset: '+12:00' },
    // { value: 'NPL', label: 'Nepal Time', offset: '+05:45' },
    // { value: 'NGA', label: 'Nigeria Time', offset: '+01:00' },
    // { value: 'NIU', label: 'Niue Time', offset: '-11:00' },
    // { value: 'NFK', label: 'Norfolk Island Time', offset: '+11:00' },
    // { value: 'NGA', label: 'Nigeria Time', offset: '+01:00' },
    // { value: 'PRT', label: 'Portugal Time', offset: '+00:00' },
    // { value: 'PRI', label: 'Puerto Rico Time', offset: '-04:00' },
    // { value: 'QAT', label: 'Qatar Time', offset: '+03:00' },
    // { value: 'REU', label: 'Réunion Time', offset: '+04:00' },
    // { value: 'ROU', label: 'Romania Time', offset: '+02:00' },
    // { value: 'RUS', label: 'Russia Time', offset: '+03:00' },
    // { value: 'RWA', label: 'Rwanda Time', offset: '+02:00' },
    // { value: 'WSM', label: 'Samoa Time', offset: '-11:00' },
    // { value: 'SMR', label: 'San Marino Time', offset: '+01:00' },
    // { value: 'STP', label: 'São Tomé and Príncipe Time', offset: '+00:00' },
    // { value: 'SAU', label: 'Saudi Arabia Time', offset: '+03:00' },
    // { value: 'SEN', label: 'Senegal Time', offset: '+00:00' },
    // { value: 'SRB', label: 'Serbia Time', offset: '+01:00' },
    // { value: 'SYC', label: 'Seychelles Time', offset: '+04:00' },
    // { value: 'SLE', label: 'Sierra Leone Time', offset: '+00:00' },
    // { value: 'SGP', label: 'Singapore Time', offset: '+08:00' },
    // { value: 'SVK', label: 'Slovakia Time', offset: '+01:00' },
    // { value: 'SVN', label: 'Slovenia Time', offset: '+01:00' },
    // { value: 'SLB', label: 'Solomon Islands Time', offset: '+11:00' },
    // { value: 'SOM', label: 'Somalia Time', offset: '+03:00' },
    // { value: 'ZAF', label: 'South Africa Time', offset: '+02:00' },
    // { value: 'KOR', label: 'South Korea Time', offset: '+09:00' },
    // { value: 'ESP', label: 'Spain Time', offset: '+01:00' },
    // { value: 'LKA', label: 'Sri Lanka Time', offset: '+05:30' },
    // { value: 'SDN', label: 'Sudan Time', offset: '+02:00' },
    // { value: 'SUR', label: 'Suriname Time', offset: '-03:00' },
    // { value: 'SWE', label: 'Sweden Time', offset: '+01:00' },
    // { value: 'CHE', label: 'Switzerland Time', offset: '+01:00' },
    // { value: 'SYR', label: 'Syria Time', offset: '+02:00' },
    // { value: 'TWN', label: 'Taiwan Time', offset: '+08:00' },
    // { value: 'TJK', label: 'Tajikistan Time', offset: '+05:00' },
    // { value: 'TZA', label: 'Tanzania Time', offset: '+03:00' },
    // { value: 'THA', label: 'Thailand Time', offset: '+07:00' },
    // { value: 'TLS', label: 'Timor-Leste Time', offset: '+09:00' },
    // { value: 'TGO', label: 'Togo Time', offset: '+00:00' },
    // { value: 'TKL', label: 'Tokelau Time', offset: '+13:00' },
    // { value: 'TON', label: 'Tonga Time', offset: '+13:00' },
    // { value: 'TTO', label: 'Trinidad and Tobago Time', offset: '-04:00' },
    // { value: 'TUN', label: 'Tunisia Time', offset: '+01:00' },
    // { value: 'TUR', label: 'Turkey Time', offset: '+03:00' },
    // { value: 'TKM', label: 'Turkmenistan Time', offset: '+05:00' },
    // { value: 'TUV', label: 'Tuvalu Time', offset: '+12:00' },
    // { value: 'UGA', label: 'Uganda Time', offset: '+03:00' },
    // { value: 'UKR', label: 'Ukraine Time', offset: '+02:00' },
    // { value: 'ARE', label: 'United Arab Emirates Time', offset: '+04:00' },
    // { value: 'GBR', label: 'United Kingdom Time', offset: '+00:00' },
    // { value: 'USA', label: 'United States Time', offset: '-05:00' },
    // { value: 'URY', label: 'Uruguay Time', offset: '-03:00' },
    // { value: 'UZB', label: 'Uzbekistan Time', offset: '+05:00' },
    // { value: 'VUT', label: 'Vanuatu Time', offset: '+11:00' },
    // { value: 'VEN', label: 'Venezuela Time', offset: '-04:00' },
    // { value: 'VNM', label: 'Vietnam Time', offset: '+07:00' },
    // { value: 'WLF', label: 'Wallis and Futuna Time', offset: '+12:00' },
    // { value: 'ESH', label: 'Western Sahara Time', offset: '+01:00' },
    // { value: 'YEM', label: 'Yemen Time', offset: '+03:00' },
    // { value: 'ZMB', label: 'Zambia Time', offset: '+02:00' },
    // { value: 'ZWE', label: 'Zimbabwe Time', offset: '+02:00' },
  ];
  

  export const countries = [
    // { code: 'AFG', name: 'Afghanistan' },
    // { code: 'ALB', name: 'Albania' },
    // { code: 'DZA', name: 'Algeria' },
    // { code: 'ASM', name: 'American Samoa' },
    // { code: 'AND', name: 'Andorra' },
    // { code: 'AGO', name: 'Angola' },
    // { code: 'AIA', name: 'Anguilla' },
    // { code: 'ATA', name: 'Antarctica' },
    // { code: 'ATG', name: 'Antigua and Barbuda' },
    // { code: 'ARG', name: 'Argentina' },
    // { code: 'ARM', name: 'Armenia' },
    // { code: 'ABW', name: 'Aruba' },
    // { code: 'AUS', name: 'Australia' },
    // { code: 'AUT', name: 'Austria' },
    // { code: 'AZE', name: 'Azerbaijan' },
    // { code: 'BHS', name: 'Bahamas' },
    // { code: 'BHR', name: 'Bahrain' },
    // { code: 'BGD', name: 'Bangladesh' },
    // { code: 'BRB', name: 'Barbados' },
    // { code: 'BLR', name: 'Belarus' },
    // { code: 'BEL', name: 'Belgium' },
    // { code: 'BLZ', name: 'Belize' },
    // { code: 'BEN', name: 'Benin' },
    // { code: 'BMU', name: 'Bermuda' },
    // { code: 'BTN', name: 'Bhutan' },
    // { code: 'BOL', name: 'Bolivia' },
    // { code: 'BES', name: 'Bonaire, Sint Eustatius and Saba' },
    // { code: 'BIH', name: 'Bosnia and Herzegovina' },
    // { code: 'BWA', name: 'Botswana' },
    // { code: 'BVT', name: 'Bouvet Island' },
    // { code: 'BRA', name: 'Brazil' },
    // { code: 'IOT', name: 'British Indian Ocean Territory' },
    // { code: 'BRN', name: 'Brunei Darussalam' },
    // { code: 'BGR', name: 'Bulgaria' },
    // { code: 'BFA', name: 'Burkina Faso' },
    // { code: 'BDI', name: 'Burundi' },
    // { code: 'CPV', name: 'Cabo Verde' },
    // { code: 'KHM', name: 'Cambodia' },
    // { code: 'CMR', name: 'Cameroon' },
    // { code: 'CAN', name: 'Canada' },
    // { code: 'CYM', name: 'Cayman Islands' },
    // { code: 'CAF', name: 'Central African Republic' },
    // { code: 'TCD', name: 'Chad' },
    // { code: 'CHL', name: 'Chile' },
    // { code: 'CHN', name: 'China' },
    // { code: 'CXR', name: 'Christmas Island' },
    // { code: 'CCK', name: 'Cocos (Keeling) Islands' },
    // { code: 'COL', name: 'Colombia' },
    // { code: 'COM', name: 'Comoros' },
    // { code: 'COD', name: 'Congo, Democratic Republic of the' },
    // { code: 'COG', name: 'Congo, Republic of the' },
    // { code: 'COK', name: 'Cook Islands' },
    // { code: 'CRI', name: 'Costa Rica' },
    // { code: 'HRV', name: 'Croatia' },
    // { code: 'CUB', name: 'Cuba' },
    // { code: 'CUW', name: 'Curaçao' },
    // { code: 'CYP', name: 'Cyprus' },
    // { code: 'CZE', name: 'Czech Republic' },
    // { code: 'DNK', name: 'Denmark' },
    // { code: 'DJI', name: 'Djibouti' },
    // { code: 'DMA', name: 'Dominica' },
    // { code: 'DOM', name: 'Dominican Republic' },
    // { code: 'ECU', name: 'Ecuador' },
    // { code: 'EGY', name: 'Egypt' },
    // { code: 'SLV', name: 'El Salvador' },
    // { code: 'GNQ', name: 'Equatorial Guinea' },
    // { code: 'ERI', name: 'Eritrea' },
    // { code: 'EST', name: 'Estonia' },
    // { code: 'SWZ', name: 'Eswatini' },
    // { code: 'ETH', name: 'Ethiopia' },
    // { code: 'FLK', name: 'Falkland Islands' },
    // { code: 'FRO', name: 'Faroe Islands' },
    // { code: 'FJI', name: 'Fiji' },
    // { code: 'FIN', name: 'Finland' },
    // { code: 'FRA', name: 'France' },
    // { code: 'GUF', name: 'French Guiana' },
    // { code: 'PYF', name: 'French Polynesia' },
    // { code: 'ATF', name: 'French Southern Territories' },
    // { code: 'GAB', name: 'Gabon' },
    // { code: 'GMB', name: 'Gambia' },
    // { code: 'GEO', name: 'Georgia' },
    // { code: 'DEU', name: 'Germany' },
    // { code: 'GHA', name: 'Ghana' },
    // { code: 'GIB', name: 'Gibraltar' },
    // { code: 'GRC', name: 'Greece' },
    // { code: 'GRL', name: 'Greenland' },
    // { code: 'GRD', name: 'Grenada' },
    // { code: 'GLP', name: 'Guadeloupe' },
    // { code: 'GUM', name: 'Guam' },
    // { code: 'GTM', name: 'Guatemala' },
    // { code: 'GGY', name: 'Guernsey' },
    // { code: 'GIN', name: 'Guinea' },
    // { code: 'GNB', name: 'Guinea-Bissau' },
    // { code: 'GUY', name: 'Guyana' },
    // { code: 'HTI', name: 'Haiti' },
    // { code: 'HMD', name: 'Heard Island and McDonald Islands' },
    // { code: 'VAT', name: 'Holy See' },
    // { code: 'HND', name: 'Honduras' },
    // { code: 'HKG', name: 'Hong Kong' },
    // { code: 'HUN', name: 'Hungary' },
    // { code: 'ISL', name: 'Iceland' },
    // { code: 'IND', name: 'India' },
    // { code: 'IDN', name: 'Indonesia' },
    // { code: 'IRN', name: 'Iran' },
    // { code: 'IRQ', name: 'Iraq' },
    // { code: 'IRL', name: 'Ireland' },
    // { code: 'IMN', name: 'Isle of Man' },
    // { code: 'ISR', name: 'Israel' },
    // { code: 'ITA', name: 'Italy' },
    // { code: 'JAM', name: 'Jamaica' },
    // { code: 'JPN', name: 'Japan' },
    // { code: 'JEY', name: 'Jersey' },
    // { code: 'JOR', name: 'Jordan' },
    // { code: 'KAZ', name: 'Kazakhstan' },
    // { code: 'KEN', name: 'Kenya' },
    // { code: 'KIR', name: 'Kiribati' },
    // { code: 'PRK', name: 'Korea, Democratic People\'s Republic of' },
    // { code: 'KOR', name: 'Korea, Republic of' },
    // { code: 'KWT', name: 'Kuwait' },
    // { code: 'KGZ', name: 'Kyrgyzstan' },
    // { code: 'LAO', name: 'Lao People\'s Democratic Republic' },
    // { code: 'LVA', name: 'Latvia' },
    // { code: 'LBN', name: 'Lebanon' },
    // { code: 'LSO', name: 'Lesotho' },
    // { code: 'LBR', name: 'Liberia' },
    // { code: 'LBY', name: 'Libya' },
    // { code: 'LIE', name: 'Liechtenstein' },
    // { code: 'LTU', name: 'Lithuania' },
    // { code: 'LUX', name: 'Luxembourg' },
    // { code: 'MAC', name: 'Macao' },
    // { code: 'MDG', name: 'Madagascar' },
    // { code: 'MWI', name: 'Malawi' },
    // { code: 'MYS', name: 'Malaysia' },
    // { code: 'MDV', name: 'Maldives' },
    // { code: 'MLI', name: 'Mali' },
    // { code: 'MLT', name: 'Malta' },
    // { code: 'MHL', name: 'Marshall Islands' },
    // { code: 'MTQ', name: 'Martinique' },
    // { code: 'MRT', name: 'Mauritania' },
    // { code: 'MUS', name: 'Mauritius' },
    // { code: 'MYT', name: 'Mayotte' },
    // { code: 'MEX', name: 'Mexico' },
    // { code: 'FSM', name: 'Micronesia' },
    // { code: 'MDA', name: 'Moldova' },
    // { code: 'MCO', name: 'Monaco' },
    // { code: 'MNG', name: 'Mongolia' },
    // { code: 'MNE', name: 'Montenegro' },
    // { code: 'MSR', name: 'Montserrat' },
    // { code: 'MAR', name: 'Morocco' },
    // { code: 'MOZ', name: 'Mozambique' },
    // { code: 'MMR', name: 'Myanmar' },
    // { code: 'NAM', name: 'Namibia' },
    // { code: 'NRU', name: 'Nauru' },
    // { code: 'NPL', name: 'Nepal' },
    // { code: 'NLD', name: 'Netherlands' },
    // { code: 'NCL', name: 'New Caledonia' },
    // { code: 'NZL', name: 'New Zealand' },
    // { code: 'NIC', name: 'Nicaragua' },
    // { code: 'NER', name: 'Niger' },
    // { code: 'NGA', name: 'Nigeria' },
    // { code: 'NIU', name: 'Niue' },
    // { code: 'NFK', name: 'Norfolk Island' },
    // { code: 'MNP', name: 'Northern Mariana Islands' },
    // { code: 'NOR', name: 'Norway' },
    // { code: 'OMN', name: 'Oman' },
    // { code: 'PAK', name: 'Pakistan' },
    // { code: 'PLW', name: 'Palau' },
    // { code: 'PSE', name: 'Palestine, State of' },
    // { code: 'PAN', name: 'Panama' },
    // { code: 'PNG', name: 'Papua New Guinea' },
    // { code: 'PRY', name: 'Paraguay' },
    // { code: 'PER', name: 'Peru' },
    // { code: 'PHL', name: 'Philippines' },
    // { code: 'PCN', name: 'Pitcairn' },
    // { code: 'POL', name: 'Poland' },
    // { code: 'PRT', name: 'Portugal' },
    // { code: 'PRI', name: 'Puerto Rico' },
    // { code: 'QAT', name: 'Qatar' },
    // { code: 'REU', name: 'Réunion' },
    // { code: 'ROU', name: 'Romania' },
    // { code: 'RUS', name: 'Russia' },
    // { code: 'RWA', name: 'Rwanda' },
    // { code: 'BLM', name: 'Saint Barthélemy' },
    // { code: 'KNA', name: 'Saint Kitts and Nevis' },
    // { code: 'LCA', name: 'Saint Lucia' },
    // { code: 'MAF', name: 'Saint Martin (French part)' },
    // { code: 'SXM', name: 'Saint Martin (Dutch part)' },
    // { code: 'SPM', name: 'Saint Pierre and Miquelon' },
    // { code: 'VCT', name: 'Saint Vincent and the Grenadines' },
    // { code: 'WSM', name: 'Samoa' },
    // { code: 'SMR', name: 'San Marino' },
    // { code: 'STP', name: 'Sao Tome and Principe' },
    // { code: 'SAU', name: 'Saudi Arabia' },
    // { code: 'SEN', name: 'Senegal' },
    // { code: 'SRB', name: 'Serbia' },
    // { code: 'SYC', name: 'Seychelles' },
    // { code: 'SLE', name: 'Sierra Leone' },
    // { code: 'SGP', name: 'Singapore' },
    // { code: 'SXM', name: 'Sint Maarten (Dutch part)' },
    // { code: 'SVK', name: 'Slovakia' },
    // { code: 'SVN', name: 'Slovenia' },
    // { code: 'SLB', name: 'Solomon Islands' },
    // { code: 'SOM', name: 'Somalia' },
    // { code: 'ZAF', name: 'South Africa' },
    // { code: 'SGS', name: 'South Georgia and the South Sandwich Islands' },
    // { code: 'SSD', name: 'South Sudan' },
    // { code: 'ESP', name: 'Spain' },
    // { code: 'LKA', name: 'Sri Lanka' },
    // { code: 'SDN', name: 'Sudan' },
    // { code: 'SUR', name: 'Suriname' },
    // { code: 'SJM', name: 'Svalbard and Jan Mayen' },
    // { code: 'SWE', name: 'Sweden' },
    // { code: 'CHE', name: 'Switzerland' },
    // { code: 'SYR', name: 'Syrian Arab Republic' },
    // { code: 'TWN', name: 'Taiwan' },
    // { code: 'TJK', name: 'Tajikistan' },
    // { code: 'TZA', name: 'Tanzania, United Republic of' },
    // { code: 'THA', name: 'Thailand' },
    // { code: 'TLS', name: 'Timor-Leste' },
    // { code: 'TGO', name: 'Togo' },
    // { code: 'TKL', name: 'Tokelau' },
    // { code: 'TON', name: 'Tonga' },
    // { code: 'TTO', name: 'Trinidad and Tobago' },
    // { code: 'TUN', name: 'Tunisia' },
    // { code: 'TUR', name: 'Turkey' },
    // { code: 'TKM', name: 'Turkmenistan' },
    // { code: 'TCA', name: 'Turks and Caicos Islands' },
    // { code: 'TUV', name: 'Tuvalu' },
    // { code: 'UGA', name: 'Uganda' },
    // { code: 'UKR', name: 'Ukraine' },
    // { code: 'ARE', name: 'United Arab Emirates' },
    // { code: 'GBR', name: 'United Kingdom' },
    // { code: 'USA', name: 'United States' },
    // { code: 'URY', name: 'Uruguay' },
    // { code: 'UZB', name: 'Uzbekistan' },
    // { code: 'VUT', name: 'Vanuatu' },
    // { code: 'VEN', name: 'Venezuela' },
    // { code: 'VNM', name: 'Viet Nam' },
    // { code: 'WLF', name: 'Wallis and Futuna' },
    // { code: 'ESH', name: 'Western Sahara' },
    // { code: 'YEM', name: 'Yemen' },
    // { code: 'ZMB', name: 'Zambia' },
    // { code: 'ZWE', name: 'Zimbabwe' },
    {code:'IND',name:'India'},
];


  