import React from "react";
import {Grid, useTheme}from "@mui/material";
import{tokens} from "../../../utils/theme";
import UserMeetingsList from "./UserMeetingsList";
import UserMeetingsSearch from "./UserMeetingsSearch";

 const UserMeetingsDashboard=()=>{
    const theme=useTheme();
    const colors=tokens(theme.palette.mode);

    return(
        <Grid
        container
        spacing={2}
        sx={{padding:3,backgroundColor:"#ffffff",minHeight:"50vh"}}
        >

            <Grid item xs={12}>

            <UserMeetingsSearch/>
            </Grid>

            <Grid sx={{marginTop:2}}>
                <UserMeetingsList/>
            </Grid>
        </Grid>
    );
 };

 export default UserMeetingsDashboard;