import React, { useState } from "react";
import { Grid, Tabs, Tab, Typography, Button } from "@mui/material";
import TabPanel from "../../../utils/TabPanel";
import DealsProfile from "./DealsProfile"; 
import EditdealsModal from "./EditDealsModal";

function DealsCard({ deals }) {
  const [tabValue, setTabValue] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false); 
  const [dealsData, setDealsData] = useState(deals); 

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDealsUpdate = (updatedDeals) => {
    setDealsData(updatedDeals); 
  };


  return (
    <Grid container spacing={2}>
      {/* Header */}
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" gutterBottom>
          {dealsData?.name}'s Profile
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ 
            backgroundColor: 'blue',
            color: 'white',
            '&:hover': {
              backgroundColor: 'yellow',
              color: 'black'
            },  borderRadius: '8px', // Example additional style
            padding: '8px 16px'
          }}
          onClick={handleModalOpen}
        >
          Update deals
        </Button>
      </Grid>

      {/* Tabs */}
      <Grid item xs={12}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
        >
          <Tab label="Profile" value={0} />
          <Tab label="Pipeline Stage" value={1} />
        </Tabs>
      </Grid>

      {/* Profile Tab */}
      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DealsProfile deals={dealsData} /> 
          </Grid>
        </Grid>
      </TabPanel>

      {/* Pipeline Stage Tab */}
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* Add relevant information about the pipeline stage here */}
            <Typography variant="h6">Pipeline Stage Information</Typography>
            <Typography variant="body1">
              {/* Current Stage: {deals?.pipelinestage || "Not Available"} */}
            </Typography>
            {/* You can add more fields or display a history of pipeline stages */}
          </Grid>
        </Grid>
      </TabPanel>

      {/* Update deals Modal */}
      <EditdealsModal
        open={isModalOpen}
        onClose={handleModalClose}
        deals={dealsData}
        onUpdate={handleDealsUpdate} // Pass the update handler
      />
    </Grid>
  );
}

export default DealsCard;
