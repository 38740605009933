import React, { useState, useEffect } from 'react';
import {
  Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Pagination, Typography, CircularProgress, Button, TableSortLabel,
  useTheme,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
  } from '@mui/material';
import { dataDelete, dataFetch } from '../../../services/ApiEndPoint';
// import OrganizationCard from './OrganizationCard'; // Assuming you have an OrganizationCard component
// import AddOrganizationModal from './AddOrganizationModal'; // Assuming you have a modal for adding organizations
import { useAuth } from '../../../contexts/AuthContext';
import { tokens } from '../../../utils/theme';
import OrganizationCard from './OrganizationCard';
import DeleteIcon from "@mui/icons-material/Delete";

const OrganizationList = () => {
  const [organizations, setOrganizations] = useState([]);
  const [totalOrganizations, setTotalOrganizations] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [orgIdToDelete, setOrgIdToDelete] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const { token } = useAuth();




  const fetchOrganizations = async () => {
    setLoadingData(true);
    try {
      const skip = (page - 1) * rowsPerPage;
      let url = `admin/org/query?skip=${skip}&limit=${rowsPerPage}&sort=${sortField}&order=${sortOrder}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setOrganizations(response.data.data);
        setTotalOrganizations(response.data.total);
      } else {
        console.error(`Error fetching organizations: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching organizations:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, [page, sortField, sortOrder, token]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSortRequest = (field) => {
    const isAsc = sortField === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortField(field);
  };

  
  const handleOrganizationClick = async (organizationId) => {
    setLoadingData(true);
    try {
      let url = `admin/org/${organizationId}`;
      let response = await dataFetch(url, token);

      if (response.status === 200) {
        setSelectedOrganization(response.data.data);
      } else {
        console.error(`Error fetching Organization details: ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - possibly due to an invalid or expired token.");
      } else {
        console.error("Error fetching Organization details:", error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const handleOpenDeleteDialog = (companyId) => {
    setOrgIdToDelete(companyId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setOrgIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (orgIdToDelete) {
      setLoadingData(true);
      try {
        const url = `admin/org/${orgIdToDelete}`;
        const response = await dataDelete(url, token);

        if (response.status === 200) {
          setOrganizations(organizations.filter((organization) => organization._id !== orgIdToDelete));
          setTotalOrganizations((prev) => prev - 1);
          console.log("Organization deleted successfully");
          toast.success("Organization deleted successfully");
        } else {
          console.error(`Error deleting Organization: ${response.status}`);
        }
      } catch (error) {
        console.error("Error deleting Organization:", error);
      } finally {
        setLoadingData(false);
        handleCloseDeleteDialog();
      }
    }
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalPages = Math.ceil(totalOrganizations / rowsPerPage);

  return (
    <Grid container spacing={2} justifyContent="center">
      {selectedOrganization ? (
        <Grid item xs={12}>
          <OrganizationCard organization={selectedOrganization} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="h5" gutterBottom
            >
              Organization Dashboard - Page {page}
            </Typography>
           
          </Grid>

          <Grid item xs={12}>
            {loadingData ? (
              <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                <CircularProgress />
              </Grid>
            ) : (
              <TableContainer component={Paper} sx={{ borderRadius: 2, backgroundColor: "#f5f5f5" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {['name', 'size', 'ownerName', 'ownerEmail','delete'].map((header) => (
                        <TableCell
                          key={header}
                          sx={{
                            fontWeight: 'bold',
                            padding: { xs: '8px', md: '16px' },
                            textAlign: 'center',
                            backgroundColor: colors.primary[300],
                            color: 'white',
                          }}
                        >
                          <TableSortLabel
                            active={sortField === header}
                            direction={sortField === header ? sortOrder : 'asc'}
                            onClick={() => handleSortRequest(header)}
                            sx={{ textAlign: 'center' }}
                          >
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
  {organizations.map((organization) => (
    <TableRow
      key={organization._id}
      onClick={() => handleOrganizationClick(organization._id)}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: colors.primary[300],
          color: 'white',
          '& td': {
            color: 'white', // Change the text color of all table cells to white on hover
          }
        }
      }}
    >
      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{organization?.name}</TableCell>
      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{organization?.size}</TableCell>
      <TableCell sx={{ textAlign: 'center', fontWeight: 'bold' }}>{organization?.ownerName|| '-'}</TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
      {organization?.ownerEmail || '-'}
      </TableCell>
      <TableCell sx={{ textAlign: "center" }}>
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the row click event when clicking the delete button
                              handleOpenDeleteDialog(organization._id);
                            }}
                            sx={{
                              backgroundColor: "black",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "red",
                              },
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
    </TableRow>
  ))}
</TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
                sx={{
                  '& .MuiPaginationItem-root': {
                    fontSize: { xs: '0.8rem', md: '1rem' },
                    margin: '0 4px'
                  },
                  '& .MuiPaginationItem-ellipsis': { display: 'none' },
                  '& .MuiPaginationItem-page.Mui-selected': {
                    backgroundColor: 'black',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'black',
                    }
                  }
                }}
              />
              <Typography sx={{ ml: 2 }}>
                <strong>Total Pages: {totalPages}</strong>
              </Typography>
            </Box>
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} fullWidth
            maxWidth="xs"
            PaperProps={{
              sx: {
                borderRadius: 10,
                padding: 2,
                minWidth: '300px',
                boxShadow: 3,
              },
            }}>
        <DialogTitle sx={{ textAlign: 'center', paddingBottom: 2 }} >Confirm Deletion</DialogTitle>
        <DialogContent sx={{ padding: 2 }}>
          <DialogContentText sx={{ textAlign: 'center', marginBottom: 2 }}>
            Are you sure you want to delete this company? 
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: 1 }}>
        <Button
                onClick={handleConfirmDelete}
                variant="contained"
                sx={{
                  backgroundColor: 'error.main',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'error.dark',
                  },
                  padding: '8px 16px',
                  marginRight: 1,
                }}
              >
                Yes
              </Button>
              <Button
                onClick={handleCloseDeleteDialog}
                variant="outlined"
                sx={{
                  color: 'blue',
                  borderColor: 'blue',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: 'blue',
                    color: 'white',
                  },
                }}
              >
                No
              </Button>
        </DialogActions>
      </Dialog>
          </Grid>
        </>
      )}

   

    </Grid>
  );
};

export default OrganizationList;
